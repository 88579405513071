import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import PageTitle from '../../components/PageTitle';
import Pagination from '../../components/Pagination';
import { PAGE_DETAILS } from '../../store/constant';
import { getUserData } from '../../store/reducers/authReducer';
import { cloneVersion, fetchUserPermissions, fetchVersion, getUserPermissions, getVersions, setIsPermissionData, setVersion } from '../../store/reducers/salarySuggestionReducer';
import { AppDispatch } from '../../store/store';
import { scrollToTop } from '../../store/utilFuntions';
import CloneVersionModal from './CloneVersionModal';
import './style.scss';

const Versions = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const versions = useSelector(getVersions);
  const user = useSelector(getUserData);
  const userPermissions = useSelector(getUserPermissions);
  const [actionVersion, setActionVersion] = useState<any>({});
  const [showModal, setShowModal] = useState(false);
  const [paginationObj, setPaginationObj] = useState({
    totalCount: 0,
    perPageCount: 250,
    currentPage: 1,
    startCount: 1,
    endCount: 250,
    isPrevActive: false,
    isNextActive: true
  })

  useEffect(() => {
    fetchVersionList();
    scrollToTop(20);
    // eslint-disable-next-line
  }, [])

  const selectVersion = (version: any) => {
    dispatch(setVersion(version));

    setTimeout(() => {
      navigate('/suggest-salary');
    }, 50);
  }

  const versionClone = (version_name: string) => {
    let existingVersion = versions.find((version: any) => version.name === version_name);
    if (existingVersion) {
      toast.error('Name already exists');
      return;
    }

    let requestData: any = {
      version_id: actionVersion.id,
      data: { name: version_name }
    };

    dispatch(cloneVersion(requestData)).then((data: any) => {
      if (data?.payload?.status === '200') {
        fetchVersionList();
        handleClose();
      }
    });
  }

  const convertDate = (date: string | null) => {
    let dateString = date ? (new Date(date)).toLocaleDateString('US', { year: 'numeric', month: 'short', day: '2-digit' }) : ''
    return dateString;
  }

  const fetchVersionList = (page: number | null = null, pageData: any = {}) => {
    dispatch(fetchVersion({ page })).then((res: any) => {
      if (res.payload.status !== "200") {
        return;
      }
      let pageObj = {
        ...pageData,
        currentPage: page || 1,
        isPrevActive: res.payload.previous ? true : false,
        isNextActive: res.payload.next ? true : false,
        totalCount: res.payload.count || 0,
        endCount: page ? pageData.endCount : (res.payload.count < 250 ? res.payload.count : 250),
        startCount: page ? pageData.startCount : 1,
      }
      setPaginationObj({
        ...paginationObj,
        ...pageObj
      })
      scrollToTop(0);
    })
  }

  const onPrevClick = (pageData: any) => {
    fetchVersionList(paginationObj.currentPage - 1, pageData);
  }

  const onNextClick = (pageData: any) => {
    fetchVersionList(paginationObj.currentPage + 1, pageData);
  }

  const handleClose = () => setShowModal(false);

  const handleShow = (version: any) => {
    setActionVersion(version);
    setShowModal(true);
  }

  useEffect(() => {
    if (user?.id) {
      dispatch(setIsPermissionData(false));
      dispatch(fetchUserPermissions(user.id))
    }
  }, [dispatch, user?.id]);

  const tooltip = (id: any, data: any) => {
    return <Tooltip id={id} className="version-tooltip">
      <div className='version-tooltip-content'>
        <div className='tooltip-value'>{data}</div>
      </div>
    </Tooltip>
  };

  return (
    <div className='versions-page'>
      <PageTitle pageDetail={PAGE_DETAILS.versions} />

      <div className="page-content">
        <Pagination totalCount={paginationObj.totalCount} perPageCount={paginationObj.perPageCount}
            startCount={paginationObj.startCount} endCount={paginationObj.endCount}
            isPrevActive={paginationObj.isPrevActive} isNextActive={paginationObj.isNextActive}
            onPrevClick={(pageData: any) => onPrevClick(pageData)} onNextClick={(pageData: any) => onNextClick(pageData)} />

        <div className='page-header'>
          <div className="first-col">Version</div>
          <div className="second-col">Author</div>
          <div className="third-col">Created On</div>
          <div className="fourth-col">Published On</div>
          {userPermissions.add_draft &&
            <div className='actions-col'>Actions</div>
          }
        </div>

        {versions.map((version: any) => {
          let versionType = version.is_published ? (version.is_active ? 'current' : 'previous') : 'draft';
          return(
            <div key={version.id} className={version.is_published
                ? (version.is_active ? "versions-list-item current-published" : "versions-list-item previously-published")
                : "versions-list-item"}>
              <div className="first-col">
                <OverlayTrigger placement="bottom" overlay={tooltip(`tooltip-id${version.id}`, version.name)}>
                  <span className="version-name" onClick={() => { selectVersion(version) }}>
                    {version.name}
                  </span>
                </OverlayTrigger>

                {versionType === 'current' &&
                  <span className="version-label">
                    <img title="Tick" src="/images/tick.svg" alt="tick" />
                    <span className="published-text">Current Published</span>
                  </span>
                }
                {versionType === 'previous' && <span className="version-label previous-label published-text">Previously Published</span>}
                {versionType === 'draft' && <span className="version-label draft-label published-text">Draft</span>}
              </div>

              <div className="second-col">{version.created_by?.name}</div>
              <div className="third-col">{convertDate(version.created_at)}</div>
              <div className="fourth-col">{convertDate(version.published_at)}</div>
              
              <div className="actions-col">
                {userPermissions.add_draft &&
                  <span className="clone-icon">
                    <img title="Clone" src="/images/clone-blue.svg" alt="clone" onClick={() => handleShow(version)} />
                  </span>
                }
              </div>
            </div>
          )
        })}

        <Pagination totalCount={paginationObj.totalCount} perPageCount={paginationObj.perPageCount}
          startCount={paginationObj.startCount} endCount={paginationObj.endCount}
          isPrevActive={paginationObj.isPrevActive} isNextActive={paginationObj.isNextActive}
          onPrevClick={(pageData: any) => onPrevClick(pageData)} onNextClick={(pageData: any) => onNextClick(pageData)} />
      </div>
    
      {
        showModal && <CloneVersionModal
          show={showModal}
          title='Create New Version'
          data={actionVersion}
          onClone={versionClone}
          onHide={handleClose} />
      }
    </div>
  )
}

export default Versions;