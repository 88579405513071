import { PermissionTypes } from '../models/userPermissions'

const RATINGS = [
  {
    id: 1,
    name: 3
  },
  {
    id: 2,
    name: 3.5
  },
  {
    id: 3,
    name: 4
  },
  {
    id: 4,
    name: 4.5
  },
  {
    id: 5,
    name: 5
  }
]

const PAGE_DETAILS = {
  configurations: {
    name: 'Configurations',
    url: '/configurations',
    hideCountry: true
  },
  suggestSalary: {
    name: 'Suggest Salary',
    url: '',
  },
  locations: {
    name: 'Locations',
    url: 'location',
    nextUrl: '/configurations/stream',
    backUrl: '/configurations/experience'
  },
  experience: {
    name: 'Grade (Experience)',
    url: 'experience',
    nextUrl: '/configurations/location',
    backUrl: ''
  },
  combination: {
    name: 'Combination Overrides',
    url: 'combination-overrides',
    nextUrl: '/configurations/market-conditions',
    backUrl: '/configurations/qualifications-certifications'
  },
  stream: {
    name: 'Department/Stream',
    url: 'stream',
    nextUrl: '/configurations/qualifications-certifications',
    backUrl: '/configurations/location'
  },
  qualificationsCertifications: {
    name: 'Qualifications & Certifications',
    url: 'qualifications-certifications',
    nextUrl: '/configurations/combination-overrides',
    backUrl: '/configurations/stream'
  },
  marketConditions: {
    name: 'Market Conditions',
    url: 'market-conditions',
    nextUrl: '/configurations/compute',
    backUrl: '/configurations/combination-overrides',
  },
  compute: {
    name: 'Compute',
    url: 'compute',
    nextUrl: '/configurations/review-publish',
    backUrl: '/configurations/market-conditions',
    hideCountry: true
  },
  reviewAndPublish: {
    name: 'Review & Publish',
    url: 'review-publish',
    nextUrl: '',
    backUrl: '/configurations/compute'
  },
  suggestedSalaries: {
    name: 'Suggested Salaries',
    url: 'suggested-salaries'
  },
  versions: {
    name: 'Versions',
    url: 'versions',
    hideCountry: true
  }
}

const FILTER_NAME = {
  grade: 'grades',
  stream: 'streams',
  rating: 'rating',
  location: 'locations',
  qualification: 'qualifications',
  marketCondition: 'market_condition',
}

const GENERAL = {
  navigation_warning: 'There are unsaved changes. Do you want to leave now? '
}

const REGEX = {
  integer: /^[0-9\b]+$/,
}

const DEFAULT_USER_PERMISSION: PermissionTypes = {
  view: false,
  edit: false,
}

const STATUS_CODES_IGNORE = ['403', '404'];

const PATHS_WITH_REFRESH_BUTTON = ['/suggest-salary', '/suggested-salaries', '/configurations/compute', '/configurations/review-publish'];
const PATHS_WITH_CURRENTLY_PUBLISHED = ['/suggest-salary', '/suggested-salaries', '/configurations/review-publish'];

export { RATINGS, PAGE_DETAILS, FILTER_NAME, GENERAL, REGEX, DEFAULT_USER_PERMISSION, STATUS_CODES_IGNORE, PATHS_WITH_REFRESH_BUTTON, PATHS_WITH_CURRENTLY_PUBLISHED }

export const protectedPathnamesForConfig =[
  '/configurations',
  '/configurations/experience',
  '/configurations/location',
  'configurations/stream',
  'configurations/combination-overrides',
  'configurations/qualifications-certifications',
  'configurations/market-conditions',
  'configurations/compute',
  'configurations/review-publish'
] 

export const protectedPathnamesForVersion =[
  '/versions',
] 