import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";
import Header from "../../components/header/header";
import SideNav from "../../components/side-nav/side-nav";
import { isUserAuthenticated } from "../../store/reducers/authReducer";
import { getSideNavState } from "../../store/reducers/generalReducer";
import './styles.scss';

const Layout = () => {
  const sideNavCollapsed = useSelector(getSideNavState);
  const isAuthenticated = useSelector(isUserAuthenticated);

  return (
    isAuthenticated ?
      <div className='layout-page'>
        {
          <>
            <Header />
            <SideNav />
            <div className={sideNavCollapsed ? "content-full" : "content-half"}>
              <Outlet />
            </div>
          </>
        }
      </div> : <Navigate to='/login' replace />
  )
}

export default Layout;