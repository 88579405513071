import './style.scss';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { AppDispatch } from '../../../../store/store';

import { editQualification, editCertification } from '../../../../store/reducers/configQualificationsCertificationsReducer';

import { blockInvalidCharQualification, twoDigitDecimalNumber } from '../../../../store/utilFuntions';

const EditQualificationsCertificationsModal = (props: any) => {
    const dispatch = useDispatch<AppDispatch>();
    const [editPopupData, setData] = useState(props.data);
    const [formError, setFormError] = useState(false);
    const [updateDisabled, setUpdateDisabled] = useState(true);
    let editPopupFormType = props.form_type;

    const changeInput = (inputField: string, event: any) => {
        let value = event.target.value?.trim();
        if (value[0] === '.') value = '0' + value;

        if ((twoDigitDecimalNumber.test(value) && value !== '0.00') || value.length === 0) {
            setFormError(false);
            setData({ ...editPopupData, [inputField]: event.target.value?.trim() });
            setUpdateDisabled(false);
        }
    }

    const resetFields = () => {
        setFormError(false);
        setData(props.data);
        setUpdateDisabled(true);
    }

    const editItem = () => {
        let editReducer = editPopupFormType === 'qualification' ? editQualification : editCertification;
        let value = parseFloat(editPopupData.offset);
        if (editPopupData.offset === '' || value < 0.01 || value > 99.99) {
            setUpdateDisabled(true);
            setFormError(true);
            return;
        }
        setUpdateDisabled(false);

        dispatch(editReducer(editPopupData))
        props.onEditItem(editPopupData);
    }

    return (
        <div>
            <Modal show={props.show} onHide={() => props.onHide()} centered backdrop='static'>
                <Modal.Header>
                    <Modal.Title>
                        {props.title}
                    </Modal.Title>
                    <img className='close-btn' src='/images/modal-close.svg' onClick={() => props.onHide()} alt='modal-close' />
                </Modal.Header>

                <Modal.Body className='qualification-modal-body'>
                    <div className='first-row'>
                        <div className='col-name'>
                            {editPopupFormType[0].toUpperCase() + editPopupFormType.slice(1)}
                        </div>
                        <div>
                            <div><input 
                                    className='overflow-hidden text-truncate'
                                    disabled value={editPopupData[editPopupFormType].name} /></div>
                        </div>
                    </div>

                    <div className='second-row'>
                        <div className='col-name'>
                            Offset
                        </div>
                        <div className="rating-input-div">
                            <input value={editPopupData.offset}
                                onKeyDown={blockInvalidCharQualification}
                                className={formError ? 'rating-input val-error' : 'rating-input'}
                                onChange={(e: any) => changeInput('offset', e)} placeholder='00.00' />
                            {formError && <span className='input-error'>
                                {editPopupData.offset === '' ? 'Required' : ''}
                            </span>}
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer className='actions'>
                    <Button className='clear-btn' onClick={() => resetFields()} >Reset</Button>
                    <Button className='add-btn' disabled={updateDisabled} onClick={() => editItem()}  >Update</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default EditQualificationsCertificationsModal;