import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { AppDispatch } from '../../../store/store';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useNavigate } from 'react-router-dom';

import { fetchExperience, getExperience, editExperience, resetData } from '../../../store/reducers/configExperienceReducer';
import { getPermissionData, getVersion } from '../../../store/reducers/salarySuggestionReducer';

import StarRating from '../../../components/StarRating';
import EditExperienceModal from './EditExperienceModal';

import PageTitle from '../../../components/PageTitle';
import { PAGE_DETAILS, GENERAL, STATUS_CODES_IGNORE } from '../../../store/constant';
import ActionButtons from '../../../components/ActionButtons';

import { valueToThousandPipe } from '../../../pipes/valueToThousandPipe';
import { usePrompt } from '../../../components/ConfirmNavigationPrompt/index';
import useUnsavedChangesStateUpdates from '../../../hooks/unsavedChangesStateUpdates';

const ConfigExperience = () => {

  const experienceList = useSelector(getExperience);
  const version = useSelector(getVersion);
  const permissionData = useSelector(getPermissionData);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [editPopupData, setData] = useState(null);
  const [show, setShow] = useState(false);
  const [submitData, setSubmitData] = useState({});

  let isNavigateToNext = false;

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  usePrompt(GENERAL.navigation_warning, Object.keys(submitData).length !== 0);

  useUnsavedChangesStateUpdates(Object.keys(submitData).length !== 0);

  useEffect(() => {
    if (version.id) {
      dispatch(fetchExperience(version.id));
    }

    return() => {
      setSubmitData({});
    }
  }, [dispatch, version.id]);

  const openEditPopup = (index: number) => {
    setData(experienceList[index]);
    handleShow();
  }

  const handleEditItem = (editItemData: any) => {
    let submitDataCopy: any = submitData;
    submitDataCopy[editItemData[0].grade_id] = editItemData;
    setSubmitData(submitDataCopy);
    handleClose();
  }

  const onCancelClick = () => {
    setSubmitData({});
    dispatch(resetData());
  }

  const navigateToNext = () => {
    if (isNavigateToNext) {
      navigate(PAGE_DETAILS.experience.nextUrl);
    }
  }

  const onSaveClick = () => {
    let requestData = [];
    let submitDataCopy: any = submitData;
    for (const editItem in submitData) {
      requestData.push(...submitDataCopy[editItem]);
    };

    if (requestData.length) {
      dispatch(editExperience(requestData)).then((data: any) => {
        if (data?.payload?.status === '200') {
          setSubmitData({});
          setTimeout(() => {
            navigateToNext();
          }, 100);
        } else if(STATUS_CODES_IGNORE.includes(data?.payload?.status)) {
          setSubmitData({});
        }
      });
    } else {
      navigateToNext();
    }
  }

  const tooltipContent = (rating: any) => {
    return (
      <div className='tooltip-content col-12 display-flex'>
        <div className='col-4'>
          <div className='tooltip-heading'>Minimum</div>
          <div className='tooltip-value'>{valueToThousandPipe(rating.minimum)}</div>
        </div>
        <div className='col-4'>
          <div className='tooltip-heading'>Median</div>
          <div className='tooltip-value'>{valueToThousandPipe(rating.median)}</div>
        </div>
        <div className='col-4'>
          <div className='tooltip-heading'>Maximum</div>
          <div className='tooltip-value'>{valueToThousandPipe(rating.maximum)}</div>
        </div>
      </div>
    )
  }

  const ratingValues = (rating: any, index: number) => {
    return (
      <td>
        <OverlayTrigger
          placement='bottom'
          overlay={
            <Tooltip id={`tooltip-rating-${index}`}>
              {tooltipContent(rating)}
            </Tooltip>
          }
        >
          <span>{valueToThousandPipe(rating.minimum)} - {valueToThousandPipe(rating.maximum)}</span>
        </OverlayTrigger></td>
    )
  }

  return (
    <div className="experience-container">
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <PageTitle paths={[PAGE_DETAILS.configurations, PAGE_DETAILS.experience]} pageDetail={PAGE_DETAILS.experience} />
        <table className='listing-table'>
          <thead>
            <tr>
              <th className='header-text'>Grade (Experience)</th>
              <th><StarRating star_rating={3} /></th>
              <th><StarRating star_rating={3.5} /></th>
              <th><StarRating star_rating={4} /></th>
              <th><StarRating star_rating={4.5} /></th>
              <th><StarRating star_rating={5} /></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {experienceList.map((experience: any, index: number) => {
              return (
                <tr>
                  <td>{experience.grade.name + ' (' + experience.grade.display_name + ')'}</td>
                  {experience.ratings.map((rating: any, rating_index: number) => {
                    return ratingValues(rating, rating_index);
                  })}
                  {permissionData.edit &&
                    <td><img className='edit-icon' src='/images/edit.svg' alt='edit' onClick={() => openEditPopup(index)} /></td>
                  }
                </tr>
              );
            })}
          </tbody>
        </table>

        {show && <EditExperienceModal
          versionId={version.id}
          show={show}
          title='Edit Grade (Experience) - Salary Range'
          data={editPopupData}
          onEditItem={handleEditItem}
          onHide={handleClose} />}
      </div>

      <div>
        <ActionButtons
          actionBtn1Click={() => onCancelClick()}
          actionBtn2Click={() => {
            isNavigateToNext = false;
            onSaveClick()
          }}
          actionBtn3Click={() => {
            isNavigateToNext = true;
            onSaveClick();
          }}
        />
      </div>

    </div>
  );
};

export default ConfigExperience;
