import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit"
import { toast } from "react-toastify";
import { api } from "../../api";
import { qualificationsObj, certificationsObj } from "../../models/qualificationsCertifications";
import { handleApiError, sortList } from '../utilFuntions';

const initialState: State = {
  qualificationseOffset: [],
  certificationsOffset: []
}

let resetStateDataQualifications: any = initialState.qualificationseOffset;
let resetStateDataCertifications: any = initialState.certificationsOffset;

export const fetchQualifications = createAsyncThunk('/get-qualifications', async (versionId: number) => {
  const response = await api.get(`configurations/qualifications?version_id=${versionId}`);
  return response.data;
});

export const fetchCertifications = createAsyncThunk('/get-certifications', async (versionId: number) => {
  const response = await api.get(`configurations/certifications?version_id=${versionId}`);
  return response.data;
});

export const updateQualifications = createAsyncThunk('/update-qualifications', async (requestData: any) => {
  const response = await api.post(`configurations/qualifications`, requestData);
  return response.data;
});

export const updateCertifications = createAsyncThunk('/update-certifications', async (requestData: any) => {
  const response = await api.post(`configurations/certifications`, requestData);
  return response.data;
});

const newStateOnAddListItem = (currentStateData: any, action: any, form_type: string) => {
  let newstateData: any = [action.payload, ...currentStateData];
  newstateData = sortList(newstateData, form_type, true, 'name')
  return newstateData;
};

const updateState = (currentStateData: any, responseData: any, form_type: string) => {
  let experienceItemIndex = -1;

  currentStateData.forEach((state_item: any, index: number) => {
    if (state_item[form_type].id === responseData[form_type].id) {
      experienceItemIndex = index;
    }
  });

  let newStateData: any = [];
  currentStateData.forEach((state_item: any, index: number) => {
    if (index === experienceItemIndex) {
      newStateData.push(responseData);
    } else {
      newStateData.push(state_item);
    }
  })
  newStateData = sortList(newStateData, form_type, true, 'name')
  return newStateData;
};

export const qualificationsCertificationsSlice = createSlice({
  name: 'qualificationsCertifications',
  initialState,
  reducers: {
    addQualification: (state: State, action: any) => {
      state.qualificationseOffset = newStateOnAddListItem(current(state.qualificationseOffset), action, 'qualification');
    },
    addCertification: (state: State, action: any) => {
      state.certificationsOffset = newStateOnAddListItem(current(state.certificationsOffset), action, 'certification');
    },
    editQualification: (state: State, action: any) => {
      state.qualificationseOffset = updateState(current(state.qualificationseOffset), action.payload, 'qualification');
      toast.success('Offset for qualification has been updated successfully');
    },
    editCertification: (state: State, action: any) => {
      state.certificationsOffset = updateState(current(state.certificationsOffset), action.payload, 'certification');
      toast.success('Offset for certification has been updated successfully');
    },
    deleteQualification: (state: State, action: any) => {
      let currentState = current(state.qualificationseOffset)
      state.qualificationseOffset = [...currentState.slice(0, action.payload), ...currentState.slice(action.payload + 1, currentState.length)];
      toast.success('Offset for qualification has been deleted successfully');
    },
    deleteCertification: (state: State, action: any) => {
      let currentState = current(state.certificationsOffset)
      state.certificationsOffset = [...currentState.slice(0, action.payload), ...currentState.slice(action.payload + 1, currentState.length)];
      toast.success('Offset for certification has been deleted successfully');
    },
    resetData: (state: any) => {
      state.qualificationseOffset = resetStateDataQualifications;
      state.certificationsOffset = resetStateDataCertifications;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchQualifications.fulfilled, (state, action) => {
      if (action.payload.status === "200") {
        state.qualificationseOffset = action.payload.data;
        resetStateDataQualifications = action.payload.data;
      } else handleApiError(action.payload.status) 
    });

    builder.addCase(fetchCertifications.fulfilled, (state, action) => {
      if (action.payload.status === "200") {
        state.certificationsOffset = action.payload.data;
        resetStateDataCertifications = action.payload.data;
      } else handleApiError(action.payload.status) 
    });

    builder.addCase(updateQualifications.fulfilled, (state, action) => {
      if (action.payload.status === "200") {
        resetStateDataQualifications = current(state.qualificationseOffset);
      } else handleApiError(action.payload.status) 
    });

    builder.addCase(updateCertifications.fulfilled, (state, action) => {
      if (action.payload.status === "200") {
        resetStateDataCertifications = current(state.certificationsOffset);
      } else handleApiError(action.payload.status) 
    });
  }
});

export const getQualifications = (state: any) => state.qualificationsCertifications.qualificationseOffset;

export const getCertifications = (state: any) => state.qualificationsCertifications.certificationsOffset;

export const { addQualification, addCertification, editQualification, editCertification, deleteQualification, deleteCertification, resetData } = qualificationsCertificationsSlice.actions;

export default qualificationsCertificationsSlice.reducer;

interface State {
  qualificationseOffset: qualificationsObj[] | [],
  certificationsOffset: certificationsObj[] | []
}