import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Dropdown, ListGroup, Row } from "react-bootstrap";
import classNames from 'classnames';

import StarRating from '../../../components/StarRating';
import { FILTER_NAME } from '../../../store/constant';
import './style.scss'

interface Props {
  filterMenuOptions: any;
  key1?: string;
  key2?: string;
  filterName?: string;
  filterType?: 'radio' | 'checkbox';
  onApplyFilter: Function;
  isStreamFilter?: boolean;
}

export default function FilterDropdown({ filterMenuOptions, key1 = "name", key2, filterName, filterType = "checkbox", onApplyFilter, isStreamFilter }: Props) {
  const [filterList, setFilterList] = useState(filterMenuOptions);
  const [appliedList, setAppliedList]: any = useState([]);
  const [show, setShow] = useState(false);
  const dropdownToggleRef = React.createRef<HTMLButtonElement>();
  const className = classNames('filter-dropdown-container', {
    'stream-filter': isStreamFilter
  });


  function getSelectedName() {
    if (appliedList) {
      return key2 ? `${appliedList[0][key1]} (${appliedList[0][key2]})` : `${appliedList[0][key1]}`
    }
    return ''
  }

  function getName(option: any) {
    return key2 ? `${option[key1]} (${option[key2]})` : `${option[key1]}`
  }

  const handleCheckBox = (event: any, index: number) => {
    const list: any = [...filterList]
    const item: any = { ...list[index] };
    item.isChecked = event.target.checked;
    list[index] = item;
    setFilterList(list);
  };

  const handleRadio = (index: number) => {
    const list: any = JSON.parse(JSON.stringify(filterList));
    list.forEach((item: any, itemIndex: number) => {
      item.isChecked = false;
      if (itemIndex === index) {
        item.isChecked = true;
      }
    })
    setFilterList(list);
  }

  const onApplyClick = () => {
    if (filterList && filterList.length) {
      const list: any = [];
      filterList.map((option: any) => {
        if (option.isChecked) {
          list.push(option);
        }
        return true;
      })
      setAppliedList(list);
      onApplyFilter(filterList, list);
      setShow(false);
    }
  };

  const onFilterToggle: any = useCallback(() => {
    if (filterMenuOptions && filterMenuOptions.length) {
      const list: any = [];
      filterMenuOptions.map((option: any) => {
        if (option.isChecked) {
          list.push(option);
        }
        return true
      })
      setAppliedList(list);
      setFilterList(filterMenuOptions);
    }
  }, [filterMenuOptions])

  useEffect(() => {
    onFilterToggle();
  }, [filterMenuOptions, onFilterToggle]) 

  return (
    <div className={className}>
      <Dropdown show={show} onToggle={() => {
        onFilterToggle();
        setShow(!show);
      }}>
        <div className="icon-container">
          <Dropdown.Toggle className='small-btn' ref={dropdownToggleRef}>
            <img className="filter-icon" src={appliedList && appliedList.length ? "/images/filter-selected.svg" : "/images/filter.svg"} alt="Menu" />
          </Dropdown.Toggle>
          {appliedList && appliedList[0] &&
            <div className='d-flex flex-column justify-content-center'>
              {filterName === FILTER_NAME.rating ?
                <div className="mbn4">
                  <StarRating star_rating={appliedList[0]?.name} height={10} width={10} />
                </div> :
                <div className="selected-text">{getSelectedName()}</div>
              }
              <div className="more-text" onClick={() => dropdownToggleRef.current?.click()}>{appliedList.length > 1 ? `+${appliedList.length - 1} more` : ''}</div>
            </div>}
        </div>

        <Dropdown.Menu>
          <Container>
            <Row className="filter-list">
              <Col>
                <ListGroup>
                  {filterList && filterList.map((option: any, index: number) => (
                    <Row key={index}>
                      <Col xs="2">
                      {filterType === 'checkbox' ? 
                        <input
                          className="ckeckbox"
                          type="checkbox"
                          checked={option.isChecked || false}
                          onChange={(e: any) => handleCheckBox(e, index)}
                        /> :
                        <input
                          type='radio'
                          className='radio'
                          value={option.id}
                          name={filterName}
                          checked={option.isChecked || false}
                          onChange={() => handleRadio(index)}
                        />
                      }
                      </Col>
                      <Col>
                        {filterName === 'rating' ?
                          <StarRating star_rating={option.name} /> :
                          <p>{getName(option)}</p>
                        }
                      </Col>
                    </Row>
                  ))}
                </ListGroup>
              </Col>
            </Row>
            <div className='action-container'>
              <button className="btn action-btn save" onClick={() => onApplyClick()}>Apply</button>
            </div>
          </Container>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
