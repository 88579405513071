import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify";
import { api } from "../../api";
import { handleApiError } from '../utilFuntions';
import { marketConditionObj } from "../../models/marketCondition";

const initialState: State = {
  marketConditionOffset: [],
}

export const fetchMarketConditionOffset = createAsyncThunk('/market-condition', async (versionId: number) => {
  const response = await api.get(`configurations/market-condition?version_id=${versionId}`);
  return response.data;
})

export const updateMarketConditionOffset = createAsyncThunk('/market-condition-update', async (requestData: any) => {
  const response = await api.post(`configurations/market-condition`, requestData);
  return response.data;
})

export const marketConditionOffsetSlice = createSlice({
  name: 'marketCondition',
  initialState,
  reducers: {},
  extraReducers:  (builder) => {
    builder.addCase(fetchMarketConditionOffset.fulfilled, (state, action) => {
      if (action.payload.status === "200") {
        state.marketConditionOffset = action.payload.data;
      } else handleApiError(action.payload.status) 
    })
    builder.addCase(updateMarketConditionOffset.fulfilled, (_state, action) => {
      if (action.payload.status === "200") {
        toast.success('Offsets for market conditions have been saved successfully');
      } else handleApiError(action.payload.status) 
    })
  }
})

export const getMarketConditionOffset = (state: any) => state.marketCondition.marketConditionOffset;

export default marketConditionOffsetSlice.reducer;

interface State {
  marketConditionOffset: marketConditionObj[] | [],
}
