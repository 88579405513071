import './style.scss';

const Pagination = (props: PaginationProps) => {

  const onPrevClick = () => {
    if (props.isPrevActive) {
      let start = props.startCount;
      let end = start - 1;
      start = start - props.perPageCount;
      const pageData = {
        startCount: start,
        endCount: end
      }
      props.onPrevClick(pageData);
    }
  }

  const onNextClick = () => {
    if (props.isNextActive) {
      let start = props.startCount, end = props.endCount;

      if (end !== props.totalCount) {
        start = end + 1;
      }
      if (end + props.perPageCount > props.totalCount) {
        end = props.totalCount;
      } else {
        end = end + props.perPageCount;
      }
      const pageData = {
        startCount: start,
        endCount: end
      }
      props.onNextClick(pageData);
    }
  }

  return(
    <div style={{'display':(props.totalCount <= props.perPageCount) ? 'none' : 'flex'}} className=" pagination">
      <span>Viewing {props.startCount} - {props.endCount} of
        {props.isTotalCountReduced ? <span> {props.totalCount}</span> : <span> {(props.totalCount > 1000)? '1000+' : props.totalCount}</span>}
      </span>
      <img style={{'opacity':props.isPrevActive ? 1 : .5, 'cursor': props.isPrevActive ? 'pointer' : 'not-allowed'}}
        src="/images/left-angle.svg" alt="prev" className="px-3" onClick={onPrevClick} />
      <img style={{'opacity':props.isNextActive ? 1 : .5, 'cursor': props.isNextActive ? 'pointer' : 'not-allowed'}}
        src="/images/right-angle.svg" alt="next" className="px-3" onClick={onNextClick} />
    </div>
  )
}

export default Pagination

interface PaginationProps {
  totalCount: number,
  perPageCount: number,
  startCount: number,
  endCount: number,
  isTotalCountReduced?: boolean,
  isPrevActive: boolean,
  isNextActive: boolean,
  onPrevClick: Function,
  onNextClick: Function
}