import axios, { AxiosRequestConfig, AxiosInstance } from "axios";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserData, isUserAuthenticated, logout } from "../store/reducers/authReducer";
import { hideAccessDenied, isShowAccessDenied, showAccessDenied } from '../store/reducers/generalReducer';
import { fetchUserPermissions, fetchVersion, setVersion } from '../store/reducers/salarySuggestionReducer';
import { AppDispatch } from "../store/store";
import { showAccessDeniedToast, showErrorToast } from '../store/utilFuntions';

const axiosRequestConfiguration: AxiosRequestConfig = {
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/`,
  responseType: "json",
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
};

const initializeAxios = (config: AxiosRequestConfig): AxiosInstance => {
  const axiosInstance = axios.create(config);

  return axiosInstance;
};

const api = initializeAxios(axiosRequestConfiguration);


const useErrorHandler = (error: any, msg: string = '') => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [errorObj, setErrorObj] = useState(error || {});
  const isAuthenticated = useSelector(isUserAuthenticated);
  const isShowUserAccessDenied = useSelector(isShowAccessDenied);
  const user = useSelector(getUserData);
  
  const setError = (err: any) => {
    setErrorObj(err);
  }

  const getUpdatedStatusCode = (statusCode: string) => {
    let code = 200;
    if (statusCode === '403') {
      code = 403
    } else if (statusCode === '404') {
      code = 404
    }
    return code;
  }

  useEffect((): any => {
    if (errorObj?.response?.status || errorObj?.status) {
      let statusCode = errorObj.status === 200 ? getUpdatedStatusCode(errorObj.data?.status) : errorObj.response?.status;
      if (statusCode !== 403) {
        dispatch(showAccessDenied());
      }
      switch (statusCode) {
        case 200: {
          break;
        }
        case 400: {
          //bad request
          showErrorToast()
          break;
        }
        case 401: {
          //unauthorized
          if (isAuthenticated) {
            toast.error('Your session has expired. Please login');
          }
          dispatch(logout());
          break;
        }
        case 403: {
          //forbidden
          if (isShowUserAccessDenied) {
            dispatch(hideAccessDenied());
            showAccessDeniedToast();
            if (user.id) {
              dispatch(fetchUserPermissions(user.id));
            }
          }
          navigate('/suggest-salary');
          break;
        }
        case 404: {
          //not found
          toast.error(errorObj.data?.message);
          dispatch(setVersion(''));
          dispatch(fetchVersion({})).then(() => {
            setTimeout(() => {
              navigate('/suggest-salary')
            }, 500);
          });
          break;
        }
        case 500: {
          //internal server error
          showErrorToast();
          break;
        }
        default: {
          showErrorToast(msg);
        }
      }
      setErrorObj({});
    }
  }, [errorObj, dispatch, msg, navigate, isAuthenticated, isShowUserAccessDenied, user.id])

  return setError
}

export { api, useErrorHandler };
