import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';

import { getFilters, getPermissionData, getVersion } from "../../../store/reducers/salarySuggestionReducer";
import { fetchLocationOffset, getLocationOffset, updateLocationOffset } from '../../../store/reducers/configLocationReducer';
import './style.scss';
import { AppDispatch } from '../../../store/store';
import ActionButtons from '../../../components/ActionButtons';
import { isPositiveNumber, isValidLocationOffset, blockInvalidChar } from '../../../store/utilFuntions';
import PageTitle from '../../../components/PageTitle';
import { GENERAL, PAGE_DETAILS, STATUS_CODES_IGNORE } from '../../../store/constant';
import { usePrompt } from '../../../components/ConfirmNavigationPrompt';
import useUnsavedChangesStateUpdates from '../../../hooks/unsavedChangesStateUpdates';

const locationTypeKey = {
  fixed: 'fixed',
  percent: 'percent',
  fixedPercent: 'fixed+percent',
  default: 'NA',
}

const locationType: any = {
  [locationTypeKey.default]: 'Select type',
  [locationTypeKey.fixed]: 'Fixed',
  [locationTypeKey.percent]: 'Percentage',
  [locationTypeKey.fixedPercent]: 'Fixed + Percentage'
}

const locationTypes = [
  locationTypeKey.default, locationTypeKey.fixed, locationTypeKey.percent, locationTypeKey.fixedPercent
]

let isNavigateToNext = false;

let locArrayTemp: any = [];

let unsavedChange: any = {};

let hoveredItem = '';

const ConfigLocation = () => {
  const navigate = useNavigate();
  const locationOffsetList: any = useSelector(getLocationOffset);
  const permissionData = useSelector(getPermissionData);
  const version = useSelector(getVersion);
  const [locationConfigs, setLocationConfigs]: any = useState([])
  const dispatch = useDispatch<AppDispatch>();
  const filters: any = useSelector(getFilters);
  const [dataModified, setDataModified] = useState(false);

  usePrompt(GENERAL.navigation_warning, dataModified);
  useUnsavedChangesStateUpdates(dataModified);

  const onResetClick: any = useCallback(() => {
    const configs: any = [];
    filters.locations && filters.locations.map((filter: any) => {
      const i = locationOffsetList.findIndex((e: any) => e.location.id === filter.id);
      if (i > -1) {
        configs.push(locationOffsetList[i]);
      } else {
        const location = {
          location: filter,
          version: version.id,
          offset_type: locationTypeKey.default
        }
        configs.push(location);
      }
      unsavedChange[filter.id] = false;
      setDataModified(false);
      return true;
    })
    locArrayTemp = configs
    setLocationConfigs(configs)
  }, [filters.locations, locationOffsetList, version.id])

  const navigateToNext = () => {
    if (isNavigateToNext) {
      navigate(PAGE_DETAILS.locations.nextUrl);
    }
  }

  function updateLocationData(configs: any) {
    dispatch(updateLocationOffset(configs)).then((data: any) => {
      if (data?.payload?.status === '200') {
        if (isNavigateToNext) {
          unsavedChange = {};
          setDataModified(false);
          setTimeout(function () {
            navigateToNext();
          }, 100);
        } else {
          dispatch(fetchLocationOffset(version.id));
        }
      } else if(STATUS_CODES_IGNORE.includes(data?.payload?.status)) {
        unsavedChange = {};
        setDataModified(false);
      }
    });
  }

  function onSaveClick() {
    let canProceed = true;
    const configs = locationConfigs.map((locationOffset: any) => {
      let locObj = { ...locationOffset };
      switch (locationOffset.offset_type) {
        case locationTypeKey.fixed:
          locObj = onOffsetInputValueChange(locationOffset, locationOffset.offset_fixed, locationTypeKey.fixed, true);
          locObj.offset_percent = null;
          break;
        case locationTypeKey.percent:
          locObj = onOffsetInputValueChange(locationOffset, locationOffset.offset_percent, locationTypeKey.percent, true);
          locObj.offset_fixed = null;
          break;
        case locationTypeKey.fixedPercent:
          locObj = onOffsetInputValueChange(locationOffset, locationOffset.offset_fixed, locationTypeKey.fixed, true);
          locObj = onOffsetInputValueChange(locObj, locationOffset.offset_percent, locationTypeKey.percent, true);
          break;
        case locationTypeKey.default:
          locObj.showFixedError = false;
          locObj.showPercentError = false;
          break;
      }

      if (locObj.showFixedError || locObj.showPercentError) {
        canProceed = false;
      }
      locObj.submit = true;
      return locObj;
    });
    if (canProceed && dataModified) {
      updateLocationData(configs)
    } else if (canProceed) {
      navigateToNext();
    }
    setLocationConfigs(configs);
  }

  function showInputError(locationOffset: any, offsetType: string, type: string = 'fixed') {
    let showError = false;
    if (offsetType === locationTypeKey.fixedPercent) {
      showError = locationOffset.submit &&
        ((type === 'fixed' && locationOffset.showFixedError) || (type === 'percent' && locationOffset.showPercentError)) &&
        locationOffset.offset_type === locationTypeKey.fixedPercent;

    } else if (offsetType === locationTypeKey.fixed) {
      showError = locationOffset.submit && locationOffset.showFixedError && locationOffset.offset_type === locationTypeKey.fixed;
    } else if (offsetType === locationTypeKey.percent) {
      showError = locationOffset.submit && locationOffset.showPercentError && locationOffset.offset_type === locationTypeKey.percent;
    }
    return showError;
  }

  function getInputError(locationOffset: any, locType: string) {
    let errorMessage = '';
    let type = '';
    switch (locType) {
      case locationTypeKey.fixedPercent:
        if (!locationOffset.offset_fixed && !locationOffset.offset_percent) {
          errorMessage = 'Required Fixed Amount and Percentage Value';
          type = locationTypeKey.fixed;
        } else if (!locationOffset.offset_fixed) {
          errorMessage = 'Required Fixed Amount';
          type = locationTypeKey.fixed;
        } else if (!locationOffset.offset_percent) {
          errorMessage = 'Required Percentage Value';
          type = locationTypeKey.percent;
        } else {
          type = locationTypeKey.fixed;
        }
        break;
      case locationTypeKey.percent:
        if (!locationOffset.offset_percent) {
          errorMessage = 'Required Percentage Value';
          type = locationTypeKey.percent;
        } else {
          type = locationTypeKey.percent;
        }
        break;
      case locationTypeKey.fixed:
        if (!locationOffset.offset_fixed) {
          errorMessage = 'Required Fixed Amount';
          type = locationTypeKey.fixed;
        } else {
          type = locationTypeKey.fixed;
        }
    }

    return {
      error: errorMessage,
      type
    }
  }

  function onOffsetDataChange(offsetObj: any) {
    const locArray = [...locArrayTemp];
    const arrayIndex = locArray.findIndex(x => x?.location?.id === offsetObj?.location?.id);
    locArray[arrayIndex] = offsetObj;
    locArrayTemp = locArray;
    setLocationConfigs(locArray);
    checkDataModified(offsetObj);
  }

  function fixedErrorCheck(value: any) {
    if (value === '' || value === null || value === undefined) {
      return true;
    } else {
      return false;
    }
  }

  function percentErrorCheck(value: any, submit: boolean) {
    if (value === '' || (submit && Number(value) === 0) || value === '+' || value === '-') {
      return true;
    } else {
      return false;
    }
  }

  function setFixedValue(value: any, locObj: any) {
    if (value === '' || value === null || value === undefined || (isPositiveNumber(value) && value <= 99999)) {
      locObj.offset_fixed = value
    }
    return locObj;
  }

  function setPercentValue(value: any, locObj: any) {
    if (isValidLocationOffset(value)) {
      locObj.offset_percent = value
    }
    return locObj;
  }

  function onOffsetInputValueChange(locationOffset: any, value: any, offsetType: string, submit: boolean = false) {
    let locObj: any = { ...locationOffset };
    if (locationOffset.offset_type === locationTypeKey.fixed) {
      locObj.showPercentError = false;
    } else if (locationOffset.offset_type === locationTypeKey.percent) {
      locObj.showFixedError = false;
    }
    if (offsetType === locationTypeKey.fixed) {
      locObj.showFixedError = fixedErrorCheck(value);
      locObj = setFixedValue(value, locObj);
    } else if (offsetType === locationTypeKey.percent) {
      locObj.showPercentError = percentErrorCheck(value, submit);
      locObj = setPercentValue(value, locObj);
    }
    onOffsetDataChange(locObj);
    return locObj;
  }

  function showFixedInput(locationOffset: any) {
    return locationOffset.offset_type &&
      (locationOffset.offset_type === locationTypeKey.fixed ||
        locationOffset.offset_type === locationTypeKey.fixedPercent)
  }

  function showPercentInput(locationOffset: any) {
    return locationOffset.offset_type &&
      (locationOffset.offset_type === locationTypeKey.percent ||
        locationOffset.offset_type === locationTypeKey.fixedPercent)
  }

  function onLocationTypeChange(locationOffset: any, locType: string) {
    const locObj: any = { ...locationOffset };
    locObj.offset_type = locType;
    locObj.submit = false;
    if (locType === locationTypeKey.default) {
      locObj.offset_fixed = '0';
      locObj.offset_percent = 0;
    } else {
      locObj.offset_fixed = '';
      locObj.offset_percent = '';
    }
    onOffsetDataChange(locObj);
  }

  function checkDataModified(offsetObj: any) {
    let isDataModified = false;
    const arrayIndex = locationOffsetList.findIndex((x: any) => x?.location?.id === offsetObj?.location?.id);
    if (arrayIndex > -1 &&
      Number(locationOffsetList[arrayIndex].offset_fixed) === Number(offsetObj.offset_fixed) &&
      Number(locationOffsetList[arrayIndex].offset_percent) === Number(offsetObj.offset_percent) &&
      locationOffsetList[arrayIndex].offset_type === offsetObj.offset_type) {
      unsavedChange[offsetObj?.location?.id] = false
    } else {
      unsavedChange[offsetObj?.location?.id] = true;
    }
    for (const key in unsavedChange) {
      if (unsavedChange[key]) isDataModified = true;
    }
    setDataModified(isDataModified);
  }

  useEffect(() => {
    if (version.id) {
      dispatch(fetchLocationOffset(version.id));
    }
  }, [dispatch, version.id])

  useEffect(() => {
    onResetClick();
  }, [locationOffsetList, filters, onResetClick])

  return (
    <div className="location-container">
      <PageTitle paths={[PAGE_DETAILS.configurations, PAGE_DETAILS.locations]} pageDetail={PAGE_DETAILS.locations} />
      {locationConfigs && locationConfigs.length ? <div className="border-line" /> : ''}
      {locationConfigs.map((locationOffset: any, index: number) => {
        return (
          <div key={index}>
            <div className="location-item">
              <div className="location-name"> {locationOffset?.location?.name} </div>
              <div className="location-type"> Type </div>
              <NavDropdown
                title={<div className="filter-types"> {locationType[locationOffset?.offset_type]} </div>}
                disabled={!permissionData.edit}
                id={locationOffset.offset_type}
                onKeyDown={(event: any) => {
                  if (event.keyCode === 13 && hoveredItem !== '') {
                    hoveredItem !== locationOffset.offset_type && onLocationTypeChange(locationOffset, hoveredItem);
                  }
                }}>
                {locationTypes.map((locType: any, locIndex: number) => {
                  return (
                    <div key={locIndex}>
                      <NavDropdown.Item
                        onMouseEnter={() => {
                          hoveredItem = locType
                        }}
                        onMouseLeave={() => {
                          hoveredItem = ''
                        }}
                        onClick={() => {
                          locType !== locationOffset.offset_type && onLocationTypeChange(locationOffset, locType);
                        }}>
                        <div className="location-type">{locationType[locType]}</div>
                      </NavDropdown.Item>
                    </div>
                  );
                })}
              </NavDropdown>
              {showFixedInput(locationOffset) &&
                <div className="fixed input-container">
                  <input
                    value={locationOffset.offset_fixed}
                    disabled={!permissionData.edit}
                    maxLength={5}
                    className={`input-text ${(showInputError(locationOffset, locationTypeKey.fixed) || showInputError(locationOffset, locationTypeKey.fixedPercent)) ? 'invalid' : ''}`}
                    onChange={(e: any) => {
                      onOffsetInputValueChange(locationOffset, e.target.value?.trim(), 'fixed')
                    }}
                    placeholder="INR" />

                  {showInputError(locationOffset, locationTypeKey.fixed) &&
                    <div className="error"> {getInputError(locationOffset, locationTypeKey.fixed).error} </div>}
                  {showInputError(locationOffset, locationTypeKey.fixedPercent) && getInputError(locationOffset, locationTypeKey.fixedPercent).type === locationTypeKey.fixed &&
                    <div className="error"> {getInputError(locationOffset, locationTypeKey.fixedPercent).error} </div>}
                </div>}

              {showPercentInput(locationOffset) &&
                <div className="input-container">
                  <input
                    value={locationOffset.offset_percent}
                    disabled={!permissionData.edit}
                    onKeyDown={blockInvalidChar}
                    className={`input-text percent-text ${showInputError(locationOffset, locationTypeKey.percent) || showInputError(locationOffset, locationTypeKey.fixedPercent, 'percent') ? 'invalid' : ''}`}
                    placeholder="%"
                    onChange={(e: any) => {
                      onOffsetInputValueChange(locationOffset, e.target.value?.trim(), 'percent')
                    }} />

                  {showInputError(locationOffset, locationTypeKey.percent) &&
                    <div className="error"> {getInputError(locationOffset, locationTypeKey.percent).error} </div>}
                  {showInputError(locationOffset, locationTypeKey.fixedPercent, 'percent') && getInputError(locationOffset, locationTypeKey.fixedPercent).type === locationTypeKey.percent &&
                    <div className="error"> {getInputError(locationOffset, locationTypeKey.fixedPercent).error} </div>}
                </div>}

            </div>
            <div className="border-line" />
          </div>
        );
      })}
      {locationConfigs.length ? <div>
        <ActionButtons
          actionBtn1Click={() => onResetClick()}
          actionBtn2Click={() => {
            isNavigateToNext = false;
            onSaveClick()
          }}
          actionBtn3Click={() => {
            isNavigateToNext = true;
            onSaveClick();
          }}
        />
      </div> : ''}
    </div>
  );
};

export default ConfigLocation;
