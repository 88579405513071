import './suggest-salary-card.scss';

const SuggestSalaryCard = ({name, value, color, bgColor}: any) => {
  return(
    <div className="salary-card col" style={{backgroundColor: bgColor, border: '1px solid ' + color}}>
      <div className="card-heading">{name}</div>
      <div className="card-value" style={{color: color}}>{value?.toString().replace(/.(?=(..)*...$)/g, '$&,')}</div>
    </div>
  )
}

export default SuggestSalaryCard