import { useSelector } from 'react-redux';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import './App.scss';
import Loader from './components/loader/loader';
import { isUserAuthenticated } from './store/reducers/authReducer';
import Toaster from './components/toaster/toaster';
import React, { useEffect } from 'react';

function App() {
  const isAuthenticated = useSelector(isUserAuthenticated);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login', { replace: true });
    } else {
      if (location.pathname === "/") {
        navigate('suggest-salary', { replace: true });
      }
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  return (
    <div className="app-div">
      <Loader />
      <Outlet />
      <Toaster />
    </div>
  );
}

export default App;