import { Modal, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { AppDispatch } from '../../../../store/store';
import './style.scss';

import { editExperienceItem } from '../../../../store/reducers/configExperienceReducer';

import StarRating from '../../../../components/StarRating';

import { inputPipe } from '../../../../pipes/InputPipe';
import { REGEX } from '../../../../store/constant';

const EditExperienceModal = (props: any) => {
    const dispatch = useDispatch<AppDispatch>();
    const [editPopupRatingData, setData] = useState(props.data.ratings);
    const [updateDisabled, setUpdateDisabled] = useState(true);

    const changeRating = (event: any, index: number, key: string) => {
        let value = event.target.value?.trim();
        if ((REGEX.integer.test(value) && parseInt(value) <= 9999999 && value.length <=7) || value === '') {
            value = Math.floor(value) === 0 ? inputPipe(value, 9999999) : value;
            let popupRatingData: any = [...editPopupRatingData];
            let unchangedRatingKeys: any = ['minimum', 'maximum', 'median'].filter((rating_key) => rating_key !== key);
            popupRatingData[index] = {
                id: popupRatingData[index].id,
                rating: popupRatingData[index].rating,
                [key]: value,
                [unchangedRatingKeys[0]]: popupRatingData[index][unchangedRatingKeys[0]],
                [unchangedRatingKeys[1]]: key === 'median' ? popupRatingData[index][unchangedRatingKeys[1]]
                    : getMedian(inputPipe(value, 9999999), popupRatingData[index][unchangedRatingKeys[0]]),
            };
            setData(popupRatingData);
            setUpdateDisabled(false);
        }

    }

    const getMedian = (value1: any, value2: any) => {
        return Math.round((Number(value1) + Number(value2)) /2);
    }

    const resetFields = () => {
        setData(props.data.ratings);
        setUpdateDisabled(true);
    }

    const validateFields = () => {
        let dataError: boolean = false
        editPopupRatingData.forEach((rating_data: any) => {
            if (rating_data.minimum === '' || rating_data.maximum === '') {
                dataError = true;
            }
        });

        return dataError ? false : true;
    }

    const editItem = () => {
        let editItemData: any = [];
        if (!validateFields()) {
            return;
        }
        editPopupRatingData.forEach((rating_data: any) => {
            editItemData.push({
                grade_id: props.data.grade.id,
                base_value_offset_id: rating_data.id,
                rating: rating_data.rating,
                minimum: rating_data.minimum,
                maximum: rating_data.maximum,
                median: rating_data.median,
                version: props.versionId
            })
        })

        dispatch(editExperienceItem(editItemData))
        props.onEditItem(editItemData);
    }

    return (
        <Modal show={props.show} onHide={() => props.onHide()} centered size='lg' backdrop='static'>
            <Modal.Header>
                <Modal.Title>
                    {props.title}
                </Modal.Title>
                <img className='btn-close' src='/images/modal-close.svg' onClick={() => props.onHide()} alt='modal-close' />
            </Modal.Header>

            <Modal.Body>
                <div className='grade-section col-12 display-flex'>
                    <div className='modal-column'>
                        <div>Grade (Experience)</div>
                    </div>
                    <div className='modal-column w-25'>
                        <div><input disabled value={props?.data.grade?.name +  ' (' + props?.data.grade?.display_name + ')'} /></div>
                    </div>
                </div>

                <div className='edit-rating-section col-12 display-flex'>
                    <div className='modal-column'>
                        <div className='modal-column-header'></div>
                        <div>Minimum</div>
                        <div>Maximum</div>
                        <div>Median</div>
                    </div>
                    {editPopupRatingData.map((rating: any, index: number) => (
                        <div className='modal-column'>
                            <StarRating class_name='modal-column-header' star_rating={rating.rating} />
                            <div>
                                <input className={rating.minimum === '' ? 'val-error' : ''}
                                    value={rating.minimum}
                                    onInput={e => changeRating(e, index, 'minimum')} />
                                {rating.minimum === '' && <span className='input-error'>Required</span>}
                            </div>
                            <div>
                                <input className={rating.maximum === '' ? 'val-error' : ''}
                                    value={rating.maximum}
                                    onInput={e => changeRating(e, index, 'maximum')} />
                                {rating.maximum === '' && <span className='input-error'>Required</span>}
                            </div>
                            <div>
                                <input className={rating.median === '' ? 'val-error' : ''}
                                    value={rating.median}
                                    onInput={e => changeRating(e, index, 'median')} />
                                {rating.median === '' && <span className='input-error'>Required</span>}
                            </div>
                        </div>
                    ))}
                </div>

            </Modal.Body>

            <Modal.Footer className='actions'>
                <Button className='clear-btn' onClick={() => resetFields()} >Reset</Button>
                <Button className='add-btn' disabled={updateDisabled} onClick={() => editItem()}  >Update</Button>
            </Modal.Footer>

        </Modal>
    )
}

export default EditExperienceModal;