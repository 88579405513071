import './style.scss';
import './ConfigLanding';
import ConfigLanding from './ConfigLanding';

const Configurations = () => {

  return (
    <div>
      <ConfigLanding />
    </div>
  );
};

export default Configurations;
