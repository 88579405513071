import { useState, useEffect, useCallback } from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';

import './styles.scss';

let hoveredItem = {};

const Filter = ({ name, reset=false, options, key1 = "name", key2 = "", onFilterChange, showDefault = false, defaultId = 0 }: any) => {
  const [filterData, setFilterData]: any = useState(showDefault && options[0] ? options[0] : {});
  const showFilterValue = (option: any) => {
    
    if (!option[key1]) return '';
    else return key2 === "" ? option[key1] || '' : `${option[key1]} (${option[key2]})`
  }

  const setDefaultData: any = useCallback(() => {
    if (defaultId === 0) {
      setFilterData(showDefault && options[0] ? options[0] : {})
    } else {
      const arrayIndex = options.findIndex((x: any) => x.id === defaultId);
      if (arrayIndex !== -1) {
        setFilterData(options[arrayIndex])
      } else {
        setFilterData(options[0] ? options[0] : {})
      }
    }
  }, [defaultId, options, showDefault])


  useEffect(() => {
    setDefaultData();
  }, [setDefaultData])

  useEffect(() => {
    if (reset) {
      setDefaultData();
    }
  }, [reset, setDefaultData])

  return (
    <div className="filter-item">
      <div className="filter-name">{name}</div>
      <div>
        <NavDropdown
          title={
            <div className="filter-select">
              <div className="stream-value">{showFilterValue(filterData) ? showFilterValue(filterData) : 'Select ' + name}</div>
              <img className="drop-down" src="/images/dropdown-arrow-blue.svg" alt="Menu" />
            </div>
          }
          onKeyDown={(event: any) => {
            if (event.keyCode === 13 && Object.keys(hoveredItem).length !== 0) {
              setFilterData(hoveredItem);
              onFilterChange(hoveredItem)
            }
          }}
          id="filter" >
          {options.map((option: any, index: number) => {
            return (
              <div key={index}>
                <NavDropdown.Item
                  onMouseEnter={() => {
                    hoveredItem = option
                  }}
                  onMouseLeave={() => {
                    hoveredItem = {}
                  }}
                  onClick={() => {
                    setFilterData(option);
                    onFilterChange(option)
                  }}>
                  <div>{showFilterValue(option)}  </div>
                </NavDropdown.Item>
              </div>
            );
          })}
        </NavDropdown>
      </div>

    </div>
  )
}

export default Filter