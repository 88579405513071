import { Modal, Button } from 'react-bootstrap';
import React, { useRef, useState } from 'react';

import './style.scss';
import '../style.scss'
import FilterGroup from '../FilterGroup';

const EditCombinationModal = (props: any) => {
  const [updateDisabled, setUpdateDisabled] = useState(true);
  const [data, setData] = useState(props.data);
  const filterGroupRef: any = useRef()

  return (
    <div>
      <Modal show={props.show} backdrop="static" onHide={() => props.onHide()} centered size='lg'>
        <div className="edit-combination-container">
          <Modal.Header>
            <Modal.Title>
              {props.title}
            </Modal.Title>
            <img
              className='close-btn'
              src='/images/modal-close.svg'
              onClick={() => {
                props.onEditDataChange(false);
                props.onHide()
              }}
              alt='modal-close' />
          </Modal.Header>

          <Modal.Body>
            <div className='combination-container'>
              <FilterGroup
                combinationData={data}
                combinationConfigs={props.combinationConfigs}
                showActionButton={false}
                ref={filterGroupRef}
                onDataChange={(editCombinationData: any, isMultipleCombination: boolean) => {
                  const isDisabled = JSON.stringify(props.data) === JSON.stringify(editCombinationData);
                  if ((isDisabled !== updateDisabled) && isMultipleCombination) {
                    setUpdateDisabled(isDisabled);
                    props.onEditDataChange(!isDisabled);
                  }
                }}
                updateList={(addCombinationData: any) => {
                  props.onEditItem(addCombinationData)
                }} />
            </div>

          </Modal.Body>

          <Modal.Footer className='actions'>
            <Button className='clear-btn' onClick={() => {
              setData(props.data)
              filterGroupRef.current!.resetData();
            }} >Reset</Button>
            <Button className='add-btn' onClick={() => {
              filterGroupRef.current!.validateData();
            }}
              disabled={updateDisabled}>Update</Button>
          </Modal.Footer>
        </div>
      </Modal>
    </div>
  )


}

export default EditCombinationModal;