import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";

import { AppDispatch } from "../../store/store";
import {
  getUnsavedChangesPresent,
  getPendingLogout,
  updatePendingLogout
} from "../../store/reducers/communicationReducer";
import { getUserData, logout } from '../../store/reducers/authReducer';
import { hideAccessDenied, isShowAccessDenied, setRefreshStatus, toggleSideNav } from "../../store/reducers/generalReducer";
import {
  fetchFilters,
  fetchVersion,
  getVersion,
  fetchUserPermissions,
  setPermissionData,
  setIsPermissionData,
  getUserPermissions,
  getVersions,
  fetchSelectedVersion
} from "../../store/reducers/salarySuggestionReducer";
import './header.scss';
import { PATHS_WITH_REFRESH_BUTTON, PATHS_WITH_CURRENTLY_PUBLISHED } from "../../store/constant";
import { showAccessDeniedToast, showErrorToast } from '../../store/utilFuntions';

const Header = () => {
  const user = useSelector(getUserData);
  const version = useSelector(getVersion);
  const versions = useSelector(getVersions);
  const unsavedChangesPresent = useSelector(getUnsavedChangesPresent);
  const pendingLogout = useSelector(getPendingLogout);
  const userPermissions = useSelector(getUserPermissions);
  const isShowUserAccessDenied = useSelector(isShowAccessDenied);
  const [userData, setUserData] = useState(user);
  const [showInfoText, setShowInfoText] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const setSelectedVersion: any = useCallback(() => {
    let currentSelectedVersion = version;
    let versionType = currentSelectedVersion.is_published ? (currentSelectedVersion.is_active ? 'current' : 'prev') : 'draft';

    if ((versionType === 'draft' && !userPermissions.view_draft) || (versionType === 'prev' && !userPermissions.view_previously_published)) {
      let currentPublished = versions.filter((version: any) => version.is_published && version.is_active)[0] || {};
      sessionStorage.setItem('version', JSON.stringify(currentPublished));
      if (isShowUserAccessDenied) {
        dispatch(hideAccessDenied());
        showAccessDeniedToast();
      }
      navigate('/suggest-salary');
    }
  }, [dispatch, isShowUserAccessDenied, userPermissions.view_draft, userPermissions.view_previously_published, version, versions, navigate])

  useEffect(() => {
    const isCurrentPublished = version.is_published && version.is_active && !version.out_of_sync && userPermissions.modify_current_published
    const pathsToCheck = isCurrentPublished ? PATHS_WITH_CURRENTLY_PUBLISHED : PATHS_WITH_REFRESH_BUTTON;   
    if (pathsToCheck.includes(location.pathname)) {
      setShowInfoText(true);
    } else {
      setShowInfoText(false);
    }
  }, [location, userPermissions.modify_current_published, version]);

  useEffect(() => {
    dispatch(fetchVersion({})).then((data: any) => {
      if (data?.payload?.status === '400') {
        showErrorToast('No available published version');
      }
    });
    dispatch(fetchFilters());
  }, [dispatch]);

  useEffect(() => {
    if (version?.id) {
      dispatch(fetchSelectedVersion(version.id));
    }
  }, [version.id, dispatch])

  useEffect(() => {
    if (user?.id) {
      dispatch(fetchUserPermissions(user.id))
    }
  }, [dispatch, user?.id]);

  useEffect(() => {
    if (version?.id) {
      dispatch(setPermissionData(''));
      setSelectedVersion();
      setTimeout(() => {
        dispatch(setIsPermissionData(true));
      }, 100);
    }
  }, [dispatch, version?.id, userPermissions, setSelectedVersion]);

  const handleRefreshClick = () => {
    if (version?.id) {
      dispatch(setRefreshStatus("refreshing"));
      dispatch(fetchSelectedVersion(version.id));
    }
  }

  const logoutApp = () => {
    if (unsavedChangesPresent) {
      dispatch(updatePendingLogout(true));
    } else {
      if (pendingLogout) {
        dispatch(updatePendingLogout(false));
      }
      dispatch(logout());
    }

    setTimeout(() => {
      navigate('/login');
    }, 100);
  }

  const getCurrentPublishedEditMode = () =>{
    const isCurrentPublishEditMode = showInfoText && !version.in_progress && version.is_published && version.is_active && !version.out_of_sync && userPermissions.modify_current_published && !version.is_republished
    return isCurrentPublishEditMode
  }

  return (
    <>
      <nav id="main-header" className="header navbar navbar-expand-lg navbar-light">
        <div className="left-section">
          <div className="menu-icon" onClick={() => dispatch(toggleSideNav())}>
            <img src="/images/menu.svg" alt="Menu" />
          </div>
          <div className='app-heading-small'>Pay<span className='title-blue-font'>Expert</span></div>

          <div className="version-div">
            <div className="version-name">{version?.name}</div>
            {version.is_published && version.is_active &&
              <span className="version-label">
                <img title="Tick" src="/images/tick.svg" alt="tick" />
                <span className="published-text">Current Published</span>
              </span>
            }
            {version.is_published && !version.is_active && <span className="version-label previous-label published-text">Previously Published</span>}
            {version.is_published === false && <span className="version-label draft-label published-text">Draft</span>}
          </div>

          <div className="info-text">
            { showInfoText && version.in_progress &&
              <span>We are doing the computations for this version. Please refresh to see the updated data
                <span className="refresh-icon" onClick={handleRefreshClick}>
                  <img src="/images/refresh.svg" alt="Refresh" title="Refresh"/>
                </span>
              </span>
            }
            { showInfoText && !version.in_progress && version.out_of_sync &&
              <span>
                Suggested salaries data is out of sync
              </span>
            }
            {  getCurrentPublishedEditMode() && (
              <span>
                The current version has edits made and is available only with the Review & Re-publish page. Re-publish it to make your edits visible everywhere
              </span>
            )}
          </div>
        </div>

        <div className="right-section nav-item dropdown">
          <button className="dropdown-toggle nav-link" id="profileMenu" data-bs-toggle="dropdown" aria-expanded="false">
            <img className="user-avatar" src={userData.image}
              onError={() => setUserData({ ...userData, image: '/images/user_default.png' })} alt="avatar" />
            <span className="username">{userData.name}</span>
          </button>
          <ul className="dropdown-menu" aria-labelledby="profileMenu">
            <li onClick={() => logoutApp()}>
              <button className="dropdown-item">Logout</button>
            </li>
          </ul>
        </div>
      </nav>
    </>

  )
}

export default Header;