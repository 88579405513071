import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { api } from "../../api";
import { RefreshStatusTypes } from "../../models/suggestedSalary";
import { handleApiError } from '../utilFuntions';

const initialState = {
  navCollapsed: false,
  dropdownFilters: {},
  refreshStatus: 'idle',
  isAccessDenied: true,
}

export const getFilters = createAsyncThunk('/get-filters', async () => {
  const response = await api.get(`${process.env.REACT_APP_BACKEND_URL}/api/filters/`);
  return response.data;
})

export const GeneralSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    toggleSideNav: (state: any) => {
      state.navCollapsed = !state.navCollapsed;
    },
    setRefreshStatus: (state: any, action: RefreshStatusPayload) => {
      state.refreshStatus = action.payload;
    },
    showAccessDenied: (state) => {
      state.isAccessDenied = true;
    },
    hideAccessDenied: (state) => {
      state.isAccessDenied = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getFilters.fulfilled, (state, action) => {
      if (action.payload.status === "200") {
        state.dropdownFilters = action.payload.data;
      } else handleApiError(action.payload.status) 
    })
  }
})

export const getSideNavState = (state: any) => state.general.navCollapsed;
export const isShowAccessDenied = (state: any) => state.general.isAccessDenied;
export const getRefreshStatus = (state: any) => state.general.refreshStatus;

export const { toggleSideNav, showAccessDenied, hideAccessDenied, setRefreshStatus } = GeneralSlice.actions;

export default GeneralSlice.reducer;

interface RefreshStatusPayload {
  payload: RefreshStatusTypes
}