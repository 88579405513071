import { api } from "../../api";
import useAxiosLoader from "../../hooks/axiosLoader";
import './loader.scss';

const Loader = () => {
  const loading = useAxiosLoader(api);

  return(
    <div className="loader-overlay" style={{display: loading ? 'block' : 'none'}}>
      <span className="loader"></span>
    </div>
  );
}

export default Loader;