import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify";
import { api } from "../../api";
import { StreamOffset, StreamOffsetObj } from "../../models/stream";
import { handleApiError } from '../utilFuntions';

const initialState: State = {
  streamOffsetRaw: [],
  streamOffset: [],
}

export const fetchStreams = createAsyncThunk('/streams/get', async (versionId: number) => {
  const response = await api.get(`configurations/streams?version_id=${versionId}`);
  return response.data;
})

export const postStreams = createAsyncThunk('/streams/post', async (data: any) => {
  const response = await api.post(`configurations/streams`, data);
  return response.data;
})

export const streamSlice = createSlice({
  name: 'stream',
  initialState,
  reducers: {
    formatFetchData: (state: State, action: any) => {
      let formattedData: any = [];
      const data = action.payload;
      data.forEach((d: any) => {
        let offsetObj!: StreamOffsetObj;
        offsetObj = {
          id: d.id,
          offset: d.offset,
          streamId: d.stream.id,
          streamName: d.stream.display_name
        }
        formattedData.push(offsetObj)
      })
      state.streamOffset = [...formattedData];
    }
  },
  extraReducers:  (builder) => {
    builder.addCase(fetchStreams.fulfilled, (state, action) => {
      if (action.payload.status === "200") {
        state.streamOffsetRaw = action.payload.data;
      } else handleApiError(action.payload.status) 
    })
    builder.addCase(postStreams.fulfilled, (state, action) => {
      if (action.payload.status === "200") {
        toast.success('Offsets for department/stream have been saved successfully');
      } else handleApiError(action.payload.status) 
    })
  }
});

export const getStreams = (state: any) => state.stream.streamOffset;

export const { formatFetchData } = streamSlice.actions;

export default streamSlice.reducer;

interface State {
  streamOffsetRaw: any[],
  streamOffset: StreamOffset[],
}