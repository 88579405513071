import './style.scss';

const StarRating = (props: any) => {
    let full_star: number = 0,
        half_star: number = 0,
        empty_star: number = 0;

    const height = props.height ? `${props.height}px` : `14px`;
    const width = props.width ? `${props.width}px` : '14px';

    switch (props.star_rating) {
        case 3:
            full_star = 3;
            half_star = 0;
            empty_star = 2;
            break;

        case 3.5:
            full_star = 3;
            half_star = 1;
            empty_star = 1;
            break;

        case 4:
            full_star = 4;
            half_star = 0;
            empty_star = 1;
            break;

        case 4.5:
            full_star = 4;
            half_star = 1;
            empty_star = 0;
            break;

        case 5:
            full_star = 5;
            half_star = 0;
            empty_star = 0;
            break;
    }

    return (
        <div className={props.class_name}>
            {[...Array(full_star)].map(() => (
                <img className="rating-img" style={{ height: height, width: width}} src='/images/full_star.svg' alt='full_star' />
            ))}
            {[...Array(half_star)].map(() => (
                <img className="rating-img" style={{ height: height, width: width}} src='/images/half_star.svg' alt='half_star' />
            ))}
            {[...Array(empty_star)].map(() => (
                <img className="rating-img" style={{ height: height, width: width}} src='/images/empty_star.svg' alt='empty_star' />
            ))}
        </div>
    )
}

export default StarRating;