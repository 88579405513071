import React, { useCallback, useEffect, useImperativeHandle, useState } from "react";
import { useSelector } from "react-redux";

import ConfigFilter from '../../../../components/ConfigFilter';
import ConfigRatingFilter from '../../../../components/ConfigRatingfilter';
import { CombinationOffset } from '../../../../models/combination';
import { RATINGS } from '../../../../store/constant';
import { getFilters, getPermissionData, getVersion } from '../../../../store/reducers/salarySuggestionReducer';
import { blockInvalidChar, isLocationOffsetLimit, isValidLocationOffset, nullValueCheck } from '../../../../store/utilFuntions';

import '../style.scss';

const dataOffset: CombinationOffset = {
  offset: '',
  stream: null,
  location: null,
  rating: null,
  version: null,
}

const style1 = { opacity: 1 };

const FilterGroup = React.forwardRef(({
  combinationData = dataOffset,
  combinationConfigs,
  onDataChange,
  showActionButton = true,
  updateList,
  onAddDataModified,
  isAddMode,
  style = {},
}: any, ref) => {
  useImperativeHandle(ref, () => ({
    validateData(addCombination: boolean) {
      setShowValidationError(true);
      validateData(addCombination);
    },
    resetData() {
      resetData();
    }
  }))
  const filters = useSelector(getFilters);
  const [offsetError, setOffsetError]: any = useState(false)
  const [combinationError, setCombinationError]: any = useState(true)
  const [combinationExistError, setCombinationExistError]: any = useState(false)
  const [showValidationError, setShowValidationError] = useState(false)
  const [addCombinationData, setAddCombinationData] = useState(combinationData)
  const [offset, setOffset] = useState(combinationData?.offset)
  const versionId = useSelector(getVersion)?.id;
  const permissionData = useSelector(getPermissionData);

  function isEditData(data: any, combinationConfig: any) {
    return (data.id && (combinationConfig.id === data.id)) ||
      (data.tempId && (combinationConfig.tempId === data.tempId))
  }

  function isSameCombination(obj1: any, obj2: any) {
    return JSON.stringify(obj1) === JSON.stringify(obj2)
  }

  function canProceedValidation() {
    if (isSameCombination(addCombinationData, dataOffset)) {
      setCombinationError(true);
      setOffsetError(false);
      return false;
    }
    return true;
  }

  function getCombinationCount(data: any, count: number) {
    count = nullValueCheck(data) ? count : count + 1;
    return count;
  }

  const isMultipleCombination = useCallback(
    () => {
      let combinationCount = 0;
      combinationCount = getCombinationCount(addCombinationData?.stream?.id, combinationCount);
      combinationCount = getCombinationCount(addCombinationData?.location?.id, combinationCount);
      combinationCount = getCombinationCount(addCombinationData.rating, combinationCount);
      return combinationCount > 1;
    }, [addCombinationData.stream, addCombinationData.location, addCombinationData.rating])

  function isOffsetValue() {
    return nullValueCheck(offset) || offset === '' || !isLocationOffsetLimit(offset) || Number(offset) === 0;
  }

  function isSameCombinationId(obj1: any, obj2: any) {
    const id1 = nullValueCheck(obj1) ? '' : obj1.id;
    const id2 = nullValueCheck(obj2) ? '' : obj2.id;
    return isSameCombination(id1 || '', id2 || '');
  }

  function isDuplicateCombination() {
    let isCombinationExists = false;
    combinationConfigs.map((combinationConfig: any) => {
      if (isSameCombinationId(combinationConfig.stream, addCombinationData.stream) &&
        isSameCombinationId(combinationConfig.location, addCombinationData.location) &&
        Number(combinationConfig.rating || null) === Number(addCombinationData.rating || null) &&
        !isEditData(addCombinationData, combinationConfig)) {
        setCombinationExistError(true);
        setCombinationError(false);
        setOffsetError(false);
        isCombinationExists = true;
        return false;
      }
      return true;
    })
    return isCombinationExists;
  }

  function setCombinationData() {
    const data = {
      ...addCombinationData, ...{
        offset: Number(offset),
        version: versionId
      }
    }
    setAddCombinationData(data)
    if (data.id === null) {
      delete data.id;
    }
    if (nullValueCheck(data.stream) || data.stream.id === null) {
      delete data.stream;
    }
    if (nullValueCheck(data.location) || data.location.id === null) {
      delete data.location;
    }
    if (nullValueCheck(data.rating) || data.rating === undefined) {
      delete data.rating;
    }
    return data;
  }

  function validateData(addCombination: boolean = true) {
    if (!canProceedValidation() || isDuplicateCombination()) {
      return;
    }
    if (!isMultipleCombination()) {
      setCombinationError(true);
      setOffsetError(false);
    } else if (isOffsetValue()) {
      setOffsetError(true);
      setCombinationError(false);
    } else {
      if (!addCombination) return;
      setCombinationError(false);
      setOffsetError(false);
      setCombinationExistError(false);
      const data = setCombinationData();
      updateList(data);
      addDataModified(false);
      setTimeout(() => {
        setAddCombinationData(dataOffset);
        setOffset('')
      }, 100);
    }
  }

  function addDataModified(isModified: boolean) {
    isAddMode && onAddDataModified(isModified);
  }

  function onFilterValueChange(filterData: any, name: string) {
    addDataModified(true);
    setCombinationError(false);
    setCombinationExistError(false);
    setOffsetError(false);
    setShowValidationError(false);
    setOffset('')
    setAddCombinationData({
      ...addCombinationData, ...{
        [name]: name === 'rating' ? filterData.name : filterData,
      }
    })
  }

  function onOffsetValueChange(e: any) {
    if (isValidLocationOffset(e.target.value?.trim())) {
      setOffsetError(false);
      setOffset(e.target.value?.trim())
      setAddCombinationData({
        ...addCombinationData, ...{
          offset: e.target.value?.trim(),
        }
      })
    }
  }

  function resetData() {
    setCombinationError(true);
    setOffsetError(false);
    setCombinationExistError(false);
    addDataModified(false);
    setAddCombinationData(combinationData);
    setOffset(combinationData.offset ? combinationData.offset : '')
  }

  function onAddClick() {
    setShowValidationError(true);
    validateData();
  }

  function showOffsetErrorBorder() {
    return offsetError && showValidationError;
  }

  function showOffsetError() {
    return showOffsetErrorBorder() && (offset === '' || offset === null);
  }

  function isInputDisabled() {
    return !isMultipleCombination();
  }

  function getOffsetStyle() {
    return showOffsetErrorBorder() ? 'invalid' : '';
  }

  function getOffsetError() {
    return isAddMode ? 'Required Percentage Value' : 'Required'
  }

  function getDisabledStyle() {
    return (combinationError || !permissionData.edit || !permissionData.view) ? 'is-disabled' : '';
  }

  function getClearDisabled() {
    return !permissionData.edit ? 'is-disabled' : '';
  }

  function getFilterStyle(filterData: any) {
    return filterData ? style1 : style;
  }

  function getBorderStyle(filterData: any) {
    return (filterData && combinationExistError && showValidationError) ? { borderColor: 'red' } : {};
  }

  useEffect(() => {
    if (!isAddMode) {
      onDataChange(addCombinationData, isMultipleCombination());
    }
  }, [addCombinationData, onDataChange, isAddMode, isMultipleCombination])

  useEffect(() => {
    if (!isMultipleCombination()) {
      setCombinationError(true);
    }
  }, [addCombinationData, isMultipleCombination])


  return (
    <div className="add-data-container add-offset-form">
      <div className="filter-group">
        <span className="filter-name">Location</span>
        <ConfigFilter
          style={getFilterStyle(addCombinationData.location)}
          filterDivStyle={getBorderStyle(addCombinationData.location)}
          options={filters.locations}
          name="Location"
          selected={addCombinationData.location}
          onFilterChange={(filterData: any) => onFilterValueChange(filterData, 'location')} />
      </div>
      <div className="filter-group">
        <span className="filter-name">Department/Stream</span>
        <ConfigFilter
          style={getFilterStyle(addCombinationData.stream)}
          filterKey="display_name"
          filterDivStyle={getBorderStyle(addCombinationData.stream)}
          options={filters.streams}
          name="Department/Stream"
          selected={addCombinationData.stream}
          onFilterChange={(filterData: any) => onFilterValueChange(filterData, 'stream')} />
      </div>
      <div className="filter-group">
        <span className="filter-name">Rating</span>
        <ConfigRatingFilter
          style={getFilterStyle(addCombinationData.rating)}
          filterDivStyle={getBorderStyle(addCombinationData.rating)}
          options={RATINGS}
          name="Rating"
          selected={addCombinationData.rating}
          onFilterChange={(filterData: any) => onFilterValueChange(filterData, 'rating')} />
      </div>
      <div className="rating-input-div">
        <input
          onKeyDown={blockInvalidChar}
          className={`rating-input ${getOffsetStyle()}`}
          placeholder="00.00 %"
          value={offset}
          disabled={isInputDisabled()}
          onChange={(e: any) => onOffsetValueChange(e)}
        />
        {showOffsetError() &&
          <div className="error offset-error"> {getOffsetError()} </div>}
      </div>
      {showActionButton &&
        <div className="action-btn-group actions">
          <div className={`action-btn clear ${getClearDisabled()}`} onClick={() => resetData()}>Clear</div>
          <div className={`action-btn add ${getDisabledStyle()}`} onClick={() => onAddClick()}>Add</div>
        </div>
      }
      {combinationExistError && showValidationError &&
        <div className={`duplicate-error ${!isAddMode ? 'edit-duplicate': ''}`}>An offset has already been added for the chosen combination</div>}
    </div>
  )
})

export default FilterGroup;
