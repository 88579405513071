import { useNavigate } from 'react-router-dom';
import ActionButtons from '../../../components/ActionButtons';
import PageTitle from '../../../components/PageTitle';
import { GENERAL, PAGE_DETAILS, STATUS_CODES_IGNORE } from '../../../store/constant';
import './style.scss';
import { useCallback, useEffect, useState } from 'react';
import { getFilters, getPermissionData, getVersion } from '../../../store/reducers/salarySuggestionReducer';
import { isPositiveNumber, isValidLocationOffset, blockInvalidChar } from '../../../store/utilFuntions';
import { useSelector } from 'react-redux';
import { fetchMarketConditionOffset, getMarketConditionOffset, updateMarketConditionOffset } from '../../../store/reducers/configMarketConditions';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store/store';
import { usePrompt } from '../../../components/ConfirmNavigationPrompt';
import useUnsavedChangesStateUpdates from '../../../hooks/unsavedChangesStateUpdates';
import { NavDropdown } from 'react-bootstrap';


const marketConditionTypeKey = {
  fixed: 'fixed',
  percent: 'percent',
  fixedPercent: 'fixed+percent',
}

const marketConditionType: any = {
  [marketConditionTypeKey.fixed]: 'Fixed',
  [marketConditionTypeKey.percent]: 'Percentage',
  [marketConditionTypeKey.fixedPercent]: 'Fixed + Percentage'
}

const marketConditionTypes = [
  marketConditionTypeKey.fixed, marketConditionTypeKey.percent, marketConditionTypeKey.fixedPercent
]

let isNavigateToNext = false;

let marketConditionArrayTemp: any = [];

let unsavedChange: any = {};

let hoveredItem = '';

const ConfigMarketConditions = () => {
  const navigate = useNavigate();
  const [marketConditionConfigs, setMarketConditionConfigs]: any = useState([]);
  const marketConditionsOffsetList: any = useSelector(getMarketConditionOffset);
  const permissionData = useSelector(getPermissionData);
  const version = useSelector(getVersion);
  const [dataModified, setDataModified] = useState(false);
  const filters: any = useSelector(getFilters);
  const dispatch = useDispatch<AppDispatch>();

  usePrompt(GENERAL.navigation_warning, dataModified);
  useUnsavedChangesStateUpdates(dataModified);

  const onResetClick: any = useCallback(() => {
    const configs: any = [];
    filters.market_condition && filters.market_condition.map((filter: any) => {
      const i = marketConditionsOffsetList.findIndex((e: any) => e.market_condition.id === filter.id);
      if (i > -1) {
        configs.push(marketConditionsOffsetList[i]);
      } else {
        const market_condition = {
          market_condition: filter,
          version: version.id,
          offset_type: marketConditionTypeKey.fixed,
          offset_fixed: '0'
        }
        configs.push(market_condition);
      }
      unsavedChange[filter.id] = false;
      setDataModified(false);
      return true;
    })
    marketConditionArrayTemp = configs;
    setMarketConditionConfigs(configs)
  }, [version.id, marketConditionsOffsetList, filters.market_condition])

  function showInputError(marketConditionOffset: any, offsetType: string, type: string = 'fixed') {
    let showError = false;
    if (offsetType === marketConditionTypeKey.fixedPercent) {
      showError = marketConditionOffset.submit &&
        ((type === 'fixed' && marketConditionOffset.showFixedError) || (type === 'percent' && marketConditionOffset.showPercentError)) &&
        marketConditionOffset.offset_type === marketConditionTypeKey.fixedPercent;

    } else if (offsetType === marketConditionTypeKey.fixed) {
      showError = marketConditionOffset.submit && marketConditionOffset.showFixedError && marketConditionOffset.offset_type === marketConditionTypeKey.fixed;
    } else if (offsetType === marketConditionTypeKey.percent) {
      showError = marketConditionOffset.submit && marketConditionOffset.showPercentError && marketConditionOffset.offset_type === marketConditionTypeKey.percent;
    }
    return showError;
  }

  function getInputError(marketConditionOffset: any, marketConditionType: string) {
    let errorMessage = '';
    let type = '';
    switch (marketConditionType) {
      case marketConditionTypeKey.fixedPercent:
        if (!marketConditionOffset.offset_fixed && !marketConditionOffset.offset_percent) {
          errorMessage = 'Required Fixed Amount and Percentage Value';
          type = marketConditionTypeKey.fixed;
        } else if (!marketConditionOffset.offset_fixed) {
          errorMessage = 'Required Fixed Amount';
          type = marketConditionTypeKey.fixed;
        } else if (!marketConditionOffset.offset_percent) {
          errorMessage = 'Required Percentage Value';
          type = marketConditionTypeKey.percent;
        } else {
          type = marketConditionTypeKey.fixed;
        }
        break;
      case marketConditionTypeKey.percent:
        if (!marketConditionOffset.offset_percent) {
          errorMessage = 'Required Percentage Value';
          type = marketConditionTypeKey.percent;
        } else {
          type = marketConditionTypeKey.percent;
        }
        break;
      case marketConditionTypeKey.fixed:
        if (!marketConditionOffset.offset_fixed) {
          errorMessage = 'Required Fixed Amount';
          type = marketConditionTypeKey.fixed;
        } else {
          type = marketConditionTypeKey.fixed;
        }
    }

    return {
      error: errorMessage,
      type
    }
  }

  function showFixedInput(marketConditionOffset: any) {
    return marketConditionOffset.offset_type &&
      (marketConditionOffset.offset_type === marketConditionTypeKey.fixed ||
        marketConditionOffset.offset_type === marketConditionTypeKey.fixedPercent)
  }

  function showPercentInput(marketConditionOffset: any) {
    return marketConditionOffset.offset_type &&
      (marketConditionOffset.offset_type === marketConditionTypeKey.percent ||
        marketConditionOffset.offset_type === marketConditionTypeKey.fixedPercent)
  }
  
  function onOffsetInputValueChange(marketConditionOffset: any, value: any, offsetType: string, submit: boolean = false) {
    let marketConditionObj: any = { ...marketConditionOffset };
    if (marketConditionOffset.offset_type === marketConditionTypeKey.fixed) {
      marketConditionObj.showPercentError = false;
    } else if (marketConditionOffset.offset_type === marketConditionTypeKey.percent) {
      marketConditionObj.showFixedError = false;
    }
    if (offsetType === marketConditionTypeKey.fixed) {
      marketConditionObj.showFixedError = fixedErrorCheck(value);
      marketConditionObj = setFixedValue(value, marketConditionObj);
    } else if (offsetType === marketConditionTypeKey.percent) {
      marketConditionObj.showPercentError = percentErrorCheck(value, submit);
      marketConditionObj = setPercentValue(value, marketConditionObj);
    }
    onOffsetDataChange(marketConditionObj);
    return marketConditionObj;
  }

  function onOffsetDataChange(offsetObj: any) {
    const marketConditionArray = [...marketConditionArrayTemp];
    const arrayIndex = marketConditionArray.findIndex(x => x?.market_condition?.id === offsetObj?.market_condition?.id);
    marketConditionArray[arrayIndex] = offsetObj;
    marketConditionArrayTemp = marketConditionArray;
    setMarketConditionConfigs(marketConditionArray);
    checkDataModified(offsetObj);
  }

  function checkDataModified(offsetObj: any) {
    let isDataModified = false;
    const arrayIndex = marketConditionsOffsetList.findIndex((x: any) => x?.market_condition?.id === offsetObj?.market_condition?.id);
    if (arrayIndex > -1 &&
      Number(marketConditionsOffsetList[arrayIndex].offset_fixed) === Number(offsetObj.offset_fixed) &&
      Number(marketConditionsOffsetList[arrayIndex].offset_percent) === Number(offsetObj.offset_percent) &&
      marketConditionsOffsetList[arrayIndex].offset_type === offsetObj.offset_type) {
      unsavedChange[offsetObj?.market_condition?.id] = false
    } else {
      unsavedChange[offsetObj?.market_condition?.id] = true;
    }
    for (const key in unsavedChange) {
      if (unsavedChange[key]) isDataModified = true;
    }
    setDataModified(isDataModified);
  }

  function fixedErrorCheck(value: any) {
    if (value === '' || value === null || value === undefined) {
      return true;
    } else {
      return false;
    }
  }

  function percentErrorCheck(value: any, submit: boolean) {
    if (value === '' || (submit && Number(value) === 0) || value === '+' || value === '-') {
      return true;
    } else {
      return false;
    }
  }

  function setFixedValue(value: any, marketConditionObj: any) {
    if (value === '' || value === null || value === undefined || (isPositiveNumber(value) && value <= 99999)) {
      marketConditionObj.offset_fixed = value
    }
    return marketConditionObj;
  }

  function setPercentValue(value: any, marketConditionObj: any) {
    if (isValidLocationOffset(value)) {
      marketConditionObj.offset_percent = value
    }
    return marketConditionObj;
  }

  function onMarketConditionTypeChange(marketConditionOffset: any, marketConditionType: string) {
    const marketConditionObj: any = { ...marketConditionOffset };
    marketConditionObj.offset_type = marketConditionType;
    marketConditionObj.submit = false;
    marketConditionObj.offset_fixed = '';
    marketConditionObj.offset_percent = '';
    onOffsetDataChange(marketConditionObj);
  }

  const navigateToNext = () => {
    if (isNavigateToNext) {
      navigate(PAGE_DETAILS.marketConditions.nextUrl);
    }
  }

  function onSaveClick() {
    let canProceed = true;
    const configs = marketConditionConfigs.map((marketConditionOffset: any) => {
      let marketconditionObj = { ...marketConditionOffset };
      switch (marketConditionOffset.offset_type) {
        case marketConditionTypeKey.fixed:
          marketconditionObj = onOffsetInputValueChange(marketConditionOffset, marketConditionOffset.offset_fixed, marketConditionTypeKey.fixed, true);
          marketconditionObj.offset_percent = null;
          break;
        case marketConditionTypeKey.percent:
          marketconditionObj = onOffsetInputValueChange(marketConditionOffset, marketConditionOffset.offset_percent, marketConditionTypeKey.percent, true);
          marketconditionObj.offset_fixed = null;
          break;
        case marketConditionTypeKey.fixedPercent:
          marketconditionObj = onOffsetInputValueChange(marketConditionOffset, marketConditionOffset.offset_fixed, marketConditionTypeKey.fixed, true);
          marketconditionObj = onOffsetInputValueChange(marketconditionObj, marketConditionOffset.offset_percent, marketConditionTypeKey.percent, true);
          break;
      }

      if (marketconditionObj.showFixedError || marketconditionObj.showPercentError) {
        canProceed = false;
      }
      marketconditionObj.submit = true;

      marketconditionObj = {
        ...marketconditionObj,
        market_condition_id: marketconditionObj.market_condition.id
      }

      return marketconditionObj;
    });
    if (canProceed && dataModified) {
      updateMarketConditionData(configs)
    } else if (canProceed) {
      navigateToNext();
    }
    setMarketConditionConfigs(configs);
  }

  function updateMarketConditionData(configs: any) {
    dispatch(updateMarketConditionOffset(configs)).then((data: any) => {
      if (data?.payload?.status === '200') {
        if (isNavigateToNext) {
          unsavedChange = {};
          setDataModified(false);
          setTimeout(function () {
            navigateToNext();
          }, 100);
        } else {
          dispatch(fetchMarketConditionOffset(version.id));
        }
      } else if(STATUS_CODES_IGNORE.includes(data?.payload?.status)) {
        unsavedChange = {};
        setDataModified(false);
      }
    });
  }

  useEffect(() => {
    if (version.id) {
      dispatch(fetchMarketConditionOffset(version.id));
    }
  }, [dispatch, version.id])

  useEffect(() => {
    onResetClick();
  }, [marketConditionsOffsetList, onResetClick, filters])

  return (
    <div className="location-container">
      <PageTitle paths={[PAGE_DETAILS.configurations, PAGE_DETAILS.marketConditions]} pageDetail={PAGE_DETAILS.marketConditions} />
      {marketConditionConfigs && marketConditionConfigs.length ? <div className="border-line" /> : ''}
      {marketConditionConfigs.map((marketConditionOffset: any, index: number) => {
        return (
          <div key={index}>
            <div className="location-item">
              <div className="location-name"> {marketConditionOffset?.market_condition?.name} </div>
              <div className="location-type"> Type </div>
              <NavDropdown
                title={<div className="filter-types"> {marketConditionType[marketConditionOffset?.offset_type]} </div>}
                disabled={!permissionData.edit}
                id={marketConditionOffset.offset_type}
                onKeyDown={(event: any) => {
                  if (event.keyCode === 13 && hoveredItem !== '') {
                    hoveredItem !== marketConditionOffset.offset_type && onMarketConditionTypeChange(marketConditionOffset, hoveredItem);
                  }
                }}>
                {marketConditionTypes.map((marketconditionType: any, marketconditionIndex: number) => {
                  return (
                    <div key={marketconditionIndex}>
                      <NavDropdown.Item
                        onMouseEnter={() => {
                          hoveredItem = marketconditionType
                        }}
                        onMouseLeave={() => {
                          hoveredItem = ''
                        }}
                        onClick={() => {
                          marketconditionType !== marketConditionOffset.offset_type && onMarketConditionTypeChange(marketConditionOffset, marketconditionType);
                        }}>
                        <div className="location-type">{marketConditionType[marketconditionType]}</div>
                      </NavDropdown.Item>
                    </div>
                  );
                })}
              </NavDropdown>
              {showFixedInput(marketConditionOffset) &&
                <div className="fixed input-container">
                  <input
                    value={marketConditionOffset.offset_fixed}
                    disabled={!permissionData.edit}
                    maxLength={5}
                    className={`input-text ${(showInputError(marketConditionOffset, marketConditionTypeKey.fixed) || showInputError(marketConditionOffset, marketConditionTypeKey.fixedPercent)) ? 'invalid' : ''}`}
                    onChange={(e: any) => {
                      onOffsetInputValueChange(marketConditionOffset, e.target.value?.trim(), 'fixed')
                    }}
                    placeholder="INR" />

                  {showInputError(marketConditionOffset, marketConditionTypeKey.fixed) &&
                    <div className="error"> {getInputError(marketConditionOffset, marketConditionTypeKey.fixed).error} </div>}
                  {showInputError(marketConditionOffset, marketConditionTypeKey.fixedPercent) && getInputError(marketConditionOffset, marketConditionTypeKey.fixedPercent).type === marketConditionTypeKey.fixed &&
                    <div className="error"> {getInputError(marketConditionOffset, marketConditionTypeKey.fixedPercent).error} </div>}
                </div>}

              {showPercentInput(marketConditionOffset) &&
                <div className="input-container">
                  <input
                    value={marketConditionOffset.offset_percent}
                    disabled={!permissionData.edit}
                    onKeyDown={blockInvalidChar}
                    className={`input-text percent-text ${showInputError(marketConditionOffset, marketConditionTypeKey.percent) || showInputError(marketConditionOffset, marketConditionTypeKey.fixedPercent, 'percent') ? 'invalid' : ''}`}
                    placeholder="%"
                    onChange={(e: any) => {
                      onOffsetInputValueChange(marketConditionOffset, e.target.value?.trim(), 'percent')
                    }} />

                  {showInputError(marketConditionOffset, marketConditionTypeKey.percent) &&
                    <div className="error"> {getInputError(marketConditionOffset, marketConditionTypeKey.percent).error} </div>}
                  {showInputError(marketConditionOffset, marketConditionTypeKey.fixedPercent, 'percent') && getInputError(marketConditionOffset, marketConditionTypeKey.fixedPercent).type === marketConditionTypeKey.percent &&
                    <div className="error"> {getInputError(marketConditionOffset, marketConditionTypeKey.fixedPercent).error} </div>}
                </div>}

            </div>
            <div className="border-line" />
          </div>
        );
      })}
      <div>
        <ActionButtons
          actionBtn1Click={() => onResetClick()}
          actionBtn2Click={() => {
            isNavigateToNext = false;
            onSaveClick()
          }}
          actionBtn3Click={() => {
            isNavigateToNext = true;
            onSaveClick();
          }}
        />
      </div>
    </div>
  );
};

export default ConfigMarketConditions;