import { GoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { googleLogin, isUserAuthenticated } from '../../store/reducers/authReducer';
import { AppDispatch } from '../../store/store';
import './login.scss';
import { toast } from 'react-toastify';

const Login = () => {
  const isAuthenticated = useSelector(isUserAuthenticated);
  const dispatch = useDispatch<AppDispatch>();

  const onGoogleLoginSuccess = (res: any) => {
    localStorage.setItem('token', JSON.stringify(res.credential));
    dispatch(googleLogin())
  }

  return isAuthenticated ? (
    <Navigate to='/suggest-salary' replace />
  ) : (
    <div className='login-page'>
      <div className='login-div'>
        <div className='app-heading'>Pay<span className='title-blue-font'>Expert</span></div>
        <div className='login-text'>
          You have reached an access restricted network! <br/>
          Please login with Google to continue
        </div>
        <GoogleLogin
          onSuccess={(res: any) => onGoogleLoginSuccess(res)}
          onError={() => toast.error('Could not sign in! Please try again')}
          shape='pill'
          theme='outline'
          type='standard'
          width='199'
        />
      </div>
    </div>
  )
}

export default Login;