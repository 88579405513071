import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { hideAccessDenied, isShowAccessDenied } from '../../store/reducers/generalReducer';

import { getIsPermissionData } from '../../store/reducers/salarySuggestionReducer';
import { AppDispatch } from '../../store/store';
import { showAccessDeniedToast } from '../../store/utilFuntions';
import { protectedPathnamesForConfig, protectedPathnamesForVersion} from '../../../src/store/constant'
import { getPermissionData, getUserPermissions } from '../../../src/store/reducers/salarySuggestionReducer';

const ProtectedRoute = ({
  redirectPath = '/suggest-salary',
  children
}: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const isPermissionData = useSelector(getIsPermissionData);
  const isShowUserAccessDenied = useSelector(isShowAccessDenied);
  const permissionData = useSelector(getPermissionData);
  const userPermissions = useSelector(getUserPermissions);
  const [isAllowed, setIsAllowed] =  useState<boolean | null>(null);

  const location = useLocation();

  useEffect(()=>{
    if(protectedPathnamesForConfig.includes(location.pathname)){            
      setIsAllowed(permissionData.view && userPermissions.view_configuration)
    }
    if(protectedPathnamesForVersion.includes(location.pathname)){            
      setIsAllowed(userPermissions.view_previously_published || userPermissions.view_draft || userPermissions.add_draft)
    }
  },[location, permissionData, userPermissions])
  

  useMemo(
    () => {      
      if (isAllowed !== null && !isAllowed && isPermissionData) {
        if (isShowUserAccessDenied) {
          dispatch(hideAccessDenied());
          showAccessDeniedToast();
        }
        setTimeout(() => {
          navigate(redirectPath, { replace: true })
        }, 50);
      }
    }
    ,
    [isAllowed, redirectPath, navigate, isPermissionData, dispatch, isShowUserAccessDenied]
  )

  return children ? children : <Outlet />;
};

export default ProtectedRoute;