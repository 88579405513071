import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getPermissionData } from '../../../../store/reducers/salarySuggestionReducer';

import './style.scss';

function ConfigCard({
  index,
  configuration,
}: {
  index: number;
  configuration: any;
}) {
  const navigate = useNavigate();
  const permissionData = useSelector(getPermissionData);

  const navigateToConfig = (url: string) => {
    navigate(url);
  };

  const getBtnText = () => {
    if (!configuration.viewOnly && permissionData.edit && permissionData.view) {
      return 'Edit';
    } else if (permissionData.view) {
      return 'View';
    }
    return '';
  };

  return (
    <div className="config-card ">
      <div className="card-header">
        <div className="card-name">
          <div className="card-number">{index + 1}</div>
          <div className={configuration.isSplit ? 'split-name' : ''}>{configuration.name}</div>
        </div>
        <div>
          <img className="right-arrow" title="arrow" src="/images/config-arrow.svg" alt="arrow" />
        </div>
      </div>
      <div className={`config-card-footer ${configuration.isGraph ? 'config-card-footer' : 'right-align'}`}>
        {configuration.isGraph && <img className="graph-img" src="/images/graph-icon.svg" alt="avatar" />}
        <div className={`view-btn ${getBtnText() === '' ? 'hide-button': ''}`} onClick={() => navigateToConfig(configuration.url)}>{getBtnText()}</div>
      </div>
    </div>
  );
}

export default ConfigCard;