import { Modal, Button } from 'react-bootstrap';
import { useState } from 'react';
import './style.scss';

const CloneVersionModal = (props: any) => {
    const [version_name, setVersionName] = useState('');
    const [input_error, setInputError] = useState('');

    const cloneVersion = () => {
        if (version_name.trim() === '') {
            setInputError('Version name is required');
            return;
        }

        props.onClone(version_name.trim());
    }

    return (
        <Modal className='clone-version-modal' show={props.show} onHide={() => props.onHide()} centered size='lg' backdrop='static'>
            <Modal.Header>
                <Modal.Title>
                    {props.title}
                </Modal.Title>
                <img className='close-btn' src='/images/modal-close.svg' onClick={() => props.onHide()} alt='modal-close' />
            </Modal.Header>

            <Modal.Body>
                <div className='d-flex justify-content-sm-around col-12 clone-modal-body'>
                    <div className='col-3'>
                        <label className='mt-1'>Version Name</label>
                    </div>
                    <div className='col-9 input-field'>
                        <input type='text'
                            maxLength={75}
                            className={input_error ? 'val-error' : ''}
                            value={version_name} onChange={e => {setInputError(''); setVersionName(e.target.value.replace(/\\n|\\t|\\r|\\b|([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,""))}} />
                        <span className='input-error'>{input_error}</span>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer className='actions clone-actions'>
                <Button className='clear-btn' onClick={() => props.onHide()}>Cancel</Button>
                <Button className='add-btn' onClick={() => cloneVersion()}>Save</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CloneVersionModal;