import { Button, Modal } from "react-bootstrap"

const ConfirmationPopup = (props: ConfirmationModalProps) => {
  return (
    <div>
      <Modal show={props.show} onHide={() => props.onClose()} centered size='lg' backdrop='static'>
        <Modal.Header>
          <Modal.Title>
            {props.title}
          </Modal.Title>
          <img className='close-btn' src='/images/modal-close.svg' onClick={() => props.onClose()} alt='modal-close' />
        </Modal.Header>

        <Modal.Body>
          <div dangerouslySetInnerHTML={{__html: props.message}}></div>
        </Modal.Body>

        <Modal.Footer className='actions'>
          <Button className='clear-btn' onClick={() => props.onClose()}>{props.actionButton1Text || 'Cancel'}</Button>
          <Button className='add-btn' onClick={() => props.onConfirm()}>{props.actionButton2Text || 'Yes'}</Button>
        </Modal.Footer>

      </Modal>
    </div>
  )
}

export default ConfirmationPopup;

interface ConfirmationModalProps {
  show: boolean,
  title: string,
  message: string,
  actionButton1Text?: string,
  actionButton2Text?: string,
  onClose: Function,
  onConfirm: Function,
}