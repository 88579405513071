import { toast } from 'react-toastify';
import { DEFAULT_USER_PERMISSION, STATUS_CODES_IGNORE } from './constant';

const postiveDecimalList = ['+'];
const negativeDecimalList = [...postiveDecimalList,'-'];
const twoDigitDecimalNumber = /^[+-]?(0|([1-9][0-9]{0,1})){1}(\.[0-9]{0,2})?$/;
const invalidDecimalNumbers = ['0.00', '-0.00', '+0.00', '00.00', '-00.00', '+00.00'];

const blockInvalidChar = (e: any) => ['e', 'E', ' '].includes(e.key) && e.preventDefault();

const blockInvalidCharQualification = (e: any) => ['e', 'E', ' ', '+', '-'].includes(e.key) && e.preventDefault();

function isPositiveNumber(value: string) {
    const re = /^[0-9\b]+$/;
    return re.test(value)
}

function isPositiveDecimalNumber(value: string) {
    // eslint-disable-next-line
    const re = /^[0-9]{0,7}(\.[0-9]{1,2}|[0-9]{0,2})$/
    return re.test(value)
}

function isdecimalNumber(value: string) {
  // eslint-disable-next-line
  const re = twoDigitDecimalNumber
  return re.test(Math.abs(Number(value)).toString())
}

function toTitleCase(value: string) {
    return value[0].toUpperCase() + value.slice(1);
}

function isValidOffset(value: string, ) {
  if (value === null || value === undefined || postiveDecimalList.includes(value.toString()) ||
  (isPositiveDecimalNumber(value.toString()) && isOffsetLimit(value))) {
    return true
  }
  return false;
}

function isValidLocationOffset(value: string, ) {
  const replaceSign = value?.toString()?.replace('+', '').replace('-', '');
  const decimalLength = replaceSign?.toString()?.split('.')[1] ? replaceSign?.toString()?.split('.')[1]?.length : 0;
  const fixedLength = replaceSign?.toString()?.split('.')[0] ? replaceSign?.toString()?.split('.')[0]?.length : 0;
  
  if (value === null || value === undefined || negativeDecimalList.includes(value.toString()) ||
  (isdecimalNumber(value.toString()) && decimalLength <= 2 && fixedLength <= 2 && isLocationOffsetLimit(value) && 
  !invalidDecimalNumbers.includes(value.toString()))) {
    return true
  }
  return false;
}

function isLocationOffsetLimit(value: string) {
  if (Number(value) >= -99.99 && Number(value) <= 99.99 ) {
    return true
  }
  return false;
}

function isOffsetLimit(value: string) {
  if (Number(value) >= 0.01 && Number(value) <= 99.99) {
    return true
  }
  return false;
}

function nullValueCheck(value: any) {
  return value === null || value === undefined;
}

function getSortValue(value: any, ignoreSpecialChar: boolean) {
  if (!ignoreSpecialChar) return value.toString();
  const replacedValue = value.toString().split(/[^A-Za-z]/).join('')
  if (replacedValue) {
    return replacedValue.replace(/[^a-zA-Z0-9 ]/g, "");
  }
  return value.toString();
}

function sortList(data: any, key1: string, ignoreSpecialChar=false, key2?: string) {
  let arr = [...data];
  if (!arr || !arr.length) return;
  if(key2) arr = arr.sort((a: any, b: any) => getSortValue(a[key1][key2], ignoreSpecialChar).localeCompare(getSortValue(b[key1][key2], ignoreSpecialChar)))
  else arr = arr.sort((a: any, b: any) => getSortValue(a[key1],ignoreSpecialChar).localeCompare(getSortValue(b[key1], ignoreSpecialChar)))
  return arr;
}

function scrollToTop(delay: number = 300) {
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, delay);
}

const getPermissionData = (view: boolean, edit: boolean) => {
  const permissions = {...DEFAULT_USER_PERMISSION};
  if (view) {
    permissions.view = true;
    if (edit) {
      permissions.edit = true;
    }
  }
  return permissions;
};

function getPermissionByVersion(versionData: any, userPermissions: any) {
  let userPermission;
    if (versionData.is_active && versionData.is_published) {
      userPermission = getPermissionData(true, userPermissions.modify_current_published);
    } else if (versionData.is_published && !versionData.is_active) {
      userPermission = getPermissionData(userPermissions.view_previously_published, false);
    } else if(!versionData.is_published) {
      userPermission = getPermissionData(userPermissions.view_draft, userPermissions.modify_draft);
    } else {
      userPermission = getPermissionData(false, false);
    }
    return userPermission
}

function showErrorToast(msg?: string) {
  toast.dismiss();
  toast.error(msg || 'Something went wrong. Please try again');
}

function handleApiError(status: string) {
  if (!STATUS_CODES_IGNORE.includes(status)) {
    showErrorToast();
  }
}

function showAccessDeniedToast() {
  toast.dismiss();
  toast.error('Sorry! Access denied');
}

export {
    isPositiveNumber,
    isPositiveDecimalNumber,
    toTitleCase,
    isValidOffset,
    isOffsetLimit,
    isValidLocationOffset,
    isLocationOffsetLimit,
    twoDigitDecimalNumber,
    blockInvalidChar,
    nullValueCheck,
    sortList,
    scrollToTop,
    blockInvalidCharQualification,
    getPermissionByVersion,
    showErrorToast,
    handleApiError,
    showAccessDeniedToast
}