import { ToastContainer } from 'react-toastify';
import './toaster.scss';

const Toaster = () => {
  return(
    <ToastContainer
      position='top-right'
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme='colored'
    />
  )
}

export default Toaster;