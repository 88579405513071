import { useState } from 'react';
import { Button, Modal } from "react-bootstrap";

import './style.scss'

const DeleteConfirmationPopup = (props: DeleteConfirmationModalProps) => {
  const [deleteText, setDeleteText] = useState('')
  return (
    <div>
      <Modal show={props.show} onHide={() => props.onClose()} centered size='lg' backdrop='static'>
        <div className="delete-conatiner">
          <Modal.Header>
            <Modal.Title>
              {props.title}
            </Modal.Title>
            <img className='close-btn' src='/images/modal-close.svg' onClick={() => props.onClose()} alt='modal-close' />
          </Modal.Header>

          <Modal.Body>
            <div className="message-container">
              <div className="message-txt"> {`${props.message}, please enter`}</div>
              <div className="delete-txt"> DELETE </div>
              <div className="message-txt"> and click confirm button. </div>
              <div>
                <input
                  value={deleteText}
                  maxLength={6}
                  type="text"
                  className="input-text"
                  onPaste={(e: any) => {
                    e.preventDefault();
                    return false;
                  }}
                  onChange={(e: any) => setDeleteText(e.target.value.toUpperCase().replace(/[^a-z]/gi, ''))}
                  placeholder="Enter text here"
                  onFocus={(e) => e.target.placeholder = ""}
                  onBlur={(e) => e.target.placeholder = "Enter text here"} />

              </div>
            </div>
          </Modal.Body>

          <Modal.Footer className='actions delete-container'>
            <Button className='cancel-btn' onClick={() => props.onClose()}>{props.actionButton1Text || 'Cancel'}</Button>
            <Button className='delete-btn' disabled={deleteText !== 'DELETE'} onClick={() => props.onConfirm()}>{props.actionButton2Text || 'Confirm'}</Button>
          </Modal.Footer>
        </div>
      </Modal>
    </div>
  )
}

export default DeleteConfirmationPopup;

interface DeleteConfirmationModalProps {
  show: boolean,
  title: string,
  message: string,
  actionButton1Text?: string,
  actionButton2Text?: string,
  onClose: Function,
  onConfirm: Function,
}