import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { api } from "../../api";

const userData = JSON.parse(localStorage.getItem('userData') || '{}');
const initialState = {
  isAuthenticated: !!userData?.id,
  userData
}

export const googleLogin = createAsyncThunk('user/login', async () => {
  const response = await api.post(`${process.env.REACT_APP_BACKEND_URL}/api/google-login/`, {});
  return response.data;
})

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
      localStorage.clear();
      sessionStorage.clear();
    }
  },
  extraReducers: (builder) => {
    builder.addCase(googleLogin.fulfilled, (state, action) => {
      if (action.payload.status === "200") {
        state.isAuthenticated = true;
        state.userData = action.payload.data;
        localStorage.setItem('userData', JSON.stringify(action.payload.data));
      } else {
        toast.error(<div>Invalid user. <br/> Please try with a valid user account</div>);
      }
    })
  }
});

export const getUserData = (state: any) => state.auth.userData;
export const isUserAuthenticated = (state: any) => state.auth.isAuthenticated;

export const { logout } = authSlice.actions;

export default authSlice.reducer;