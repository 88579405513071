import { useEffect, useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ActionButtons from '../../../components/ActionButtons';
import ConfirmationPopup from '../../../components/ConfirmationPopup';
import { usePrompt } from '../../../components/ConfirmNavigationPrompt';
import PageTitle from '../../../components/PageTitle';
import useUnsavedChangesStateUpdates from '../../../hooks/unsavedChangesStateUpdates';
import { StreamOffsetFieldType, StreamOffsetObj } from '../../../models/stream';
import { GENERAL, PAGE_DETAILS, STATUS_CODES_IGNORE } from '../../../store/constant';
import { fetchStreams, formatFetchData, getStreams, postStreams } from '../../../store/reducers/configStreamReducer';
import { getFilters, getPermissionData, getVersion } from '../../../store/reducers/salarySuggestionReducer';
import { AppDispatch } from '../../../store/store';
import { scrollToTop, sortList, twoDigitDecimalNumber } from '../../../store/utilFuntions';
import EditStreamModal from './EditStreamModal';
import './style.scss';

const ConfigStream = () => {
  const initialFormValue: StreamOffsetObj = {
    streamId: 0,
    streamName: '',
    offset: '',
    id: 0,
    isAddedOrModified: true,
  };
  const initialFormErrors = {
    offset: '',
    duplicate: '',
  }
  const version = useSelector(getVersion);
  const streamTableAPI = useSelector(getStreams);
  const dropdownFilters = useSelector(getFilters);
  const permissionData = useSelector(getPermissionData);
  const [streamOffsetForm, setStreamOffsetForm] = useState(initialFormValue);
  const [formErrors, setFormErrors] = useState(initialFormErrors);
  const [streamTable, setStreamTable] = useState(streamTableAPI);
  const [showModal, setShowModal] = useState('');
  const [popupData, setPopupData] = useState({});
  const [addDisabled, setAddDisabled] = useState(true);
  const [dataModified, setDataModified] = useState(false);
  const [modalIndex, setModalIndex] = useState(-1);
  const [deletedOffsets, setDeletedOffsets] = useState<StreamOffsetObj[]>([]);
  const [streams, setStreams] = useState(dropdownFilters.streams || []);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  let hoveredItem: any = {};

  const getSelectedStream = () => {
    let selectedStreamName = streamOffsetForm.streamName || 'Select Department/Stream';
    return selectedStreamName;
  }

  usePrompt(GENERAL.navigation_warning, dataModified);
  useUnsavedChangesStateUpdates(dataModified);

  useEffect(() => {
    if (version?.id) {
      dispatch(fetchStreams(version.id)).then((res: any) => {
        dispatch(formatFetchData(res.payload.data));
      })
    }
  }, [dispatch, version?.id])

  useEffect(() => {
    scrollToTop(20);
}, [])

  useEffect(() => {
    setStreamTable(streamTableAPI);
  }, [streamTableAPI])

  useEffect(() => {
    if (dropdownFilters.streams) {
      setStreams(dropdownFilters.streams)
    }
  }, [dropdownFilters])

  const setErrors = () => {
    let errorObj = { ...formErrors }
    if (checkForDuplicates()) {
      errorObj = { ...formErrors, duplicate: 'An offset has already been added for the chosen stream' };
      setFormErrors(errorObj);
      setAddDisabled(true);
      return errorObj;
    }
    checkForFieldErrors(errorObj);
    setFormErrors(errorObj);
    return errorObj;
  }

  const addStreamOffset = () => {
    if (!permissionData.edit) return;
    let errorObj = setErrors();
    if (getErrorCount(errorObj) === 0) {
      let streamTableData = sortList([...streamTable, streamOffsetForm], 'streamName', false,) || [...streamTable, streamOffsetForm];
      setDataModified(true);
      setStreamTable([...streamTableData]);
      clearOffset();
    }
  }

  const checkForDuplicates = () => {
    let index = streamTable.findIndex((row: any) => row.streamId === streamOffsetForm.streamId);
    return index !== -1;
  }

  const checkForFieldErrors = (errorObj: any, value?: string) => {
    let val;
    let chars = ['.', '-', '+'];
    if (value !== undefined) {
      val = parseFloat(value);
    } else {
      value = streamOffsetForm.offset
      val = parseFloat(value);
    }

    if (!val && val !== 0 && !chars.includes(value || '')) {
      errorObj.offset = 'Required Percentage Value';
      setAddDisabled(true);
    } else if (!(val >= -99.99 && val <= 99.99) || val === 0 || chars.includes(value || '')) {
      errorObj.offset = 'Invalid';
      setAddDisabled(true);
    } else {
      errorObj.offset = '';
    }

    if (getErrorCount(errorObj) === 0) {
      setAddDisabled(false);
    }
  }

  const getErrorCount = (errorObj: any) => {
    let count = 0;
    Object.keys(errorObj).forEach((field: string) => {
      if (errorObj[field as StreamOffsetFieldType]) count += 1;
    })
    return count;
  }

  const clearOffset = () => {
    setStreamOffsetForm(initialFormValue);
    setFormErrors(initialFormErrors);
    setAddDisabled(true);
  }

  const changeInput = (e: any) => {
    let value: string = e.target.value?.trim();
    if (value[0] === '.') value = '0' + value;

    if ((twoDigitDecimalNumber.test(value) && value !== '0.00') || value.length === 0 || value === '-' || value === '+') {
      setStreamOffsetForm({ ...streamOffsetForm, offset: e.target.value?.trim() });
      if (!addDisabled) return;
      let errorObj = { ...formErrors };
      checkForFieldErrors(errorObj, e.target.value?.trim());
      setFormErrors(errorObj);
    }
  }

  const openEditPopup = (index: number) => {
    setPopupData({
      ...streamTable[index]
    })
    setShowModal('edit');
  }

  const handleEditItem = (val: any, index: number) => {
    val = {
      ...val,
      isAddedOrModified: true
    };
    setDataModified(true);
    setStreamTable([...streamTable.slice(0, index), val, ...streamTable.slice(index + 1, streamTable.length)]);
    toast.success('Offset for department/stream has been updated successfully');
    setShowModal('');
  }

  const handleClose = () => {
    setShowModal('');
  }

  const onResetClick = () => {
    dispatch(fetchStreams(version.id)).then((res: any) => {
      if (STATUS_CODES_IGNORE.includes(res.payload.status)) {
        setDataModified(false);
      } else {
        dispatch(formatFetchData(res.payload.data));
        setStreamOffsetForm(initialFormValue);
        setFormErrors(initialFormErrors);
        setDeletedOffsets([]);
        setDataModified(false);
      }
    })
  }

  const saveStreamOffset = (shouldNavigate: boolean = false) => {
    let payload: any = [];
    deletedOffsets.forEach((row: StreamOffsetObj) => {
      payload.push({
        id: row.id,
        version: version?.id,
        delete: true
      })
    })
    streamTable.forEach((row: StreamOffsetObj) => {
      if (row.isAddedOrModified) {
        payload.push({
          version: version?.id,
          id: row.id,
          offset: row.offset || null,
          stream: {
            id: row.streamId,
            name: row.streamName,
          }
        })
      }
    })
    if (streamOffsetForm.streamName) {
      setErrors();
      scrollToTop();
      return;
    }
    setDataModified(false);
    if (payload.length === 0) {
      if (shouldNavigate) {
        setTimeout(() => {
          navigate(PAGE_DETAILS.stream.nextUrl);
        }, 100)
      }
      return;
    }
    dispatch(postStreams(payload)).then((res: any) => {
      if (res.payload.status === '200') {
        if (shouldNavigate) {
          navigate(PAGE_DETAILS.stream.nextUrl);
        } else {
          onResetClick();
        }
      } else if (!STATUS_CODES_IGNORE.includes(res.payload.status)) {
        setDataModified(true);
      }
    })
  }

  const onStreamChange = (option: any) => {
    if (addDisabled) {
      setAddDisabled(false);
    }
    setStreamOffsetForm({
      ...initialFormValue,
      streamId: option.id,
      streamName: option.display_name,
    })
    setFormErrors(initialFormErrors);
  }

  const openDeletePopup = (index: number) => {
    setShowModal('delete');
  }

  const handleDeleteItem = () => {
    //remove duplicate error if duplicate item is deleted
    if (streamTable[modalIndex].streamId === streamOffsetForm.streamId) {
      setAddDisabled(false);
      setFormErrors({...formErrors, duplicate: ''});
    }

    let currentDeleted = [];
    if (streamTable[modalIndex]?.id) {
      currentDeleted = [...deletedOffsets, streamTable[modalIndex]];
      setDeletedOffsets(currentDeleted);
      setDataModified(true);
    }
    let updatedStreamTable = [...streamTable.slice(0, modalIndex), ...streamTable.slice(modalIndex + 1, streamTable.length)];
    setStreamTable(updatedStreamTable)
    toast.success('Offset for department/stream has been deleted successfully');
    setShowModal('');

    //update data modified flag after deletion
    let modified: boolean = false;
    modified = updatedStreamTable.some((row: StreamOffsetObj) => row.isAddedOrModified) || (currentDeleted.length !== 0);
    setDataModified(modified);
  }

  return (
    <div className="stream-config">
      <PageTitle paths={[PAGE_DETAILS.configurations, PAGE_DETAILS.stream]} pageDetail={PAGE_DETAILS.stream} />

      <div className="add-offset-form">
        <div className="d-flex align-items-center">
          Department/Stream
          <div className={formErrors.duplicate ? 'add-dropdown val-error' : 'add-dropdown'}>
            <NavDropdown
              onKeyDown={(event: any) => {
                if (event.keyCode === 13 && Object.keys(hoveredItem).length !== 0) {
                  onStreamChange(hoveredItem);
                }
              }}
              disabled={!streams?.length || !permissionData.edit}
              title={<div className="dropdown-placeholder"
                style={streamOffsetForm.streamId ? { opacity: 1 } : {}}> {getSelectedStream()}</div>} id="filter" >
              {streams?.map((option: any, index: number) => {
                return (
                  <div key={index}>
                    <NavDropdown.Item
                      onClick={() => onStreamChange(option)}
                      onMouseEnter={() => {
                        hoveredItem = option
                      }}
                      onMouseLeave={() => {
                        hoveredItem = {}
                      }}>
                      <div>{option.display_name}</div>
                    </NavDropdown.Item>
                  </div>
                );
              })}
            </NavDropdown>
          </div>
        </div>

        <div className="rating-input-div">
          <input
            className={formErrors.offset ? 'rating-input val-error' : 'rating-input'}
            value={streamOffsetForm.offset} disabled={!streamOffsetForm.streamId}
            onChange={(e: any) => changeInput(e)} placeholder='00.00' />
          <span className='input-error'>{formErrors.offset === 'Invalid' ? '' : formErrors.offset}</span>
        </div>

        <div className="actions d-flex">
          <button className='btn clear-btn' onClick={clearOffset} disabled={!permissionData.edit}>Clear</button>
          <button className='btn add-btn' onClick={addStreamOffset} disabled={addDisabled}>Add</button>
        </div>

        <div className='duplicate-error'>
          {formErrors.duplicate}
        </div>
      </div>

      <div className="stream-offset-table">
        {
          streamTable.length === 0 ? <div className='d-flex justify-content-center fw-bold stream-list'>No Data</div> :
            <table className='listing-table stream-list'>
              <tbody>
                {streamTable.map((stream: any, index: number) => {
                  
                  return (
                    <tr key={stream.streamId}>
                      <td key='name'>{stream.streamName}</td>
                      <td key='offset'>{stream.offset}</td>
                      {permissionData.edit &&
                        <td key='edit'>
                          <img className='edit-icon' src='/images/edit.svg' alt='edit' onClick={() => { setModalIndex(index); openEditPopup(index) }} />
                          <img className='delete-icon' src='/images/delete.svg' alt='delete' onClick={() => { setModalIndex(index); openDeletePopup(index) }} />
                        </td>
                      }
                    </tr>
                  );
                })}
              </tbody>
            </table>
        }
        {showModal === 'edit' && <EditStreamModal
          show={showModal === 'edit'}
          title='Edit Department/Stream - Offset'
          data={popupData}
          onEditItem={(val: any) => handleEditItem(val, modalIndex)}
          onHide={handleClose}
          resetData={() => openEditPopup(modalIndex)} />}

        {showModal === 'delete' && <ConfirmationPopup
          show={showModal === 'delete'}
          title='Delete Department/Stream - Offset'
          message={`Are you sure you want to remove the offset for <span class='text-semibold'>${streamTable[modalIndex].streamName}</span>?`}
          onClose={handleClose}
          onConfirm={handleDeleteItem} />}
      </div>
      <div>
        <ActionButtons
          actionBtn1Click={onResetClick}
          actionBtn2Click={saveStreamOffset}
          actionBtn3Click={() => saveStreamOffset(true)}
        />
      </div>
    </div>
  )
}

export default ConfigStream;