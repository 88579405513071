import { useEffect, useState } from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useSelector } from 'react-redux';
import { getPermissionData } from '../../store/reducers/salarySuggestionReducer';
import StarRating from '../StarRating';
import './styles.scss';

let hoveredItem: any = {};

const ConfigRatingFilter = ({ name, selected, options, onFilterChange, style = {}, filterDivStyle={} }: any) => {
  const [rating, setRating] = useState(selected ? selected : '');
  const permissionData = useSelector(getPermissionData);

  useEffect(() => {
    setRating(selected);
  }, [selected])

  return (
    <div className="config-rating-filter" style={filterDivStyle}>
      <div>
        <NavDropdown
          onKeyDown={(event: any) => {
            if (event.keyCode === 13 && Object.keys(hoveredItem).length !== 0) {
              setRating(hoveredItem.name);
              onFilterChange(hoveredItem)
            }
          }}
          disabled={!permissionData.edit}
          title={rating ?
            <StarRating star_rating={rating} />
            :
            <span style={style}>{'Select ' + name}</span>} id="ratingFilter" >
          {options.map((option: any, index: any) => {
            return (
              <div key={index}>
                <NavDropdown.Item
                  onMouseEnter={() => {
                    hoveredItem = option
                  }}
                  onMouseLeave={() => {
                    hoveredItem = {}
                  }}
                  onClick={() => {
                    setRating(option.name);
                    onFilterChange(option)
                  }}>
                  <StarRating star_rating={option.name} />
                </NavDropdown.Item>
              </div>
            );
          })}
        </NavDropdown>
      </div>
    </div>
  )
}

export default ConfigRatingFilter