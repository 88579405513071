import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/authReducer";
import communicationReducer from "./reducers/communicationReducer";
import generalReducer from "./reducers/generalReducer";
import salarySuggestionReducer from "./reducers/salarySuggestionReducer";
import configExperienceReducer from "./reducers/configExperienceReducer";
import configStreamReducer from "./reducers/configStreamReducer";
import configLocationReducer from './reducers/configLocationReducer';
import configCombinationReducer from './reducers/configCombinationReducer';
import configQualificationsCertificationsReducer from "./reducers/configQualificationsCertificationsReducer";
import suggestedSalaryReducer from "./reducers/suggestedSalaryReducer";
import configMarketConditions from "./reducers/configMarketConditions";

const combinedReducers = combineReducers({
  auth: authReducer,
  communication: communicationReducer,
  salary: salarySuggestionReducer,
  general: generalReducer,
  experience: configExperienceReducer,
  stream: configStreamReducer,
  location: configLocationReducer,
  qualificationsCertifications: configQualificationsCertificationsReducer,
  combination: configCombinationReducer,
  marketCondition: configMarketConditions,
  suggestedSalaries: suggestedSalaryReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'auth/logout') {
    state = undefined;
  }
  return combinedReducers(state, action)
}

export const store = configureStore({
  reducer: rootReducer
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch