import { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { createRoot } from 'react-dom/client';

import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../store/reducers/authReducer';
import { getPendingVersionToBeUpdated, getPendingLogout, updatePendingVersionToBeUpdated, updatePendingLogout } from '../../store/reducers/communicationReducer';
import { setVersion } from '../../store/reducers/salarySuggestionReducer';

const clearKeys = (dispatch: any, pendingVersionToBeUpdated: any, pendingLogout: boolean) => {
    if (pendingVersionToBeUpdated) {
        dispatch(updatePendingVersionToBeUpdated(null));
    }
    if (pendingLogout) {
        dispatch(updatePendingLogout(false));
    }
}

/**
 * Prompts the user with an Alert before they leave the current screen.
 *
 * @param  message
 * @param  when
 */
export function usePrompt(message: string, when = true) {
    const pendingVersionToBeUpdated = useSelector(getPendingVersionToBeUpdated);
    const pendingLogout = useSelector(getPendingLogout);
    const dispatch = useDispatch();
    let blocker = useBlocker(when);    
   
    useEffect(()=>{        
        enableScroll();
            window.addEventListener('scroll', disableScroll); // to prevent the background content from scrolling before confirmation
            let openedModals = document.body.getElementsByClassName('modal');
            if (openedModals.length) {
                let topModal = openedModals[openedModals.length - 1];
                topModal.classList.add('modal-static');
                setTimeout(() => topModal.classList.remove('modal-static'), 150);
                return;
            }
            const element = document.createElement('div');
            const elementRoot = createRoot(element);
            element.setAttribute('id', 'prompt-dialog-container');
            element.setAttribute('aria-hidden', 'true');

            const closePrompt = (state: boolean) => {
                if (element) {
                    elementRoot.unmount();
                }
                if (!state) {
                    clearKeys(dispatch, pendingVersionToBeUpdated, pendingLogout);

                    document.body.removeChild(element);
                    if(blocker && typeof blocker.reset === 'function'){
                        blocker && blocker.reset()
                    }
                } else {
                    if (pendingVersionToBeUpdated) {
                        dispatch(setVersion(pendingVersionToBeUpdated));
                    }

                    if (pendingLogout) {
                        dispatch(logout());
                    }

                    clearKeys(dispatch, pendingVersionToBeUpdated, pendingLogout);
                    if(blocker && typeof blocker.proceed === 'function'){
                        blocker  && blocker.proceed()
                    }
                }
                enableScroll();
            };

            document.body.appendChild(element);
            if(blocker.state === 'blocked'){
                elementRoot.render(
                    <Modal
                        centered
                        show={!!message}
                        message={message}
                        backdrop="static"
                        onHide={() => closePrompt(false)}>

                        <Modal.Header>
                            <Modal.Title>
                                Unsaved Changes!
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            {message}
                        </Modal.Body>

                        <Modal.Footer className='actions'>
                            <Button className='clear-btn' onClick={() => closePrompt(false)} >Cancel</Button>
                            <Button className='add-btn' onClick={() => closePrompt(true)}  >OK</Button>
                        </Modal.Footer>
                    </Modal>
                )
            }
    },[message, dispatch, pendingVersionToBeUpdated, pendingLogout, when, blocker])
}
const enableScroll = () => {
    window.removeEventListener('scroll', disableScroll);
}

const disableScroll = () => {
    let scrollTop = document.documentElement.scrollTop;
    let scrollLeft = document.documentElement.scrollLeft;
    window.scrollTo(scrollLeft, scrollTop);
}
