import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Provider } from 'react-redux';
import { store } from './store/store';
import 'bootstrap/dist/js/bootstrap.js';
import './fonts/MuseoSansRounded-500.otf';
import App from './App';
import Login from './modules/login/login';
import Layout from './modules/layout/layout';
import SuggestSalary from './modules/suggest-salary';
import Configurations from './modules/Configurations';
import Versions from './modules/Versions';
import ProtectedRoute from './modules/ProtectedRoute';
import ConfigExperience from './modules/Configurations/ConfigExperience';
import ConfigLocation from './modules/Configurations/ConfigLocation';
import ConfigStream from './modules/Configurations/ConfigStream';
import ConfigCombination from './modules/Configurations/ConfigCombination';
import ConfigQualificationsCertifications from './modules/Configurations/ConfigQualificationsCertifications';
import ConfigReviewAndPublish from './modules/Configurations/ConfigReviewAndPublish';
import SuggestedSalaries from './modules/suggested-salaries';
import ConfigCompute from './modules/Configurations/ConfigCompute';
import ConfigMarketConditions from './modules/Configurations/ConfigMarketConditions';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      { path: 'login', element: <Login /> },
      {
        path: '/',
        element: <Layout />,
        children: [
          { 
            path: 'suggest-salary', element: <SuggestSalary /> },
            {
              element: <ProtectedRoute/>,
              children: [
                { path: 'versions', element: <Versions /> }
              ],
            },
          {
            element: <ProtectedRoute isFromConfiguration={true}/>,
            children: [
              { path: 'configurations', element: <Configurations /> },
              { path: 'configurations/experience', element: <ConfigExperience /> },
              { path: 'configurations/location', element: <ConfigLocation /> },
              { path: 'configurations/stream', element: <ConfigStream /> },
              { path: 'configurations/combination-overrides', element: <ConfigCombination /> },
              { path: 'configurations/qualifications-certifications', element: <ConfigQualificationsCertifications /> },
              { path: 'configurations/market-conditions', element: <ConfigMarketConditions /> },
              { path: 'configurations/compute', element: <ConfigCompute /> },
              { path: 'configurations/review-publish', element: <ConfigReviewAndPublish /> },
            ],
          },
          {
            path: 'suggested-salaries',
            element: (
              <SuggestedSalaries
                editMode={false}
                getApiUrl={'suggested-salary/list-page'}
                pageId='suggestedSalaries'
              />
            ),
          },
        ],
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID!}>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </GoogleOAuthProvider>
);

reportWebVitals();