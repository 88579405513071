import './style.scss';

import ConfigCard from './ConfigCard';
import PageTitle from '../../../components/PageTitle';
import { PAGE_DETAILS, STATUS_CODES_IGNORE } from '../../../store/constant';
import { useDispatch, useSelector } from 'react-redux';
import { deleteVersion, fetchVersion, getPermissionData, getUserPermissions, getVersion, setVersion } from '../../../store/reducers/salarySuggestionReducer';
import React, { useState } from 'react';
import { AppDispatch } from '../../../store/store';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DeleteConfirmationPopup from './DeleteConfirmationPopup';

const ConfigLanding = () => {
  const navigate = useNavigate();
  const permissionData = useSelector(getPermissionData);
  const userPermissions = useSelector(getUserPermissions);
  const version = useSelector(getVersion);
  
  const configList = [
    {
      id: 1,
      name: 'Grade (Experience)',
      url: 'experience',
      isGraph: true,
    },
    {
      id: 2,
      name: 'Locations',
      url: 'location',
      isGraph: true,
    },
    {
      id: 3,
      name: 'Department/Stream',
      url: 'stream',
      isGraph: true,
    },
    {
      id: 4,
      name: 'Qualifications \n& Certifications',
      url: 'qualifications-certifications',
      isGraph: false,
      isSplit: true,
    },
    {
      id: 5,
      name: 'Combination Overrides',
      url: 'combination-overrides',
      isGraph: false,
    },
    {
      id: 6,
      name: 'Market Conditions',
      url: 'market-conditions',
      isGraph: false,
      viewOnly: false,
    },
    {
      id: 7,
      name: 'Compute',
      url: 'compute',
      isGraph: false,
      viewOnly: true,
    },
    {
      id: 8,
      name: 'Review & Publish',
      url: 'review-publish',
      isGraph: false,
    }
  ]
  const isCurrentPublished = version.is_published && version.is_active && userPermissions.modify_current_published
  const updatedConfigList = isCurrentPublished ? configList.map(item => item.id === 8 ? { ...item, name: 'Review & Re-Publish' } : item) : configList;
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const dispatch = useDispatch<AppDispatch>();

  const handleDeleteItem = () => {
    setShowDeleteModal(false);
    dispatch(deleteVersion(version.id)).then((res: any) => {
      if (res.payload.status === '200') {
        dispatch(setVersion(''));
        dispatch(fetchVersion({})).then(() => navigate('/versions'))
      } else if (!STATUS_CODES_IGNORE.includes(res.payload.status)) {
        toast.error(res.payload.message)
      }
    });
  }

  const isDeletePermission = () => {
    return (permissionData.edit && !(version.is_active && version.is_published)) ||
    (userPermissions.view_previously_published && (!version.is_active && version.is_published))
  }

  return (
    <div className="config-container">
      <div className="title">
        <PageTitle pageDetail={PAGE_DETAILS.configurations} />
        {isDeletePermission() && <div className="delete-btn"
          onClick={() => {
            setShowDeleteModal(true);
          }}>Delete</div>}
      </div>
      <div className="config-list">
        {updatedConfigList.map((configuration, index) => {
          return (
            <ConfigCard key={index} configuration={configuration} index={index} />
          );
        })}
      </div>
      {showDeleteModal && <DeleteConfirmationPopup
        show={showDeleteModal}
        title='Delete Version'
        message={`You are trying to delete version ${version?.name}`}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleDeleteItem} />}
    </div>
  );
};

export default ConfigLanding;
