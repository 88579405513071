import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState = {
    unsavedChangesPresent: false,
    pendingVersionToBeUpdated: null,
    pendingLogout: false
}

export const CommunicationSlice = createSlice({
    name: 'communication',
    initialState,
    reducers: {
        updateunsavedChangesPresent: (state: any, action: PayloadAction<any>) => {
            state.unsavedChangesPresent = action.payload;
        },
        updatePendingVersionToBeUpdated: (state: any, action: PayloadAction<any>) => {
            state.pendingVersionToBeUpdated = action.payload;
        },
        updatePendingLogout: (state: any, action: PayloadAction<any>) => {
            state.pendingLogout = action.payload;
        }
    }
})

export const getUnsavedChangesPresent = (state: any) => state.communication.unsavedChangesPresent;

export const getPendingVersionToBeUpdated = (state: any) => state.communication.pendingVersionToBeUpdated;

export const getPendingLogout = (state: any) => state.communication.pendingLogout;

export const { updateunsavedChangesPresent, updatePendingVersionToBeUpdated, updatePendingLogout } = CommunicationSlice.actions;

export default CommunicationSlice.reducer;