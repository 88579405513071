import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PageTitle from "../../components/PageTitle";
import Pagination from "../../components/Pagination";
import StarRating from "../../components/StarRating";
import { SuggestedSalaryObj, SuggestedSalaryProps } from "../../models/suggestedSalary";
import { FILTER_NAME, PAGE_DETAILS, RATINGS, STATUS_CODES_IGNORE } from "../../store/constant";
import { getRefreshStatus, setRefreshStatus } from "../../store/reducers/generalReducer";
import { fetchSelectedVersion, getFilters, getPermissionData, getUserPermissions, getVersion, updateVersionOnPublish } from "../../store/reducers/salarySuggestionReducer";
import { fetchSuggestedSalaryList, getSalaryList, postSuggestedSalaryList, publishVersion } from "../../store/reducers/suggestedSalaryReducer";
import { AppDispatch } from "../../store/store";
import { scrollToTop } from "../../store/utilFuntions";
import EditSuggestedSalaryModal from "./EditSuggestedSalary";
import FilterDropdown from './FilterDropdown';

import './style.scss';

const apiData = {
  [FILTER_NAME.grade]: '',
  [FILTER_NAME.stream]: '',
  [FILTER_NAME.rating]: '',
  [FILTER_NAME.location]: '',
  [FILTER_NAME.qualification]: '',
  [FILTER_NAME.marketCondition]: 'NH',
}

const SuggestedSalaries = (props: SuggestedSalaryProps) => {
  const suggestedSalaryList: SuggestedSalaryObj[] = useSelector(getSalaryList);
  const permissionData = useSelector(getPermissionData);
  const userPermissions = useSelector(getUserPermissions);

  const [editMode] = useState(props.editMode || false)
  const [salaryList, setSalaryList] = useState(suggestedSalaryList);
  const [showModal, setShowModal] = useState(false);
  const [popupData, setPopupData] = useState({});
  const [modalIndex, setModalIndex] = useState(-1);
  const [paginationObj, setPaginationObj] = useState({
    totalCount: 0,
    perPageCount: 250,
    currentPage: 1,
    startCount: 1,
    endCount: 250,
    isPrevActive: false,
    isNextActive: true
  })
  const version = useSelector(getVersion);
  const refreshStatus = useSelector(getRefreshStatus);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const filters: any = useSelector(getFilters);
  const [filterData, setFilterData]: any = useState({});
  const [ratingList, setRatingList]: any = useState(RATINGS);
  const [filterString, setFilterString] = useState('');  
  
  const isCurrentPublished = version.is_published && version.is_active && userPermissions.modify_current_published
  const updatedPageDetails = {
    ...PAGE_DETAILS,
    reviewAndPublish: {
      ...PAGE_DETAILS.reviewAndPublish,
      name: isCurrentPublished ? 'Review & Re-Publish' : 'Review & Publish'
    }
  };

  const setDefaultFilters = useCallback(() => {
    let filtersObj = {
      ...filters,
      market_condition: filters.market_condition?.map((option: any) => {
        return {...option, isChecked: option.id === 'NH'}
      })
    }
    setFilterData(filtersObj);
}, [filters])

  useEffect(() => {
    return () => {
      dispatch(setRefreshStatus('idle'))
    }
  }, [dispatch])

  useEffect(() => {    
    if (version.id && refreshStatus === 'idle') {
      dispatch(fetchSelectedVersion(version.id));
    }
    if (version.id && refreshStatus !== 'finished') {      
      setFilterString('&market_condition=NH');
      fetchSalaryList(1, {}, '&market_condition=NH');
    }
    if (refreshStatus === 'refreshing') {
      //reset filters
      setDefaultFilters();
      setFilterString('&market_condition=NH'); //setting default param in list api
      setRatingList(RATINGS);
      resetFilters();
      dispatch(setRefreshStatus('finished'));
    }
    // eslint-disable-next-line
  }, [version.id, refreshStatus, setDefaultFilters])

  useEffect(() => {
    setSalaryList(suggestedSalaryList);
  }, [suggestedSalaryList])

  useEffect(() => {
    if (filters) setDefaultFilters();

    return () => {
      resetFilters();
    }
  }, [filters, setDefaultFilters])

  function applyFilterCallback(list: any, appliedList: any = [], name: string, key: string = 'name') {
    if (name === 'rating') {
      setRatingList(list);
    } else {
      const data: any = { ...filterData };
      data[name] = list;
      setFilterData(data)
    }
    apiData[name] = appliedList.map((itm: any) => itm[key]).join(',');
    let filtersString = ''
    if (apiData[FILTER_NAME.grade] !== '') {
      filtersString = filtersString + `&grade=${apiData[FILTER_NAME.grade]}`;
    }
    if (apiData[FILTER_NAME.stream] !== '') {
      filtersString = filtersString + `&stream=${apiData[FILTER_NAME.stream]}`;
    }
    if (apiData[FILTER_NAME.rating] !== '') {
      filtersString = filtersString + `&rating=${apiData[FILTER_NAME.rating]}`;
    }
    if (apiData[FILTER_NAME.qualification] !== '') {
      filtersString = filtersString + `&qualification=${apiData[FILTER_NAME.qualification]}`;
    }
    if (apiData[FILTER_NAME.location] !== '') {
      filtersString = filtersString + `&location=${apiData[FILTER_NAME.location]}`;
    }
    if (apiData[FILTER_NAME.marketCondition] !== '') {
      filtersString = filtersString + `&market_condition=${apiData[FILTER_NAME.marketCondition]}`;
    }
    setFilterString(filtersString);

    let pageData = {
      startCount: 1,
      endCount: 250,
    }
    fetchSalaryList(1, pageData, filtersString);
  }

  const openEditPopup = (index: number) => {
    let s = salaryList[index];
    setPopupData({
      minimum: s.overridden_minimum || s.minimum,
      maximum: s.overridden_maximum || s.maximum,
      median: s.overridden_median || s.median,
    })
    setShowModal(true);
  }

  const handleEditItem = (val: any, index: number, isCleared?: boolean) => {
    let data = {
      suggested_salary_id: salaryList[index].id,
      overridden_minimum: isCleared ? 0 : val.minimum,
      overridden_maximum: isCleared ? 0 : val.maximum,
      is_cleared: isCleared || null,
      version: version.id
    }
    dispatch(postSuggestedSalaryList({
      apiUrl: props.postApiUrl,
      data: [data]
    })).then((res: any) => {
      if (!STATUS_CODES_IGNORE.includes(res.payload.status)) {
        fetchSalaryList(paginationObj.currentPage, {}, filterString);
      }
      setShowModal(false);
    })
  }

  const resetFilters = () => {
    Object.keys(apiData).forEach((key: any) => {
      apiData[key] = ''
      if (key === 'market_condition') {
        apiData[key] = 'NH';
      }
    });
  }

  const resetRow = (index: number) => {
    let val = {
      minimum: salaryList[index].minimum,
      maximum: salaryList[index].maximum
    }
    handleEditItem(val, index, true);
  }

  const handleClose = () => {
    setShowModal(false);
  }

  const fetchSalaryList = (page: number | null = null, pageData: any = {}, filters: string = '') => {
    let pageNumber = page || 1;
    const isReviewPage = props.pageId === 'reviewAndPublish'  
    dispatch(fetchSuggestedSalaryList({versionId: version.id, apiUrl: props.getApiUrl, page, filters, isReviewPage})).then((res: any) => {
      if (res.payload.status !== "200") {
        return;
      }
      let pageObj = {
        ...pageData,
        currentPage: pageNumber,
        isPrevActive: res.payload.previous ? true : false,
        isNextActive: res.payload.next ? true : false,
        totalCount: res.payload.count || 0,
        startCount: (pageNumber - 1) * 250 + 1,
        endCount: (pageNumber * 250) > res.payload.count ? res.payload.count : (pageNumber * 250)
      }
      setPaginationObj({
        ...paginationObj,
        ...pageObj
      })
      scrollToTop(0);
    })
  }

  const onPrevClick = (pageData: any) => {
    fetchSalaryList(paginationObj.currentPage - 1, pageData, filterString);
  }

  const onNextClick = (pageData: any) => {
    fetchSalaryList(paginationObj.currentPage + 1, pageData, filterString);
  }

  const reviewAndPublish = () => {
    let isCurrentPublished = version.is_published && version.is_active && userPermissions.modify_current_published;
    const data = {
      versionId: version.id,
      isCurrentPublished: isCurrentPublished
    }
    if (version.in_progress) return;
    dispatch(publishVersion(data)).then((res: any) => {
      if (res.payload.status === "200") {
        toast.success(res.payload.message);
        dispatch(updateVersionOnPublish(version));
        navigate('/suggest-salary');
      }
    })
  }

  return(
    <div className="suggested-salaries-list">
      <PageTitle paths={props.pageId === 'reviewAndPublish' ? [updatedPageDetails.configurations, updatedPageDetails.reviewAndPublish] : undefined}
        pageDetail={updatedPageDetails[props.pageId]} />

      <div className="mt-n18">
        <Pagination totalCount={paginationObj.totalCount} perPageCount={paginationObj.perPageCount}
          startCount={paginationObj.startCount} endCount={paginationObj.endCount}
          isPrevActive={paginationObj.isPrevActive} isNextActive={paginationObj.isNextActive}
          onPrevClick={(pageData: any) => onPrevClick(pageData)} onNextClick={(pageData: any) => onNextClick(pageData)} />
      </div>

      <div className="table-div">
        <table className='salary-table'>
          <thead>
            <tr>
              <th>
                <span className='header-text'>Grade (Experience)</span>
              </th>
              <th>
                <span className='header-text'>Rating</span>
              </th>
              <th>
                <span className='header-text'>Location</span>
              </th>
              <th>
                <span className='header-text'>Department/Stream</span>
              </th>
              <th>
                <span className='header-text'>Market Condition</span>
              </th>
              <th colSpan={3}>
                <div className='header-text fs-14'>Recommendation</div>
              </th>
            </tr>
            <tr>
              <th>
                <FilterDropdown key1="name" key2="display_name" filterMenuOptions={filterData.grades} filterName={FILTER_NAME.grade} onApplyFilter={(list: any, appliedList: any) => applyFilterCallback(list, appliedList, FILTER_NAME.grade)} />
              </th>
              <th className='header-text'>
                <FilterDropdown filterMenuOptions={ratingList} filterName={FILTER_NAME.rating} onApplyFilter={(list: any, appliedList: any) => applyFilterCallback(list, appliedList, FILTER_NAME.rating)} />
              </th>
              <th className='header-text'>
                <FilterDropdown filterMenuOptions={filterData.locations} onApplyFilter={(list: any, appliedList: any) => applyFilterCallback(list, appliedList, FILTER_NAME.location)} />
              </th>
              <th className='header-text'>
                <FilterDropdown key1="display_name" filterMenuOptions={filterData.streams} onApplyFilter={(list: any, appliedList: any) => applyFilterCallback(list, appliedList, FILTER_NAME.stream)} isStreamFilter={true} />
              </th>
              <th className='header-text'>
                <FilterDropdown filterMenuOptions={filterData.market_condition} filterType='radio' filterName={FILTER_NAME.marketCondition} onApplyFilter={(list: any, appliedList: any) => applyFilterCallback(list, appliedList, FILTER_NAME.marketCondition, 'id')} />
              </th>
              <th className='header-text'>
                <div className="subheader-text min">Min</div>
              </th>
              <th className='subheader-text'>
                Med
                </th>
              <th className='subheader-text'
              >Max
              </th>
            </tr>
          </thead>
          <tbody>
            {salaryList && salaryList.map((row: SuggestedSalaryObj, index: number) => {
              return (
                <tr key={row.id}>
                  <td key='grade'>{row['grade'] + ' (' + row['experience'] + ')'}</td>
                  <td key='rating'><StarRating star_rating={row['rating']} height="10" width="10"/></td>
                  <td key='location'>{row['location']}</td>
                  <td key='stream'>{row['stream']}</td>
                  <td key='marketConditions'>{row['market_condition']}</td>
                  <td key='minimum'> {row['overridden_minimum']
                    ? <span>{editMode && <span><span className="override">{row['minimum']}</span> <br/></span>} {row['overridden_minimum']}</span>
                    : <span>{row['minimum']}</span>} </td>
                  <td key='median'> {row['overridden_median']
                    ? <span>{editMode && <span><span className="override">{row['median']}</span> <br/></span>} {row['overridden_median']}</span>
                    : <span>{row['median']}</span>} </td>
                  <td key='maximum'> {row['overridden_maximum']
                    ? <span>{editMode && <span><span className="override">{row['maximum']}</span> <br/></span>} {row['overridden_maximum']}</span>
                    : <span>{row['maximum']}</span>} </td>

                  {permissionData.edit && editMode && 
                    <td>
                      {(row['overridden_minimum'] || row['overridden_maximum']) ? <button className="btn clear-btn" onClick={() => resetRow(index)} disabled={version.is_active && version.is_published && version.is_republished}>Reset</button> : ''}
                      <img className={`edit-icon${version.is_active && version.is_published && version.is_republished ? ' disabled-edit' : ''}`}  src='/images/copy.svg' alt='edit' onClick={version.is_active && version.is_published && version.is_republished ? undefined : () => {setModalIndex(index); openEditPopup(index)}}/>
                    </td>
                  }
                </tr>
              );
            })}
          </tbody>
        </table>

        {salaryList?.length === 0 ? <div className='d-flex justify-content-center fw-bold mt-3'>No Data</div> : ''}

        {showModal && editMode && <EditSuggestedSalaryModal
          show={showModal}
          title='Edit Suggested Salary'
          data={popupData}
          onEditItem={(val: any) => handleEditItem(val, modalIndex)}
          onHide={handleClose}
          resetData={() => openEditPopup(modalIndex)} />}
      </div>

      <Pagination totalCount={paginationObj.totalCount} perPageCount={paginationObj.perPageCount}
        startCount={paginationObj.startCount} endCount={paginationObj.endCount}
        isPrevActive={paginationObj.isPrevActive} isNextActive={paginationObj.isNextActive}
        onPrevClick={(pageData: any) => onPrevClick(pageData)} onNextClick={(pageData: any) => onNextClick(pageData)} />

      <div className="mb-26"></div>

      {permissionData.edit && editMode && userPermissions.publish_draft && salaryList.length !== 0 && !(version.is_published && !version.is_active) &&
        <div className="actions">
          <button className="btn add-btn" onClick={reviewAndPublish} disabled={version.in_progress}>{isCurrentPublished ?'Review & Re-Publish' : 'Review & Publish'}</button>
        </div>
      }

    </div>
  )
}

export default SuggestedSalaries;