import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify";
import { api } from "../../api";
import { SuggestedSalaryObj } from "../../models/suggestedSalary";
import { handleApiError } from '../utilFuntions';

const initialState: State = {
  salaryList: [],
}

export const fetchSuggestedSalaryList = createAsyncThunk('/salary/get', async (data: any) => {  
  const response = await api.get(`${data.apiUrl}?version_id=${data.versionId}&is_review_page=${data.isReviewPage}${data.page ? '&page=' + data.page : ''}`+ data.filters);
  return response.data;
})

export const postSuggestedSalaryList = createAsyncThunk('/salary/post', async (data: any) => {
  const response = await api.post(data.apiUrl, data.data);
  return response.data;
})

export const computeSalaries = createAsyncThunk('salary/compute', async (versionId: number) => {
  const response = await api.get(`publish-version/${versionId}/compute`);
  return response.data;
})

export const publishVersion = createAsyncThunk('salary/publish', async (data: any) => {
  const response = await api.get(`publish-version/${data.versionId}/publish?is_published=${data.isCurrentPublished}`);
  return response.data;
})

export const suggestedSalarySlice = createSlice({
  name: 'suggestedSalaries',
  initialState,
  reducers: {
    setSalaryList: (state: any, action) => {
      state.salaryList = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSuggestedSalaryList.fulfilled, (state, action) => {
      if (action.payload.status === "200") {
        state.salaryList = action.payload.results;
      } else handleApiError(action.payload.status) 
    })
    builder.addCase(postSuggestedSalaryList.fulfilled, (state, action) => {
      if (action.payload.status === "200") {
        toast.success('Salary amount has been updated successfully');
      } else handleApiError(action.payload.status) 
    })
    builder.addCase(computeSalaries.fulfilled, (state, action) => {
      if (action.payload.status !== "200") {
        handleApiError(action.payload.status) 
      }
    })
    builder.addCase(publishVersion.fulfilled, (state, action) => {
      if (action.payload.status !== "200") {
        handleApiError(action.payload.status) 
      }
    })
  }
});

export const getSalaryList = (state: any) => state.suggestedSalaries.salaryList;

export const { setSalaryList } = suggestedSalarySlice.actions;

export default suggestedSalarySlice.reducer;

interface State {
  salaryList: SuggestedSalaryObj[]
}