import { Modal, Button } from 'react-bootstrap';
import { useState } from 'react';
import './style.scss';

import { StreamOffsetFieldType } from '../../../../models/stream';
import { twoDigitDecimalNumber } from '../../../../store/utilFuntions';

const EditStreamModal = (props: any) => {
  const initialFormErrors = {
    offset: ''
  }
  const [popupData, setPopupData] = useState({ ...props.data });
  const [formErrors, setFormErrors] = useState(initialFormErrors);
  const [updateDisabled, setUpdateDisabled] = useState(true);

  const updateStreamOffset = () => {
    popupData.submit = true;
    let errorObj = { ...formErrors }
    checkForFieldErrors(errorObj);
    setFormErrors(errorObj);
    if (getErrorCount(errorObj) === 0) {
      popupData.submit = false;
      props.onEditItem(popupData);
    }
  }

  const checkForFieldErrors = (errorObj: any, value?: string) => {
    if (!popupData.submit) return
    let val;
    let chars = ['.', '-', '+'];
    if (value !== undefined) {
      val = parseFloat(value);
    } else {
      value = popupData.offset;
      val = parseFloat(value!);
    }

    if (!val && val !== 0 && !chars.includes(value || '')) {
      errorObj.offset = 'Required';
      setUpdateDisabled(true);
    } else if (!(val >= -99.99 && val <= 99.99) || val === 0 || chars.includes(value || '')) {
      errorObj.offset = 'Invalid';
      setUpdateDisabled(true);
    } else {
      errorObj.offset = '';
    }

    if (getErrorCount(errorObj) === 0) {
      setUpdateDisabled(false);
    }
  }

  const getErrorCount = (errorObj: any) => {
    let count = 0;
    Object.keys(errorObj).forEach((field: string) => {
      if (errorObj[field as StreamOffsetFieldType]) count += 1;
    })
    return count;
  }

  const changeInput = (e: any) => {
    let value: string = e.target.value?.trim();
    if (value[0] === '.') value = '0' + value;

    if ((twoDigitDecimalNumber.test(value) && value !== '0.00') || value.length === 0 || value === '-' || value === '+') {
      if (updateDisabled && parseFloat(value) !== 0) {
        setUpdateDisabled(false);
      }
      setPopupData({ ...popupData, offset: e.target.value?.trim() });
      let errorObj = { ...formErrors };
      checkForFieldErrors(errorObj, e.target.value?.trim());
      setFormErrors(errorObj);
    }
  }

  const resetOffset = () => {
    setPopupData({ ...props.data });
    setFormErrors(initialFormErrors);
  }

  const hideModal = () => {
    popupData.submit = false;
    props.onHide();
  }

  return (
    <div>
      <Modal show={props.show} onHide={hideModal} centered backdrop='static'>
        <Modal.Header>
          <Modal.Title>
            {props.title}
          </Modal.Title>
          <img className='close-btn' src='/images/modal-close.svg' onClick={hideModal} alt='modal-close' />
        </Modal.Header>

        <Modal.Body>
          <div className='first-row'>
            <div className='col-name'>
              Department/Stream
            </div>
            <div>
              <div><input disabled value={props?.data.streamName} /></div>
            </div>
          </div>

          <div className='second-row position-relative'>
            <div className='col-name'>
              Offset
            </div>
            <div className='input-fields'>
              <input
                className={formErrors.offset ? 'rating-input val-error' : 'rating-input'}
                value={popupData.offset}
                onChange={(e: any) => changeInput(e)} placeholder='00.00' />
              <span className='input-error'>
                {formErrors.offset === 'Invalid' ? '' : formErrors.offset}
              </span>
            </div>
          </div>

        </Modal.Body>

        <Modal.Footer className='actions'>
          <Button className='clear-btn' onClick={resetOffset} >Reset</Button>
          <Button className='add-btn' onClick={updateStreamOffset} disabled={updateDisabled}>Update</Button>
        </Modal.Footer>

      </Modal>
    </div>
  )


}

export default EditStreamModal;