import SuggestedSalaries from "../../suggested-salaries"

const ConfigReviewAndPublish = () => {
  return(
    <SuggestedSalaries
      editMode={true}
      getApiUrl={'suggested-salary/list-page'}
      postApiUrl={'suggested-salary/publish-page'}
      pageId='reviewAndPublish'
    />
  )
}

export default ConfigReviewAndPublish;