import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify";

import { api } from "../../api";
import { CombinationOffset } from "../../models/combination";
import { handleApiError } from '../utilFuntions';

const initialState: State = {
  combinationOffset: [],
}

export const fetchCombinations = createAsyncThunk('/combinations', async (versionId: number) => {
  const response = await api.get(`configurations/combinations?version_id=${versionId}`);
  return response.data;
})

export const updateCombination = createAsyncThunk('/combinations-update', async (requestData: CombinationOffset[]) => {
  const response = await api.post(`configurations/combinations`, requestData);
  return response.data;
})

export const combinationSlice = createSlice({
  name: 'combination',
  initialState,
  reducers: {},
  extraReducers:  (builder) => {
    builder.addCase(fetchCombinations.fulfilled, (state, action) => {
      if (action.payload.status === "200") {
        state.combinationOffset = action.payload.data;
      } else handleApiError(action.payload.status) 
    })
    builder.addCase(updateCombination.fulfilled, (_state, action) => {
      if (action.payload.status === "200") {
        toast.success('Combination offsets have been saved successfully');
      }
    })
  }
});

export const getCombinations = (state: any) => state.combination.combinationOffset;

export default combinationSlice.reducer;

interface State {
  combinationOffset: CombinationOffset[],
}