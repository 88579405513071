import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit"
import { toast } from "react-toastify";
import { api } from "../../api";
import { experienceObj } from "../../models/experience";
import { handleApiError } from '../utilFuntions';

const initialState: State = {
  experienceOffset: [],
}

let resetStateData: any = initialState.experienceOffset;

export const fetchExperience = createAsyncThunk('/get-experience', async (versionId: number) => {
  const response = await api.get(`configurations/base-values?version_id=${versionId}`);
  return response.data;
});

export const editExperience = createAsyncThunk('/edit-experience', async (requestData: any) => {
  const response = await api.post(`configurations/base-values`, requestData);
  return response.data;
});

const getEditItemRatings = (responseData: any) => {
  let ratingsArray: any = [];

  responseData.forEach((reponseItem: any) => {
    ratingsArray.push({
      id: reponseItem.base_value_offset_id,
      rating: reponseItem.rating,
      minimum: reponseItem.minimum,
      maximum: reponseItem.maximum,
      median: reponseItem.median,
    })
  });

  return ratingsArray;
}

const updateState = (state: any, responseData: any) => {
  let stateData: any = current(state.experienceOffset);
  let editedItem: any = {};
  let experienceItemIndex = -1;

  stateData.forEach((state_item: any, index: number) => {
    if (state_item.grade.id === responseData[0].grade_id) {
      experienceItemIndex = index;

      editedItem = {
        grade: state_item.grade,
        ratings: getEditItemRatings(responseData)
      }
    }
  });

  let newStateData: any = [];
  stateData.forEach((state_item: any, index: number) => {
    if (index === experienceItemIndex) {
      newStateData.push(editedItem);
    } else {
      newStateData.push(state_item);
    }
  })

  return newStateData;
}

export const experienceSlice = createSlice({
  name: 'experience',
  initialState,
  reducers: {
    editExperienceItem: (state: State, action: any) => {
      state.experienceOffset = updateState(state, action.payload);
      toast.success('Salary amount has been updated successfully');
    },
    resetData: (state: State) => {
      state.experienceOffset = resetStateData;
    }
  },
  extraReducers:  (builder) => {
    builder.addCase(fetchExperience.fulfilled, (state, action) => {
      if (action.payload.status === "200") {
        state.experienceOffset = action.payload.data;
        resetStateData = action.payload.data;
      } else handleApiError(action.payload.status) 
    });

    builder.addCase(editExperience.fulfilled, (state: any, action: any) => {
      if (action.payload.status === "200") {
        toast.success('Offsets for experience have been saved successfully');
        resetStateData = current(state.experienceOffset);
      } else handleApiError(action.payload.status) 
    });
  }
});

export const getExperience = (state: any) => state.experience.experienceOffset;

export const { editExperienceItem, resetData } = experienceSlice.actions;

export default experienceSlice.reducer;

interface State {
  experienceOffset: experienceObj[] | []
}