import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";

import Filter from "../../components/Filter";
import SuggestSalaryCard from "../../components/suggest-salary-card/suggest-salary-card";
import './styles.scss';
import { fetchSalaries, fetchSelectedVersion, getFilters, getSalaries, getVersion } from "../../store/reducers/salarySuggestionReducer";
import { AppDispatch } from "../../store/store";
import { PAGE_DETAILS, RATINGS } from '../../store/constant';
import RatingFilter from '../../components/Ratingfilter';
import PageTitle from '../../components/PageTitle';
import { getRefreshStatus, setRefreshStatus } from "../../store/reducers/generalReducer";

let params = {
  version_id: null,
  grade: null,
  rating: 3,
  location: null,
  stream: null,
  market_condition: 'NH',
}

const filterName = {
  grade: 'grade',
  rating: 'rating',
  location: 'location',
  stream: 'stream',
  market_condition: 'market_condition',
}

const SuggestSalary = () => {
  const filters = useSelector(getFilters);
  const salary = useSelector(getSalaries);
  const versionId = useSelector(getVersion)?.id;
  const refreshStatus = useSelector(getRefreshStatus);
  const dispatch = useDispatch<AppDispatch>();
  const [salSplit, setSalSplit] = useState({
    minimun: 0,
    maximun: 0,
    median: 0,
    overridden_maximum: 0,
    overridden_median: 0,
    overridden_minimum: 0
  })
  const [locations, setLocations] = useState(filters.locations || []);
  const [streams, setStreams] = useState(filters.streams || []);
  const [market_condition, setMarketCondition] = useState(filters.market_condition || []);

  const onFilterChange = (type: string, filterData: any) => {
    switch (type) {
      case filterName.grade:
        params.grade = filterData.name;
        break;
      case filterName.rating:
        params.rating = filterData.name;
        break;
      case filterName.location:
        if (filterData.id === 0) {
          params.location = null;
        } else {
          params.location = filterData.name;
        }
        break;
      case filterName.stream:
        if (filterData.id === 0) {
          params.stream = null;
        } else {
          params.stream = filterData.name;
        }
        break;
      case filterName.market_condition:
        params.market_condition = filterData.id;
        break;
    }
    dispatch(fetchSalaries(params));
  }

  useEffect(() => {
    return () => {
      dispatch(setRefreshStatus('idle'))
    }
  }, [dispatch])

  useEffect(() => {
    params = {
      version_id: versionId,
      grade: filters.grades ? filters.grades[3]?.name : '',
      rating: RATINGS[1]?.name,
      location: null,
      stream: null,
      market_condition: 'NH',
    }
    if (versionId && refreshStatus === 'idle') {
      dispatch(fetchSelectedVersion(versionId));
    }
    if (refreshStatus === 'refreshing') {
      dispatch(fetchSalaries(params));
      dispatch(setRefreshStatus('finished'));
    } else if (filters.grades && versionId && refreshStatus !== 'finished') {
      dispatch(fetchSalaries(params));
    }
  }, [filters?.grades, dispatch, versionId, refreshStatus])

  useEffect(() => {
    setSalSplit({
      minimun: salary.minimum,
      maximun: salary.maximum,
      median: salary.median,
      overridden_maximum: salary.overridden_maximum,
      overridden_median: salary.overridden_median,
      overridden_minimum: salary.overridden_minimum
    })
  }, [salary])

  useEffect(() => {
    setLocations(filters.locations ? [{ name: 'Select Location', id: 0 }, ...filters.locations] : [])
  }, [filters.locations])

  useEffect(() => {
    setStreams(filters.streams ? [{ name: 'Select Department/Stream', id: 0, display_name: 'Select Department/Stream' }, ...filters.streams] : [])
  }, [filters.streams])

  useEffect(() => {
    setMarketCondition(filters.market_condition ? [...filters.market_condition] : [])
  }, [filters.market_condition])

  return (
    <div className="suggest-salary">
      <PageTitle pageDetail={PAGE_DETAILS.suggestSalary} />

      <div className="row filters-div">
        <Filter
          name="Grade (Experience)"
          reset={refreshStatus === 'refreshing'}
          options={filters.grades ? filters.grades : []}
          key1="name"
          key2="display_name"
          onFilterChange={(filterData: any) => onFilterChange(filterName.grade, filterData)}
          defaultId={4}
          showDefault={true} />
        <RatingFilter
          name="Rating"
          reset={refreshStatus === 'refreshing'}
          options={RATINGS}
          onFilterChange={(filterData: any) => onFilterChange(filterName.rating, filterData)} />
        <Filter
          name="Location"
          reset={refreshStatus === 'refreshing'}
          options={locations}
          onFilterChange={(filterData: any) => onFilterChange(filterName.location, filterData)} />
        <Filter
          name="Department/Stream"
          reset={refreshStatus === 'refreshing'}
          key1 = "display_name"
          options={streams}
          onFilterChange={(filterData: any) => onFilterChange(filterName.stream, filterData)} />
        <Filter
          name="Market Condition"
          reset={refreshStatus === 'refreshing'}
          options={market_condition}
          defaultId={'NH'}
          onFilterChange={(filterData: any) => onFilterChange(filterName.market_condition, filterData)} />
      </div>

      <div className="row">
        <SuggestSalaryCard name='Minimum' color={'#256f9c'} bgColor={'#f5f5fd'} value={salSplit.overridden_minimum ? salSplit.overridden_minimum : salSplit.minimun} />
        <SuggestSalaryCard name='Median' color={'#00aaf3'} bgColor={'#eeeeee'} value={salSplit.overridden_median ? salSplit.overridden_median : salSplit.median} />
        <SuggestSalaryCard name='Maximum' color={'#ff5657'} bgColor={'#ffe3e3'} value={salSplit.overridden_maximum ? salSplit.overridden_maximum : salSplit.maximun} />
      </div>
    </div>
  )
}

export default SuggestSalary;