import { createAsyncThunk, createSlice, current, PayloadAction } from "@reduxjs/toolkit"
import { toast } from "react-toastify";
import { api } from "../../api";
import { Filters, salaryObj, SalarySuggestion } from "../../models/salary";
import { getPermissionByVersion, handleApiError } from '../utilFuntions';

const initialState: SalarySuggestion = {
  version: {},
  versions: [],
  filters: {
    grades: null,
    locations: null,
    streams: null,
    qualifications: null,
    market_condition: null,
  },
  country: '',
  currency: '',
  suggestedSalary: {
    minimum: 0,
    median: 0,
    maximum: 0,
    overridden_maximum: 0,
    overridden_median: 0,
    overridden_minimum: 0
  },
  userPermissions: {
    view_current_published: false,
    view_previously_published: false,
    view_draft: false,
    modify_previously_published: false,
    modify_draft: false,
    add_draft: false,
    view_configuration: false,
    publish_draft: false,
    modify_current_published: false
  },
  permissionData: {
    view: false,
    edit: false,
  },
  is_permission_data: false,
}


export const fetchVersion = createAsyncThunk('/get-version', async (params: any) => {
  const response = await api.get(`published-version/`, {params: params});
  return response.data;
});

export const fetchSelectedVersion = createAsyncThunk('/get-selected-version', async (versionId: number) => {
  const response = await api.get(`version/${versionId}`);
  return response.data;
},{
  condition: (versionId: number, { getState }) => {
    const state: any = getState();
    const salary = state.salary;
    if (salary.version?.fetchStatus === 'pending') {
      return false;
    }
    return true;
  }
});

export const cloneVersion = createAsyncThunk('/clone-version', async (requestData: any) => {
  const response = await api.post(`publish-version/${requestData.version_id}/clone`, requestData.data);
  return response.data;
});

export const fetchFilters = createAsyncThunk('/filters', async () => {
  const response = await api.get(`filters/`);
  return response.data;
});

export const fetchSalaries = createAsyncThunk('/suggested-salary', async (params: any) => {
  const response = await api.get(`suggested-salary/landing-page`, {params: params});
  return response.data;
});

export const fetchUserPermissions = createAsyncThunk('/user-permissions/', async (userId: string) => {
  const response = await api.get(`user/${userId}/permissions`);
  return response.data;
});

export const deleteVersion = createAsyncThunk('/delete-version/', async (versionId: string) => {
  const response = await api.delete(`publish-version/${versionId}/delete`);
  return response.data;
});

export const salarySuggestionSlice = createSlice({
  name: 'salary',
  initialState,
  reducers: {
    setVersions: (state: SalarySuggestion, action: VersionPayload) => {
      state.versions = action.payload;
    },
    setVersion: (state: SalarySuggestion, action: VersionPayload) => {
      sessionStorage.setItem('version', JSON.stringify(action.payload));
      state.version = action.payload;
    },
    updateVersionOnPublish: (state: SalarySuggestion, action: any) => {
      const version: any = {...action.payload, ...{is_active: true, is_published: true, is_republished: true}};
      sessionStorage.setItem('version', JSON.stringify(version));
      state.version = version;
    },
    setFilters: (state: SalarySuggestion, action: FilterPayload) => {
      state.filters = action.payload;
    },
    setSalaries: (state: SalarySuggestion, action: SalaryPayload) => {
      state.suggestedSalary = action.payload;
    },
    setPermissionData: (state: SalarySuggestion, _action: PayloadAction<any>) => {
      const permissionData = getPermissionByVersion(state.version, state.userPermissions)
      state.permissionData = permissionData;
    },
    setIsPermissionData: (state: SalarySuggestion, action: PayloadAction<boolean>) => {
      state.is_permission_data = action.payload;
    },
  },
  extraReducers:  (builder) => {
    builder.addCase(fetchVersion.fulfilled, (state, action) => {
      if (action.payload.status === "200") {
        state.versions = action.payload.results;
        const versionData = sessionStorage.getItem('version');

        let selectedVersion = JSON.parse(versionData && versionData !== 'undefined' ? versionData : '{}');

        if (selectedVersion?.id) {
          state.version = selectedVersion;
        } else {
          const published = action.payload.results.filter((version: any) => {
            return version.is_published && version.is_active;
          });
          sessionStorage.setItem('version', JSON.stringify(published[0]));
          state.version = published[0] || {};
        }
      } else if (action.payload.status !== "400") {
        handleApiError(action.payload.status) 
      }
    })

    builder.addCase(fetchSelectedVersion.pending, (state) => {
      state.version.fetchStatus = 'pending';
    })

    builder.addCase(fetchSelectedVersion.fulfilled, (state, action) => {
      if (action.payload.status === "200") {
        sessionStorage.setItem('version', JSON.stringify(action.payload.data));
        state.version = {...action.payload.data, fetchStatus: 'fulfilled'};
      } else handleApiError(action.payload.status) 
    })

    builder.addCase(cloneVersion.fulfilled, (state, action) => {
      if (action.payload.status === "200") {
        toast.success('Clone Created');
      } else handleApiError(action.payload.status) 
    })

    builder.addCase(fetchFilters.fulfilled, (state, action) => {
      if (action.payload.status === "200") {
        state.filters = action.payload.data;
      } else handleApiError(action.payload.status) 
    })

    builder.addCase(fetchSalaries.fulfilled, (state, action) => {
      if (action.payload.status === "200") {
        state.suggestedSalary = action.payload.data;
      } else if (action.payload.status === "403") {
        let versionsList = current(state.versions);
        setPublishedVersionAsSelected(versionsList, state);
      } else handleApiError(action.payload.status) 
    })

    builder.addCase(fetchUserPermissions.fulfilled, (state, action) => {
      if (action.payload.status === "200") {
        let permissions = action.payload.data
        state.userPermissions = permissions;
      } else handleApiError(action.payload.status) 
    })

    builder.addCase(deleteVersion.fulfilled, (_state, action) => {
      if (action.payload.status === "200") {
        toast.success('Version has been deleted succesfully');
      }
    })
  }
})

const setPublishedVersionAsSelected = (versionsList: any, state: any) => {
  let currentPublished = versionsList.filter((version: any) => version.is_published && version.is_active)[0] || {};
  sessionStorage.setItem('version', JSON.stringify(currentPublished));
  state.version = currentPublished;
}

export const getVersion = (state: State) => state.salary.version;
export const getVersions = (state: State) => state.salary.versions;
export const getFilters = (state: State) => state.salary.filters;
export const getSalaries = (state: State) => state.salary.suggestedSalary;
export const getUserPermissions = (state: State) => state.salary.userPermissions;
export const getPermissionData = (state: State) => state.salary.permissionData;
export const getIsPermissionData = (state: State) => state.salary.is_permission_data;

export const { setVersion, setVersions, setFilters, setSalaries, setPermissionData, updateVersionOnPublish, setIsPermissionData } = salarySuggestionSlice.actions;

export default salarySuggestionSlice.reducer;

interface VersionPayload {
  payload: string
}

interface FilterPayload {
  payload: Filters
}

interface SalaryPayload {
  payload: salaryObj
}

interface State {
  salary: SalarySuggestion
}