import { useState, useEffect } from 'react';
import { AxiosInstance } from 'axios';
import { useErrorHandler } from '../api';

const useAxiosLoader = (api: AxiosInstance) => {
  const [counter, setCounter] = useState(0);
  const errorHandler = useErrorHandler({});
  
  useEffect(() => {
    const inc = (mod: number) => setCounter(c => c + mod);
    
    const handleRequest = (config: any) => {
      inc(1);
      config.headers = {
        'Accept': 'application/json',
        'token': JSON.parse(localStorage.getItem('token') || '""')
      };
      config.withCredentials = true;
      return config;
    };
    const handleResponse = (response: any) => {
      inc(-1);
      errorHandler(response);
      return response;
    };
    const handleError = (error: any) => {
      inc(-1);
      errorHandler(error);
      return Promise.reject(error);
    };
  
    const reqInterceptor = api.interceptors.request.use(handleRequest, handleError);
    const resInterceptor = api.interceptors.response.use(handleResponse, handleError);
    return () => {
      api.interceptors.request.eject(reqInterceptor);
      api.interceptors.response.eject(resInterceptor);
    };
    // eslint-disable-next-line
  }, [api.interceptors.request, api.interceptors.response]);
  
  return counter > 0;
};

export default useAxiosLoader;