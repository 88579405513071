import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { AppDispatch } from '../../../store/store';
import { NavDropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { getVersion, getFilters, getPermissionData } from '../../../store/reducers/salarySuggestionReducer';
import {
    fetchQualifications, fetchCertifications, getQualifications, getCertifications,
    addQualification, addCertification, updateQualifications, updateCertifications, resetData, deleteQualification, deleteCertification
} from '../../../store/reducers/configQualificationsCertificationsReducer';
import { qualificationsObj, certificationsObj } from '../../../models/qualificationsCertifications';

import PageTitle from '../../../components/PageTitle';
import { PAGE_DETAILS, GENERAL, STATUS_CODES_IGNORE } from '../../../store/constant';
import ActionButtons from '../../../components/ActionButtons';

import { blockInvalidCharQualification, scrollToTop, twoDigitDecimalNumber } from '../../../store/utilFuntions';
import { usePrompt } from '../../../components/ConfirmNavigationPrompt';

import EditQualificationsCertificationsModal from './EditQualificationsCertificationsModal';
import useUnsavedChangesStateUpdates from '../../../hooks/unsavedChangesStateUpdates';
import ConfirmationPopup from '../../../components/ConfirmationPopup';
import { toast } from 'react-toastify';

const ConfigQualificationsCertifications = () => {
    let isNavigateToNext = false;
    let hoveredItem: any = {
        qualification: {},
        certification: {}
    }
    const initialQualificationValue: qualificationsObj = {
        qualification: null,
        offset: ''
    };

    const initialCertificationValue: certificationsObj = {
        certification: null,
        offset: ''
    };

    const initialFormErrors: any = {
        qualification: false,
        certification: false
    }

    const initialDuplicateError: any = {
        qualification: false,
        certification: false
    }

    const qualificationsList = useSelector(getQualifications);
    const certificationsList = useSelector(getCertifications);
    const permissionData = useSelector(getPermissionData);
    const dropdownFilters: any = useSelector(getFilters);
    const version = useSelector(getVersion);
    let [qualificationOffsetForm, setQualificationOffsetForm] = useState(initialQualificationValue);
    let [certificationOffsetForm, setCertificationOffsetForm] = useState(initialCertificationValue);
    let [formOffsetErrors, setFormOffsetError] = useState(initialFormErrors);
    let [popupFormType, setPopupFormType] = useState('');
    const [duplicateErrors, setDuplicateErrors] = useState(initialDuplicateError);
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const [submitQualificationsData, setSubmitQualificationsData] = useState({});
    const [submitCertificationsData, setSubmitCertificationsData] = useState({});
    const [modalIndex, setModalIndex] = useState(-1);
    const [deletedOffsets,setDeletedOffsets] = useState<any>({qualification: [], certification: []});

    const [popupData, setPopupData] = useState<any>({});
    const [show, setShow] = useState('');

    const handleClose = () => setShow('');

    const handleShow = (action: string) => setShow(action);

    usePrompt(GENERAL.navigation_warning,
        Object.keys(submitQualificationsData).length !== 0 || Object.keys(submitCertificationsData).length !== 0 ||
        deletedOffsets.qualification.length || deletedOffsets.certification.length);

    useUnsavedChangesStateUpdates(Object.keys(submitQualificationsData).length !== 0 || Object.keys(submitCertificationsData).length !== 0 ||
        deletedOffsets.qualification.length || deletedOffsets.certification.length);

    useEffect(() => {
        scrollToTop(20);
    }, [])

    useEffect(() => {
        if (version.id) {
            dispatch(fetchQualifications(version.id));
            dispatch(fetchCertifications(version.id));
        }

        return () => {
            setSubmitQualificationsData({});
            setSubmitCertificationsData({});
        }
    }, [dispatch, version.id]);

    const openPopup = (action: string, index: number, formType: string) => {
        setPopupFormType(formType);
        setModalIndex(index);
        let item: any = formType === 'qualification' ? qualificationsList[index] : certificationsList[index];
        setPopupData(item);
        handleShow(action);
    }

    const optionPresentInList = (option: any, form_type: string) => {
        let list: any = form_type === 'qualification' ? qualificationsList : certificationsList;
        let presentOption = list.find((item: any) => item[form_type]?.id === option?.id);

        return presentOption ? true : false;
    }

    const getSelectedQualification = () => {
        let selectedQualificationName = 'Select Qualification';
        dropdownFilters?.qualifications?.forEach((qualification: any) => {
            if (qualification.id === qualificationOffsetForm.qualification?.id) {
                selectedQualificationName = qualification.name;
            }
        })
        return selectedQualificationName;
    }

    const getSelectedCertification = () => {
        let selectedCertificationName = 'Select Certification';
        dropdownFilters?.certifications?.forEach((certification: any) => {
            if (certification.id === certificationOffsetForm.certification?.id) {
                selectedCertificationName = certification.name;
            }
        })
        return selectedCertificationName;
    }

    const changeInput = (form_type: string, inputField: string, event: any) => {
        let value = event.target.value?.trim();
        if (value[0] === '.') value = '0' + value;

        if ((twoDigitDecimalNumber.test(value) && value !== '0.00') || value.length === 0) {
            setFormOffsetError({ ...formOffsetErrors, [form_type]: false });
            if (form_type === 'qualification') {
                setQualificationOffsetForm({ ...qualificationOffsetForm, [inputField]: event.target.value?.trim() });
            } else {
                setCertificationOffsetForm({ ...certificationOffsetForm, [inputField]: event.target.value?.trim() });
            }
        }
    }

    const clearItem = (form_type: string) => {
        setFormOffsetError({ ...formOffsetErrors, [form_type]: false });
        setDuplicateErrors({...duplicateErrors, [form_type]: false});

        if (form_type === 'qualification') {
            setQualificationOffsetForm(initialQualificationValue);
        } else {
            setCertificationOffsetForm(initialCertificationValue);
        }
    }

    const addItem = (form_type: string) => {
        let addItemData: any = form_type === 'qualification' ? qualificationOffsetForm : certificationOffsetForm;
        let value = parseFloat(addItemData.offset);
        if (optionPresentInList(addItemData[form_type], form_type)) {
            setDuplicateErrors({...duplicateErrors, [form_type]: true});
            return;
        }
        if (addItemData.offset === '' || value < 0.01 || value > 99.99) {
            setFormOffsetError({ ...formOffsetErrors, [form_type]: true });
            return;
        }

        let addItemReducer: any = form_type === 'qualification' ? addQualification : addCertification;

        dispatch(addItemReducer(addItemData));

        let submitData = form_type === 'qualification' ? submitQualificationsData : submitCertificationsData;
        let stateFunction = form_type === 'qualification' ? setSubmitQualificationsData : setSubmitCertificationsData;
        let submitDataCopy: any = submitData;

        submitDataCopy[addItemData[form_type]['id']] = addItemData;
        stateFunction(submitDataCopy);

        // clear add form
        clearItem(form_type);
    }

    const handleEditItem = (editItemData: any) => {
        let submitData = popupFormType === 'qualification' ? submitQualificationsData : submitCertificationsData;
        let stateFunction = popupFormType === 'qualification' ? setSubmitQualificationsData : setSubmitCertificationsData;
        let submitDataCopy: any = submitData;

        submitDataCopy[editItemData[popupFormType].id] = editItemData;
        stateFunction(submitDataCopy);
        handleClose();
    }

    const handleDelete = () => {
        let deleteItemReducer: any = popupFormType === 'qualification' ? deleteQualification : deleteCertification;
        dispatch(deleteItemReducer(modalIndex));

        let submitData = popupFormType === 'qualification' ? submitQualificationsData : submitCertificationsData;
        let stateFunction = popupFormType === 'qualification' ? setSubmitQualificationsData : setSubmitCertificationsData;
        let submitDataCopy: any = submitData;

        if (popupData.id) {
            let data = {
                id: popupData.id,
                version: popupData.version,
                delete: true
            };
            setDeletedOffsets({...deletedOffsets, [popupFormType]: [...deletedOffsets[popupFormType], data]});
        }
        delete submitDataCopy[popupData[popupFormType].id];

        stateFunction(submitDataCopy);
        handleClose();
    }

    const onCancelClick = () => {
        setSubmitQualificationsData({});
        setSubmitCertificationsData({});
        setDeletedOffsets({qualification: [], certification: []});
        dispatch(resetData());
        dispatch(fetchQualifications(version.id));
        dispatch(fetchCertifications(version.id));
    }

    const navigateToNext = () => {
        if (isNavigateToNext) {
            navigate(PAGE_DETAILS.qualificationsCertifications.nextUrl);
        }
    }

    const checkForErrors = () => {
        const formTypes = ['qualification', 'certification'];
        let formErrors = {...formOffsetErrors};

        formTypes.forEach((formType: string) => {
            let addItemData: any = formType === 'qualification' ? qualificationOffsetForm : certificationOffsetForm;
            if (optionPresentInList(addItemData[formType], formType)) {
                setDuplicateErrors({...duplicateErrors, [formType]: true});
                return;
            }
            let value = parseFloat(addItemData.offset);
            if (addItemData[formType] && (addItemData.offset === '' || value < 0.01 || value > 99.99)) {
                formErrors[formType] = true;
            }
        })

        setFormOffsetError(formErrors);
    }

    const saveQualificationsData = () => {
        if (qualificationOffsetForm.qualification || certificationOffsetForm.certification) {
            checkForErrors();
            scrollToTop();
            return;
        }
        let requestData: any = [...deletedOffsets['qualification']];
        let submitDataCopy: any = submitQualificationsData;
        for (const item in submitQualificationsData) {
            requestData.push({ ...submitDataCopy[item], version: version.id });
        };
        if (requestData.length) {
            dispatch(updateQualifications(requestData)).then((data: any) => {
                if (data?.payload?.status === '200') {
                    setSubmitQualificationsData({});
                    saveCertificationsData(true);
                    if (!isNavigateToNext) {
                        dispatch(fetchQualifications(version.id));
                    }
                } else if(STATUS_CODES_IGNORE.includes(data?.payload?.status)) {
                    setSubmitQualificationsData({});
                    setDeletedOffsets({qualification: [], certification: []});
                }
            });
        } else {
            saveCertificationsData();
        }
    }

    const saveCertificationsData = (isQualification = false) => {
        let requestData: any = [...deletedOffsets['certification']];
        let submitDataCopy: any = submitCertificationsData;
        for (const item in submitCertificationsData) {
            requestData.push({ ...submitDataCopy[item], version: version.id });
        };

        if (requestData.length) {
            dispatch(updateCertifications(requestData)).then((data: any) => {
                if (data?.payload?.status === '200') {
                    toast.success(isQualification ? 'Offsets for qualifications and certifications have been saved successfully' : 'Offsets for certifications have been saved successfully')
                    setSubmitCertificationsData({});
                    setDeletedOffsets({qualification: [], certification: []});
                    if (!isNavigateToNext) {
                        dispatch(fetchCertifications(version.id));
                    }
                    setTimeout(() => {
                        navigateToNext();
                    }, 100);
                } else if(STATUS_CODES_IGNORE.includes(data?.payload?.status)) {
                    setSubmitCertificationsData({});
                    setDeletedOffsets({qualification: [], certification: []});
                  }
            });
        } else {
            if (isQualification) toast.success('Offsets for qualifications have been saved successfully')
            setDeletedOffsets({qualification: [], certification: []});
            setTimeout(() => {
                navigateToNext();
            }, 100);
        }
    }

    const onSaveClick = () => {
        saveQualificationsData();
    }

    function getTable(list: any, form_type: string) {
        return (
            <table className='listing-table'>
                <thead></thead>
                <tbody>
                    {list.map((list_item: any, index: number) => {
                        return (
                            <tr>
                                <td>{list_item[form_type]?.name}</td>
                                <td>{list_item.offset}</td>
                                {permissionData.edit &&
                                    <td>
                                        <img className='edit-icon' src='/images/edit.svg' alt='edit' onClick={() => openPopup('edit', index, form_type)} />
                                        <img className='delete-icon' src='/images/delete.svg' alt='delete' onClick={() => {openPopup('delete', index, form_type)}}/>
                                    </td>
                                }
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }

    const getOffsetForm = (form_type: string) => {
        let selectlabel: string = form_type === 'qualification' ? 'Qualification' : 'Certification';
        let selectValue: string = form_type === 'qualification' ? getSelectedQualification() : getSelectedCertification();
        let selectArray: any = form_type === 'qualification' ? dropdownFilters?.qualifications : dropdownFilters?.certifications;
        let formState: any = form_type === 'qualification' ? qualificationOffsetForm : certificationOffsetForm;
        let formStateFunction: any = form_type === 'qualification' ? setQualificationOffsetForm : setCertificationOffsetForm;

        return (
            <div className='add-offset-form'>
                <div className="d-flex align-items-center">
                    {selectlabel}
                    <div className={duplicateErrors[form_type] ? 'add-dropdown val-error' : 'add-dropdown'}>
                        <NavDropdown
                            disabled={!permissionData.edit}
                            onKeyDown={(event: any) => {
                                if (event.keyCode === 13 && Object.keys(hoveredItem[form_type]).length !== 0) {
                                    formStateFunction({
                                        [form_type]: hoveredItem[form_type],
                                        offset: ''
                                    });
                                    setFormOffsetError({ ...formOffsetErrors, [form_type]: false });
                                    setDuplicateErrors({...duplicateErrors, [form_type]: false});
                                }
                            }}
                            title={<div className="dropdown-placeholder"
                                style={formState[form_type] ? { opacity: 1 } : {}}> {selectValue} </div>} id="filter" >
                            {selectArray?.map((option: any, index: number) => {
                                return (
                                    <div key={index}>
                                        <NavDropdown.Item
                                            onMouseEnter={() => {
                                                hoveredItem[form_type] = option
                                            }}
                                            onMouseLeave={() => {
                                                hoveredItem = {
                                                    qualification: {},
                                                    certification: {}
                                                }
                                            }}
                                            onClick={() => {
                                                formStateFunction({
                                                    [form_type]: option,
                                                    offset: ''
                                                });
                                                setFormOffsetError({ ...formOffsetErrors, [form_type]: false });
                                                setDuplicateErrors({...duplicateErrors, [form_type]: false});
                                            }}>
                                            <div className={`${form_type === 'certification' ? 'form-item' : ''}`}>{option.name}</div>
                                        </NavDropdown.Item>
                                    </div>
                                );
                            })}
                        </NavDropdown>
                    </div>
                </div>

                <div className="d-flex rating-input-div">
                    <input value={formState.offset} disabled={!formState[form_type]}
                        onKeyDown={blockInvalidCharQualification}
                        className={formOffsetErrors[form_type] === true ? 'rating-input val-error' : 'rating-input'}
                        onChange={(e: any) => changeInput(form_type, 'offset', e)} placeholder='00.00' />
                    {formOffsetErrors[form_type] === true && <span className='input-error'>
                        {formState.offset === '' ? 'Required Percentage Value' : ''}
                    </span>}
                </div>

                <div className="actions d-flex">
                    <button className='btn clear-btn' onClick={() => clearItem(form_type)} disabled={!permissionData.edit}>Clear</button>
                    <button className='btn add-btn' disabled={!formState[form_type]} onClick={() => addItem(form_type)}>Add</button>
                </div>

                {duplicateErrors[form_type] && <div className='duplicate-error'>
                    {`An offset has already been added for the chosen ${form_type}`}
                </div>}
            </div>
        )
    }

    return (
        <div className='qc-container'>
            <PageTitle paths={[PAGE_DETAILS.configurations, PAGE_DETAILS.qualificationsCertifications]} pageDetail={PAGE_DETAILS.qualificationsCertifications} />
            <div className='col-12 display-flex'>
                <div className='col-6'>
                    {getOffsetForm('qualification')}
                    {getTable(qualificationsList, 'qualification')}
                </div>
                <div className='col-6'>
                    {getOffsetForm('certification')}
                    {getTable(certificationsList, 'certification')}
                </div>
            </div>

            {show === 'edit' && <EditQualificationsCertificationsModal
                show={show === 'edit'}
                title={`Edit ${popupFormType[0].toUpperCase()}${popupFormType.slice(1)} - Offset`}
                data={popupData}
                form_type={popupFormType}
                onEditItem={handleEditItem}
                onHide={handleClose} />}

            {show === 'delete' && <ConfirmationPopup
                show={show === 'delete'}
                title={`Delete ${popupFormType[0].toUpperCase()}${popupFormType.slice(1)} - Offset`}
                message={`Are you sure you want to remove the offset for <span class='text-semibold'>${popupData[popupFormType].name}</span>?`}
                onClose={handleClose}
                onConfirm={handleDelete} />}

            {certificationsList.length === 0 && qualificationsList.length === 0 && <div className='d-flex mt-3 pt-1 justify-content-center fw-bold stream-list'>No Data</div>}

            <div>
                <ActionButtons
                    actionBtn1Click={() => onCancelClick()}
                    actionBtn2Click={() => {
                        isNavigateToNext = false;
                        onSaveClick()
                    }}
                    actionBtn3Click={() => {
                        isNavigateToNext = true;
                        onSaveClick();
                    }}
                />
            </div>
        </div>
    )
}

export default ConfigQualificationsCertifications;