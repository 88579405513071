import { Modal, Button } from 'react-bootstrap';
import { useState } from 'react';
import './style.scss';
import { SuggestedSalaryFieldType } from '../../../models/suggestedSalary';
import { toTitleCase } from '../../../store/utilFuntions';

const EditSuggestedSalaryModal = (props: any) => {
  const initialFormErrors = {
    minimum: '',
    median: '',
    maximum: ''
  }
  const [popupData, setPopupData] = useState({...props.data});
  const [formErrors, setFormErrors] = useState(initialFormErrors);
  const [updateDisabled, setUpdateDisabled] = useState(true);

  const updateSalary = () => {
    let errorObj = {...formErrors}
    Object.keys(formErrors).forEach((fieldName: string) => {
      checkForFieldErrors(errorObj, fieldName)
    })
    setFormErrors(errorObj);
    if (getErrorCount(errorObj) === 0) {
      props.onEditItem(popupData);
    }
  }

  const validateField = (fieldName: string, value: string) => {
    if (!updateDisabled) return;
    let errorObj = {...formErrors}
    checkForFieldErrors(errorObj, fieldName, value);
    setFormErrors(errorObj);
  }

  const checkForFieldErrors = (errorObj: any, fieldName?: string, value?: string) => {
    if (fieldName === 'median') return;
    let val;
    if (value !== undefined) {
      val = parseFloat(value);
    } else {
      val = parseFloat(popupData[fieldName as SuggestedSalaryFieldType]);
    }

    if (!val && val !== 0) {
      errorObj[fieldName as SuggestedSalaryFieldType] = 'Required';
      setUpdateDisabled(true);
    } else if (val === 0) {
      errorObj[fieldName as SuggestedSalaryFieldType] = 'Invalid';
      setUpdateDisabled(true);
    } else {
      errorObj[fieldName as SuggestedSalaryFieldType] = '';
    }

    if (getErrorCount(errorObj) === 0) {
      setUpdateDisabled(false);
    }
  }

  const getErrorCount = (errorObj: any) => {
    let count = 0;
    Object.keys(errorObj).forEach((field: string) => {
      if (errorObj[field as SuggestedSalaryFieldType]) count += 1;
    })
    return count;
  }

  const changeInput = (inputField: any, e: any) => {
    if (inputField === 'median') return;
    let value = e.target.value?.trim();
    let temp: any = parseFloat(value);
    temp = temp ? temp : (temp === 0 ? 0 : '');
    const re = /^(0|([1-9][0-9]{0,6}))$/;

    if (re.test(value) || value.length === 0) {
      let median = calculateMedian(inputField, temp);
      setPopupData({...popupData, [inputField]: value, median});
      validateField(inputField, value);
    }
  }

  const calculateMedian = (field: any, value: number) => {
    let median = 0;
    if (!value) value = 0;
    if (field === 'minimum') {
      median = (value + (parseFloat(popupData.maximum) || 0)) / 2;
    } else {
      median = (value + (parseFloat(popupData.minimum) || 0)) / 2;
    }
    median = Math.round(median);
    return median;
  }

  const resetSalary = () => {
    setPopupData({...props.data});
    setFormErrors(initialFormErrors);
  }

  return (
    <div>
      <Modal show={props.show} onHide={() => props.onHide()} centered size='lg' backdrop='static'>
        <Modal.Header>
          <Modal.Title>
            {props.title}
          </Modal.Title>
          <img className='close-btn' src='/images/modal-close.svg' onClick={() => props.onHide()} alt='modal-close' />
        </Modal.Header>

        <Modal.Body>
          <div className='first-row'>
            {Object.keys(popupData).map((field: any) =>
              (
                <div className='input-fields col' key={field}>
                  <div className="field-heading">{toTitleCase(field)}</div>
                  <div>
                    <input className={formErrors[field as SuggestedSalaryFieldType] ? 'salary-input val-error' : 'salary-input'}
                      value={popupData[field]} disabled={field === 'median'}
                      onChange={(e: any) => changeInput(field, e)} placeholder='Enter a value' />
                    <span className='input-error'>
                      {formErrors[field as SuggestedSalaryFieldType] === 'Invalid' ? '' : formErrors[field as SuggestedSalaryFieldType]}
                    </span>
                  </div>
                </div>
              )
            )}
          </div>

        </Modal.Body>

        <Modal.Footer className='actions suggested-salary-actions'>
          <Button className='clear-btn' onClick={resetSalary} >Reset</Button>
          <Button className='add-btn' onClick={updateSalary} disabled={updateDisabled}>{'Update & Save'}</Button>
        </Modal.Footer>

      </Modal>
    </div>
  )


}

export default EditSuggestedSalaryModal;