import { useEffect, useState } from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useSelector } from 'react-redux';
import { getPermissionData } from '../../store/reducers/salarySuggestionReducer';
import classNames from 'classnames';

import './styles.scss';

let hoveredItem = {};

const ConfigFilter = ({ name, selected, options, filterKey = "name", onFilterChange, style={}, filterDivStyle={} }: any) => {
  const [filterData, setFilterData]: any = useState(selected ? selected : {});
  const permissionData = useSelector(getPermissionData);
  const isDepartmentStreamFilter = name === 'Department/Stream';
  const className = classNames('config-filter-item', {
    'stream-filter': isDepartmentStreamFilter
  });  
  const placeholder = isDepartmentStreamFilter ? 'display_name' : 'name'
  

  useEffect(() => {
    setFilterData(selected ? selected : {});
  }, [selected])

  return (
    <div className={className} style={filterDivStyle}>
      <NavDropdown
        title={<span className='dropdown-placeholder' style={style}>{filterData[placeholder] ? filterData[placeholder] : 'Select ' + name}</span>}
        disabled={!permissionData.edit}
        onKeyDown={(event: any) => {
          if (event.keyCode === 13 && Object.keys(hoveredItem).length !== 0) {
            setFilterData(hoveredItem);
            onFilterChange(hoveredItem)
          }
        }}
        id="filter" >
        {options && options.map((option: any, index: number) => {
          return (
            <div key={index}>
              <NavDropdown.Item
                onMouseEnter={() => {
                  hoveredItem = option
                }}
                onMouseLeave={() => {
                  hoveredItem = {}
                }}
                onClick={() => {
                  setFilterData(option);
                  onFilterChange(option)
                }}>
                <div>{option[filterKey]}  </div>
              </NavDropdown.Item>
            </div>
          );
        })}
      </NavDropdown>
    </div>
  )
}

export default ConfigFilter