import { useEffect, useState } from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import StarRating from '../StarRating';
import './styles.scss';

let hoveredItem: any = {};

const RatingFilter = ({ name, reset=true, value, options, key = "name", onFilterChange }: any) => {
  const [rating, setRating] = useState(3.5);

  useEffect(() => {
    if (reset) {
      setRating(3.5)
    }
  }, [reset])

  return (
    <div className="filter-item">
      <div className="filter-name">{name}</div>
      <div>
        <NavDropdown
          onKeyDown={(event: any) => {
            if (event.keyCode === 13 && Object.keys(hoveredItem).length !== 0) {
              setRating(hoveredItem.name);
              onFilterChange(hoveredItem)
            }
          }}
          title={
            <div className="filter-select">
              <StarRating star_rating={rating} height="20" width="20" />
              <img className="drop-down" src="/images/dropdown-arrow-blue.svg" alt="Menu" />
            </div>
          } id="ratingFilter" >
          {options.map((option: any, index: any) => {
            return (
              <div key={index}>
                <NavDropdown.Item
                  onMouseEnter={() => {
                    hoveredItem = option
                  }}
                  onMouseLeave={() => {
                    hoveredItem = {}
                  }}
                  onClick={() => {
                    setRating(option.name);
                    onFilterChange(option)
                  }}>
                  <StarRating star_rating={option.name} height="20" width="20" />
                </NavDropdown.Item>
              </div>
            );
          })}
        </NavDropdown>
      </div>
    </div>
  )
}

export default RatingFilter