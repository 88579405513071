import { useSelector } from 'react-redux';
import { getPermissionData } from '../../store/reducers/salarySuggestionReducer';
import './style.scss';

const ActionButtons = ({
  actionBtn1 = "Reset",
  actionBtn2 = "Save",
  actionBtn3 = "Save & Next",
  strictViewMode = false,
  actionBtn1Click,
  actionBtn2Click,
  actionBtn3Click }: any) => {
  const permissionData = useSelector(getPermissionData);
  return (
    <div className="action-btn-container">
      {!strictViewMode && permissionData.edit && <div className="action-btn cancel" onClick={() => actionBtn1Click()}>{actionBtn1}</div>}
      {!strictViewMode && permissionData.edit && <div className="action-btn save" onClick={() => actionBtn2Click()}>{actionBtn2}</div>}
      <div className="action-btn save-next" onClick={() => actionBtn3Click()}>{ !strictViewMode && permissionData.edit ? actionBtn3 : 'Next'}</div>
    </div>
  )
}

export default ActionButtons