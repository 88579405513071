import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

import PageTitle from '../../../components/PageTitle';
import { GENERAL, PAGE_DETAILS, STATUS_CODES_IGNORE } from '../../../store/constant';
import { getFilters, getPermissionData, getVersion } from "../../../store/reducers/salarySuggestionReducer";
import { AppDispatch } from "../../../store/store";
import './style.scss';
import StarRating from '../../../components/StarRating';
import ActionButtons from '../../../components/ActionButtons';
import { fetchCombinations, getCombinations, updateCombination } from '../../../store/reducers/configCombinationReducer';
import { CombinationOffset } from '../../../models/combination';
import EditCombinationModal from './EditCombinationModal';
import FilterGroup from './FilterGroup';
import { usePrompt } from '../../../components/ConfirmNavigationPrompt';
import useUnsavedChangesStateUpdates from '../../../hooks/unsavedChangesStateUpdates';
import { handleApiError, scrollToTop, showErrorToast } from '../../../store/utilFuntions';
import ConfirmationPopup from '../../../components/ConfirmationPopup';

let dataToSave: CombinationOffset[] = []
let deleteData: any = {};

let isNavigateToNext = false;
let tempId = 0;

const ConfigCombination = () => {
  const navigate = useNavigate();
  const combinations = useSelector(getCombinations);
  const versionId = useSelector(getVersion)?.id;
  const dispatch = useDispatch<AppDispatch>();
  const [combinationConfigs, setCombinationConfigs]: any = useState([])
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [popupData, setPopupData] = useState({})
  const filters: any = useSelector(getFilters);
  const [dataModified, setDataModified] = useState({
    add: false,
    edit: false,
    dataToSave: false
  });
  const filterGroupRef: any = useRef()
  const permissionData = useSelector(getPermissionData);

  usePrompt(GENERAL.navigation_warning, dataModified.edit || dataModified.dataToSave);
  useUnsavedChangesStateUpdates(dataModified.edit || dataModified.dataToSave);

  const navigateToNext = () => {
    if (isNavigateToNext) {
      navigate(PAGE_DETAILS.combination.nextUrl);
    }
  }

  function showOffsetUpdateError(errorData: any) {
    let error = '';
    if (errorData.duplicate_instace) {
      errorData.duplicate_instace.map((duplicate: any) => {
        const streams = duplicate.stream_id && filters.streams.filter((stream: any) => {
          return stream.id === duplicate.stream_id;
        });
        const locations = duplicate.location_id && filters.locations.filter((location: any) => {
          return location.id === duplicate.location_id;
        });
        const streamName = streams !== null && streams.length ? streams[0]?.name : ' ';
        const locationName = locations !== null && locations.length ? locations[0]?.name : ' ';
        error = error + 'Department/Stream: ' + streamName + ', Location: ' + locationName + ', Rating: ' + duplicate?.rating + ', Offset: ' + duplicate?.offset + '\n';
        return true;
      });
      toast.error(<div>
        <div className="error-heading">{errorData?.Message}: {'\n'}</div>
        <div className="error-data">{error}</div>
      </div>, {
        autoClose: false,
        closeOnClick: true,
      });
      dataToSave = [];
      setDataModified({ ...dataModified, dataToSave: false });
      dispatch(fetchCombinations(versionId));
    } else handleApiError(errorData.status)
  }

  function onUpdateResponse(data: any) {
    const responseCode = data?.payload?.status === '200' || data?.payload?.status === '400' || STATUS_CODES_IGNORE.includes(data?.payload?.status) ? data?.payload?.status : '500'
    switch (responseCode) {
      case '200':
        dataToSave = [];
        if (!isNavigateToNext) dispatch(fetchCombinations(versionId));
        setDataModified({ ...dataModified, dataToSave: false, edit: false });
        setTimeout(function () {
          navigateToNext();
        }, 100);
        break;
      case '400':
        showOffsetUpdateError(data?.payload)
        break;
      case '404':
        setDataModified({ add: false, dataToSave: false, edit: false });
        break;
      case '403':
        setDataModified({ add: false, dataToSave: false, edit: false });
        break;
      case '500':
        showErrorToast();
        break;
    }
  }

  function onSaveClick(isNavigate: boolean = false) {
    if (dataModified.add && permissionData.edit) {
      filterGroupRef.current!.validateData(false);
      scrollToTop();
      return;
    }
    if (!dataToSave.length) {
      if (isNavigate) navigateToNext();
      return;
    }
    dispatch(updateCombination(dataToSave)).then((data: any) => {
      onUpdateResponse(data)
    });
  }

  function onAddClick(addCombinationData: any) {
    tempId = tempId + 1;
    addCombinationData.tempId = tempId;
    const configs: CombinationOffset[] = [...combinationConfigs];
    configs.unshift(addCombinationData);
    setCombinationConfigs(configs);
    dataToSave.push(addCombinationData);
  }

  function showSuccessToast(actionType: string) {
    if (actionType === 'edit') toast.success('Combination offsets have been updated successfully');
    else if (actionType === 'delete') toast.success('Combination offset has been deleted successfully');
  }

  function dismissModal(actionType: string) {
    if (actionType === 'edit') setShowEditModal(false);
    else setShowDeleteModal(false);
  }

  function modifyActionItem(combinationIndex: number, toSaveIndex: number, actionData: any, actionType: string, isTempId?: boolean) {
    const configs: CombinationOffset[] = [...combinationConfigs];
    configs[combinationIndex] = actionData;
    if (actionType === 'delete') {
      configs.splice(combinationIndex, 1);
    }
    setCombinationConfigs(configs);
    if (toSaveIndex !== -1) {
      dataToSave[toSaveIndex] = actionData;
      if (actionType === 'delete' && isTempId) {
        dataToSave.splice(toSaveIndex, 1);
      }
      setDataModified({ ...dataModified, edit: false, dataToSave: dataToSave.length > 0 });
    } else if ((actionType === 'edit') ||
      (actionType === 'delete' && !isTempId)) {
      dataToSave.push(actionData);
      setDataModified({ ...dataModified, dataToSave: dataToSave.length > 0 });
    }
    showSuccessToast(actionType);
  }

  function onItemAction(actionData: any, actionType: string) {
    const combinationIndex = combinationConfigs.findIndex((x: any) => actionData.id && (x.id === actionData.id));
    const toSaveIndex = dataToSave.findIndex((x: any) => x.id === actionData.id);
    if (combinationIndex !== -1) {
      modifyActionItem(combinationIndex, toSaveIndex, actionData, actionType)
    } else {
      const combinationTempIndex = combinationConfigs.findIndex((x: any) => x.tempId && x.tempId === actionData.tempId);
      const toSaveTempIndex = dataToSave.findIndex((x: any) => x.tempId && x.tempId === actionData.tempId);
      if (combinationTempIndex !== -1) {
        modifyActionItem(combinationTempIndex, toSaveTempIndex, actionData, actionType, true)
      }
    }
    dismissModal(actionType);
  }

  function onEditDataModified(isModified: boolean) {
    const data = {
      add: dataModified.add,
      edit: isModified,
      dataToSave: dataToSave.length > 0
    }
    setDataModified(data);
  }

  function onAddDataModified(isModified: boolean) {
    const data = {
      add: isModified,
      edit: dataModified.edit,
      dataToSave: dataToSave.length > 0
    }
    setDataModified(data);
  }

  const handleDeleteItem = () => {
    onItemAction(deleteData, 'delete');
  }

  useEffect(() => {
    if (versionId) {
      dispatch(fetchCombinations(versionId));
    }
  }, [dispatch, versionId])

  useEffect(() => {
    setCombinationConfigs(combinations);
  }, [dispatch, combinations])

  return (
    <div className="combination-container">
      <PageTitle paths={[PAGE_DETAILS.configurations, PAGE_DETAILS.combination]} pageDetail={PAGE_DETAILS.combination} />
      <FilterGroup
        style={{ opacity: 0.6 }}
        ref={filterGroupRef}
        isAddMode={true}
        onAddDataModified={(isModified: boolean) => onAddDataModified(isModified)}
        showActionButton={true}
        updateList={(addCombinationData: any) => onAddClick(addCombinationData)}
        combinationConfigs={combinationConfigs}
      />
      <div className="list-container">
        {combinationConfigs.map((combinationConfig: any, index: number) => {
          return (
            <div key={index} className="combination-item-container">
              <div className="combination-item">
                <div className="item location"> {combinationConfig?.location?.name} </div>
                <div className="item"> {combinationConfig?.stream?.display_name} </div>
                <div className="rating">
                  <StarRating star_rating={combinationConfig.rating} />
                </div>
                <div className="item offset"> {combinationConfig.offset} </div>
                {permissionData.edit ? <div className="actions">
                  <div className="edit" onClick={() => {
                    setShowEditModal(true)
                    setPopupData(combinationConfig);
                  }}>
                    <img className='icon' src='/images/edit.svg' alt='edit' />
                  </div>
                  <div className="edit" onClick={() => {
                    deleteData = { ...combinationConfig };
                    deleteData.delete = true;
                    setShowDeleteModal(true);
                  }}>
                    <img className='icon' src='/images/delete.svg' alt='delete' />
                  </div>
                </div> : <div className="view-only"/>}
              </div>
              <div className="border-line" />
            </div>
          );
        })}
      </div>
      {combinationConfigs.length === 0 && <div className='d-flex mt-35 justify-content-center fw-bold stream-list'>No Data</div>}
      <ActionButtons
        actionBtn1Click={() => {
          setCombinationConfigs(combinations)
          dataToSave = [];
          filterGroupRef.current!.resetData();
          setDataModified({
            add: false,
            edit: false,
            dataToSave: false
          });
        }}
        actionBtn2Click={() => {
          isNavigateToNext = false;
          onSaveClick()
        }}
        actionBtn3Click={() => {
          isNavigateToNext = true;
          onSaveClick(true);
        }}
      />
      {showEditModal && <EditCombinationModal
        combinationConfigs={combinationConfigs}
        show={showEditModal}
        title='Edit Combination Overrides'
        data={popupData}
        onEditDataChange={(isModified: boolean) => onEditDataModified(isModified)}
        onEditItem={(editCombinationData: any) => onItemAction(editCombinationData, 'edit')}
        onHide={() => setShowEditModal(false)}
        resetData={() => console} />}

      {showDeleteModal && <ConfirmationPopup
        show={showDeleteModal}
        title='Delete Combination - Offsets'
        message={`Are you sure you want to remove the data for the combination: Location: <span class='text-semibold'>${deleteData?.location?.name || '-'}</span>, Department/Stream: <span class='text-semibold'>${deleteData?.stream?.display_name || '-'}</span>, Rating: <span class='text-semibold'>${deleteData?.rating || '-'}</span>?`}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleDeleteItem} />}
    </div >
  )
}

export default ConfigCombination;
