import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {useNavigate} from 'react-router-dom';

import { PAGE_DETAILS } from '../../store/constant';
import './style.scss';

const PageTitle = ({ paths, pageDetail }: any) => {
  const navigate = useNavigate();

  const handleNavigation = (path: any, index: number) => {
    if (index >= (paths.length - 1)) {
      return;
    }

    return path.url ? navigate(path.url): navigate(PAGE_DETAILS.configurations.url);
  }

  return (
    <div className="page-title-container">
      {paths && paths.length && <Breadcrumb>
        {paths && paths.map((path: any, index: number) => {
          return (
            <Breadcrumb.Item key={index} onClick={() => handleNavigation(path, index)} active={index === (paths.length - 1)}>{path.name}</Breadcrumb.Item>
          );
        })}
      </Breadcrumb>}
      <div className="page-heading heading-text">
        <div>{pageDetail && pageDetail.name}</div>
        {!pageDetail.hideCountry && <div className="country-text">INDIA, INR</div>}
      </div>
    </div>
  )
}

export default PageTitle;