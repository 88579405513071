import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ActionButtons from "../../../components/ActionButtons";
import PageTitle from "../../../components/PageTitle";
import { PAGE_DETAILS } from "../../../store/constant";
import { setRefreshStatus, getRefreshStatus } from "../../../store/reducers/generalReducer";
import { fetchSelectedVersion, getVersion, setVersion } from "../../../store/reducers/salarySuggestionReducer";
import { computeSalaries } from "../../../store/reducers/suggestedSalaryReducer";
import { AppDispatch } from "../../../store/store";
import './style.scss';

const ConfigCompute = () => {
  const version = useSelector(getVersion)
  const refreshStatus = useSelector(getRefreshStatus);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    return () => {
      dispatch(setRefreshStatus('idle'))
    }
  }, [dispatch])

  useEffect(() => {
    if (refreshStatus === 'refreshing') {
      dispatch(setRefreshStatus('finished'));
    }
  }, [refreshStatus, dispatch])

  useEffect(() => {
    if (version.id) {
      dispatch(fetchSelectedVersion(version.id));
    }
  }, [version.id, dispatch])

  const handleCompute = () => {
    if (version?.id) {
      dispatch(computeSalaries(version.id)).then((res: any) => {
        if (res.payload.status === "200") {
          dispatch(setVersion(res.payload.data));
        }
      })
    }
  }

  const navigate = useNavigate();
  return(
    <div>
      <div className="compute-container">
        <PageTitle paths={[PAGE_DETAILS.configurations, PAGE_DETAILS.compute]} pageDetail={PAGE_DETAILS.compute} />

        <div className="compute-content">
          <div className="text">
            Feel free to calculate the suggested salaries using the button below
          </div>
          <div className="actions">
            <button className="btn add-btn" onClick={handleCompute}>Compute</button>
          </div>
        </div>
      </div>

      <ActionButtons
        actionBtn1Click={() => {}}
        actionBtn2Click={() => {}}
        actionBtn3Click={() => {navigate(PAGE_DETAILS.compute.nextUrl)}}
        strictViewMode={true}
      />
    </div>
  )
}

export default ConfigCompute;