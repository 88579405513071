import { useMemo } from 'react';
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { getSideNavState } from "../../store/reducers/generalReducer";
import { getPermissionData, getUserPermissions } from '../../store/reducers/salarySuggestionReducer';
import './side-nav.scss';

const SideNav = () => {
  let sideNavCollapsed = useSelector(getSideNavState);
  const permissionData = useSelector(getPermissionData);
  const userPermissions = useSelector(getUserPermissions);

  const sideNavTabs = useMemo(() => [
    {
      id: 'suggest-salary',
      name: 'Suggest Salary',
      url: '/suggest-salary',
      iconUrl: '/images/suggest-salary.svg',
      show: true,
    },
    {
      id: 'versions',
      name: 'All Versions',
      url: '/versions',
      iconUrl: '/images/versions.svg',
      show: userPermissions.view_previously_published || userPermissions.view_draft || userPermissions.add_draft,
      bottom: true,
    },
    {
      id: 'configurations',
      name: 'Configurations',
      url: '/configurations',
      iconUrl: '/images/configurations.svg',
      show: permissionData.view && userPermissions.view_configuration
    },
    {
      id: 'suggested-salaries',
      name: 'Suggested Salaries',
      url: '/suggested-salaries',
      iconUrl: '/images/suggested-salary.svg',
      show: true,
    },
    {
      id: 'help',
      name: 'Help',
      url: '/help',
      iconUrl: '/images/help.svg',
      show: false,
    }
  ], [permissionData.view, userPermissions]);

  const getNavLink = (navItem: any) => {
    return (
      <NavLink key={navItem.id} to={navItem.url} className={({ isActive }) => (isActive ? 'side-nav-list-item active' : 'side-nav-list-item inactive')}
        title={sideNavCollapsed ? navItem.name : ''}>
        <img src={navItem.iconUrl} alt={navItem.name} />
        {navItem.name}
      </NavLink>
    )
  }

  const navList = sideNavTabs.map((navItem: any) => {
    return (
      <>
        {navItem.show && !navItem.bottom &&
          getNavLink(navItem)
        }
      </>
    )
  })

  const navBottomList = sideNavTabs.map((navItem: any) => {
    return (
      <>
        {navItem.show && navItem.bottom &&
          getNavLink(navItem)
        }
      </>
    )
  })

  return (
    <nav className="side-nav">
      <div className={sideNavCollapsed ? "nav-collapsed" : "nav-expanded"}>
        <div className="d-flex flex-column">
          {navList}
        </div>
        <div className="d-flex flex-column">
          {navBottomList}
        </div>
      </div>
    </nav>
  )
}

export default SideNav