import { AppDispatch } from "../store/store";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

import { updateunsavedChangesPresent } from "../store/reducers/communicationReducer";

const useUnsavedChangesStateUpdates = (condition: boolean) => {
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(updateunsavedChangesPresent(condition));
    
        return() => {
          dispatch(updateunsavedChangesPresent(false));
        }
      }, [dispatch, condition]);

      return;
}

export default useUnsavedChangesStateUpdates;