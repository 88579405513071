import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify";
import { api } from "../../api";
import { locationObj } from '../../models/location';
import { handleApiError } from '../utilFuntions';

const initialState: State = {
  locationOffset: [],
}

export const fetchLocationOffset = createAsyncThunk('/location', async (versionId: number) => {
  const response = await api.get(`configurations/locations?version_id=${versionId}`);
  return response.data;
})

export const updateLocationOffset = createAsyncThunk('/location-update', async (requestData: any) => {
  const response = await api.post(`configurations/locations`, requestData);
  return response.data;
})

export const locationOffsetSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {},
  extraReducers:  (builder) => {
    builder.addCase(fetchLocationOffset.fulfilled, (state, action) => {
      if (action.payload.status === "200") {
        state.locationOffset = action.payload.data;
      } else handleApiError(action.payload.status) 
    })
    builder.addCase(updateLocationOffset.fulfilled, (_state, action) => {
      if (action.payload.status === "200") {
        toast.success('Offsets for locations have been saved successfully');
      } else handleApiError(action.payload.status) 
    })
  }
})

export const getLocationOffset = (state: any) => state.location.locationOffset;

export default locationOffsetSlice.reducer;

interface State {
  locationOffset: locationObj[] | [],
}
